/*
Footer Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
@import '../../assets/styles/variables';


/*
Footer
================================================================================
*/
.footer {
  padding: 1rem 0 2rem 0;

  color: $color-secondary-lowlight;
  background-color: $color-third-lowlight;

  font-size: 0.775rem;
  height: 1.7rem;
  width: 100%;
  bottom: 0;
  z-index: 1300;
  position: fixed;
}

.footer-different{
     /*position: absolute; */
    padding: 1rem 0 2rem 0;

    color: $color-secondary-lowlight;
    background-color: $color-third-lowlight;

    font-size: 0.775rem;
    height: 1.7rem;
    width: 100%;
    bottom: 0;
    z-index: 5000;
    position: fixed;

    @media (max-width: 992px){
      padding-bottom: 2.5rem;
      font-size: 0.6775rem;
    }
}

/*
Footer Nav
------------------------------------------------------------
*/
.powered-by {
  margin: 0;
  text-align: center;
  @media (max-width: 992px){
    padding-bottom: 0.5rem;
  }
}

.powered-by--logo {
  width: 100px;
  margin-top: -3px;
  margin-left: 2px;
  @media (max-width: 992px){
    width: 70px;
  }
}

ul.footer__nav {
  display: inline-block;
  margin: 0 0 1rem 0;
  padding: 0;

  list-style-type: none;

  text-align: center;
  text-transform: uppercase;

  & > li {
    display: inline;
    margin: 0 0.625rem;

    & > a {
      color: $color-secondary-lowlight;

      cursor: pointer;
    }

    & > a:hover,
    & > a:active,
    & > a:focus {
      color: $color-white;

      text-decoration: none;
    }
  }
}
.terms{
  text-align:right;
  @media (max-width: 992px){
    text-align:center;
    margin-top: -8px;
  }
}

.disclaimer {
  width: 90%;
  margin: 0 auto;

  text-align: justify;
}

ul.social-links {
  top: 2.75rem;

  display: block;
  margin: 0 0 1rem 0;
  padding: 0;

  list-style-type: none;

  font-size: 1.5rem;
  text-align: center;

  & > li {
    display: inline-block;
    margin: 0 0.625rem;

    & > a {
      color: $color-secondary-lowlight;

      cursor: pointer;
    }

    & > a:hover,
    & > a:active,
    & > a:focus {
      color: $color-white;

      text-decoration: none;
    }
  }
}
