@import url(https://fonts.googleapis.com/css?family=Barlow:300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap);
/*
NavBar Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */


/*
NavBar
================================================================================
*/
.styles_navbar__3RQiz {
  box-shadow: -5px 1px 10px #888;
  z-index: 900 !important;
}
/*
  Layout
  ------------------------------------------------------------
  */
.styles_navbar__3RQiz .styles_navbar__row__1-l1a {
    height: 100%;
  }
.styles_navbar__3RQiz .styles_navbar__col__vTpd2 {
    height: 100%
  }
.styles_navbar__3RQiz .styles_navbar__col__vTpd2:first-child {
      text-align: left
    }
@media (max-width: 767px) {

    .styles_navbar__3RQiz .styles_navbar__col__vTpd2:first-child {
        display: none
    }
      }
.styles_navbar__3RQiz .styles_navbar__col__vTpd2:not(:first-child):not(:last-child) {
      text-align: center;
    }
.styles_navbar__3RQiz .styles_navbar__col__vTpd2:last-child {
      text-align: right;
    }
.styles_navbar__3RQiz .styles_navbar__col__vTpd2.styles_new__uwsJy img{
    height:40%;
  }
/*
  Brand
  ------------------------------------------------------------
  */
.styles_navbar__3RQiz .styles_navbar__brand__2HDgo {
    display: block;
    height: 100%;

    text-align: center;
  }
.styles_navbar__3RQiz .styles_navbar__brand__link__1zzyQ {
    display: inline-block;
    height: 100%;
  }
.styles_navbar__3RQiz .styles_navbar__brand__img__1i2Mh {
    position: relative;
    top: 20%;
    height: 60%;
  }
/*
  Text & Links
  ------------------------------------------------------------
  */
.styles_navbar__3RQiz .styles_navbar__text__2PaOh {
    display: inline;
    position: relative;
    top: calc(50% - 1rem);

    color: #646569;

    font-weight: 600;
    text-transform: uppercase;
  }
.styles_navbar__3RQiz a.styles_navbar__text__2PaOh:hover,
    .styles_navbar__3RQiz a.styles_navbar__text__2PaOh:active,
    .styles_navbar__3RQiz a.styles_navbar__text__2PaOh:focus {
      color: #BDBBBB;
      text-decoration: none;
    }

.styles_header-link__gcGDB {
  padding: 0 1rem;
}

.styles_header-link__gcGDB a:focus,
  .styles_header-link__gcGDB a:hover {
    font-weight: bold;
    text-decoration: none;
  }

.styles_header-link--current__3xImE {
  font-weight: bold;
}

.styles_rightbar__1MT-K{
  padding-top:35px;
}

.styles_null_navbar__2mnlT{
  height:0;
  min-height:0;
}
/* stylelint-disable */
.spinner-icon {
    font-size: 18px;
    margin: 4px;

    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%; /* IE 9 */

    -webkit-animation: spinw .7s infinite linear;
       -moz-animation: spinm .7s infinite linear;
        -ms-animation: spin .7s infinite linear;

    -animation: spin .7s infinite linear;
  }
.spinner-text {
    font-size: 14px;
  }

@-webkit-keyframes spinw {
  from {
    transform: scale(1) rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinw {
  from {
    transform: scale(1) rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

/*
Account Security Form Modal Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */
.custom-button{
    color:orange
}
.styles_large-button--primary__3MmCq {
  border: 2px solid #00a1df;
  border-radius: 1rem;
  padding: 1.1rem 8.0rem;

  color: #FFF;
  background-color: #00a1df;

  font-size: 1.1rem;
  font-weight: 600;
  line-height: normal
}
.styles_large-button--primary__3MmCq:hover {
    color: #00a1df;
    background-color: #FFF;
  }
.styles_large-button--primary__3MmCq:focus {
    outline: none;
  }
.styles_large-button--secondary__1zk-5 {
  border: 1px solid #73C8AF;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;

  color: #FFF;
  background-color: #73C8AF;

  font-weight: 600;
  line-height: normal
}
.styles_large-button--secondary__1zk-5:hover {
    color: #73C8AF;
    background-color: #FFF;
  }
.styles_large-button--secondary__1zk-5:focus {
    outline: none;
  }
.styles_large-button--secondary__1zk-5:disabled{
    background-color:#FFF;
    color: lightgrey;
    border: 1px solid lightgrey;
  }
.styles_jumbotron__BVqM1 {
    padding: 3rem 1rem 1rem;
    margin-bottom: 0;
    background-color: #00a1df;
    color: #fff;
    text-align: center;
    border-radius: 0;
    position: relative;
}
.styles_dark__3P1N4{
    background-color: #00a1df;
    color: #fff;
}
.styles_flex-container__1kC0n{
  display: flex;
}
.styles_flex-container__1kC0n div{
  flex-grow:1;
  margin: auto;
}
.styles_flex-center__NVlbB{
  justify-content:center;
  text-align:center;
}
.styles_flex-cell3__Wn-F3{
  width: calc(100% / 3);
}
.styles_flex-cell4__2WCHQ{
  width: calc(100% / 4);
}
.styles_flex-cell8__30nUT{
  width: calc(100% / 8);
}
.styles_field-instructions__2l74e {
  margin-bottom: 1.5rem;
  text-align: justify;
  font-weight: 300
}
.styles_field-instructions__2l74e:last-child {
    margin-bottom: 2.5rem;
  }
.styles_light-gray__19JNx{
  color:#979797;
}
.styles_spacer__M1Mn- {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
.styles_spacer--after-form-group__2M2c4 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.styles_spacer--members-list__3jaKd {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.styles_hidden-field__1da3I {
  display: none;
}
.styles_hidden-field__1da3I + .styles_align-with-input__3iRuW {
  padding-top: 0;
}
.styles_white__3TGNq{
  color:#fafafa
}
.styles_white__3TGNq:hover {
    color: #cacaca;
  }
.styles_white-background__1aL-7{
  background:#fff;
}
.styles_float-right__3v0ab {
  float: right;
}
.styles_float-left__2XlsZ {
  float: left;
}
.styles_modal-section-title__irfdN {
  margin: 0 0 1rem 0;
  color: #00a1df;
  font-size: 1.5rem;
}
.modal-body {
  padding-bottom: 1.5rem;
}
.styles_dhqblue__NEoJ2{
  color:#00a1df;
}
.styles_acceptsSMScheckbox__3bFVN input{
  opacity:.4;
  height: 0.8rem;
}
.styles_acceptsSMS__f5Pib{
  font-size:.8rem;
  color: #999;
}

/*
Page Wide
================================================================================
*/


#styles_paymentplans__MqFNn #styles_header__35cKI {
  padding-top: 20px;
}

#styles_paymentplans__MqFNn .styles_button-create__2kjfL{
  padding: 20px 0;
}
.styles_intro__L-yQV{}

.styles_buttons__teZg_ {}

.styles_explanation__1eWWA {
  margin-top: 1rem;
}

.styles_explanation__1eWWA  {
    color: #767676;
    background: #fff;
}

.styles_explanation__1eWWA ol {
    margin-bottom: 0;
    counter-reset: myCounter;
}

.styles_explanation__1eWWA ol li {
    list-style: none;
    padding-left: .5rem;
    margin-bottom: 1rem;
  }

.styles_explanation__1eWWA ol li:before {
    position: absolute;
    left: 0px;
    margin-top: -10px;
    counter-increment: myCounter;
    content: counter(myCounter);
    display: inline-block;
    text-align: center;
    margin: 5px 10px;
    line-height: 40px;
    transition: all .2s;
    color: #ffffff;
    background: rgb(0, 48, 87);
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.styles_explanation__1eWWA ul {
  margin-bottom: 0;
  counter-reset: myCounter;
}

.styles_explanation__1eWWA ul li {
  list-style: none;
  padding-left: .5rem;
  margin-bottom: 1rem;
}

.styles_explanation__1eWWA ul li:before {
  position: absolute;
  left: 0px;
  margin-top: -10px;
  counter-increment: myCounter;
  content: counter(myCounter);
  display: inline-block;
  text-align: center;
  margin: 5px 10px;
  line-height: 40px;
  transition: all .2s;
  color: #ffffff;
  background: rgb(0, 48, 87);
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.styles_varidi-button__37qOl{
  margin:1rem 0;
}

.styles_highlightText__IYZSa {
  color: rgb(0, 48, 87);
}

.styles_titleCheckboxes__3COM9{
    color: rgb(0, 48, 87);
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 16px;
}

.styles_subtitle__1fzeU{
    color: rgb(0, 48, 87);
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 8px;
}

.styles_text__v-9ps {
  color: rgb(56, 56, 56);
  font-size: .95rem;
}
/*
Account Security Form Modal Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */
.custom-button{
    color:orange
}
.styles_large-button--primary__3kzfR {
  border: 2px solid #00a1df;
  border-radius: 1rem;
  padding: 1.1rem 8.0rem;

  color: #FFF;
  background-color: #00a1df;

  font-size: 1.1rem;
  font-weight: 600;
  line-height: normal
}
.styles_large-button--primary__3kzfR:hover {
    color: #00a1df;
    background-color: #FFF;
  }
.styles_large-button--primary__3kzfR:focus {
    outline: none;
  }
.styles_large-button--secondary__2Tr_7 {
  border: 1px solid #73C8AF;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;

  color: #FFF;
  background-color: #73C8AF;

  font-weight: 600;
  line-height: normal
}
.styles_large-button--secondary__2Tr_7:hover {
    color: #73C8AF;
    background-color: #FFF;
  }
.styles_large-button--secondary__2Tr_7:focus {
    outline: none;
  }
.styles_large-button--secondary__2Tr_7:disabled{
    background-color:#FFF;
    color: lightgrey;
    border: 1px solid lightgrey;
  }
.styles_jumbotron__29pz0 {
    padding: 3rem 1rem 1rem;
    margin-bottom: 0;
    background-color: #00a1df;
    color: #fff;
    text-align: center;
    border-radius: 0;
    position: relative;
}
.styles_dark__1lLRn{
    background-color: #00a1df;
    color: #fff;
}
.styles_flex-container__1gidY{
  display: flex;
}
.styles_flex-container__1gidY div{
  flex-grow:1;
  margin: auto;
}
.styles_flex-center__24cVI{
  justify-content:center;
  text-align:center;
}
.styles_flex-cell3__zyAVy{
  width: calc(100% / 3);
}
.styles_flex-cell4__3GQTR{
  width: calc(100% / 4);
}
.styles_flex-cell8__2_pYr{
  width: calc(100% / 8);
}
.styles_field-instructions__1zGhz {
  margin-bottom: 1.5rem;
  text-align: justify;
  font-weight: 300
}
.styles_field-instructions__1zGhz:last-child {
    margin-bottom: 2.5rem;
  }
.styles_light-gray__S4AUL{
  color:#979797;
}
.styles_spacer__2u-0K {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
.styles_spacer--after-form-group__3krv- {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.styles_spacer--members-list__2Kwui {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.styles_hidden-field__3OB2Y {
  display: none;
}
.styles_hidden-field__3OB2Y + .styles_align-with-input__3vc1- {
  padding-top: 0;
}
.styles_white__2Nz4A{
  color:#fafafa
}
.styles_white__2Nz4A:hover {
    color: #cacaca;
  }
.styles_white-background__2pjUb{
  background:#fff;
}
.styles_float-right__8_qrZ {
  float: right;
}
.styles_float-left__kCRBm {
  float: left;
}
.styles_modal-section-title__G5Cyw {
  margin: 0 0 1rem 0;
  color: #00a1df;
  font-size: 1.5rem;
}
.modal-body {
  padding-bottom: 1.5rem;
}
.styles_dhqblue__1qHnU{
  color:#00a1df;
}
.styles_acceptsSMScheckbox__1YjOt input{
  opacity:.4;
  height: 0.8rem;
}
.styles_acceptsSMS__1Q1Pw{
  font-size:.8rem;
  color: #999;
}
/*
Page Wide
================================================================================
*/



#styles_paymentplans__2Nrxg #styles_header__3cGXd {
  padding-top: 20px;
}

#styles_paymentplans__2Nrxg .styles_button-create__6Xrjg{
  padding: 20px 0;
}
.styles_intro__YT5v_{}

.styles_buttons__1IB_2 {}

.styles_explanation__1T10V {
  margin-top: 1rem;
}

.styles_explanation__1T10V  {
    color: #767676;
    background: #fff;
}

.styles_explanation__1T10V ol {
    margin-bottom: 0;
    counter-reset: myCounter;
}

.styles_explanation__1T10V ol li {
    list-style: none;
    padding-left: .5rem;
    margin-bottom: 1rem;
  }

.styles_explanation__1T10V ol li:before {
    position: absolute;
    left: 0px;
    margin-top: -10px;
    counter-increment: myCounter;
    content: counter(myCounter);
    display: inline-block;
    text-align: center;
    margin: 5px 10px;
    line-height: 40px;
    transition: all .2s;
    color: #ffffff;
    background: #149dcc;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.styles_elevate-button__3g1WP{
  margin:1rem 0;
}

/*
Account Security Form Modal Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */


/*
Page Wide
================================================================================
*/


#styles_paymentplans__1ol7Z #styles_header__eS9fi {
  padding-top: 20px;
}

#styles_paymentplans__1ol7Z .styles_button-create__245JY{
  padding: 20px 0;
}
.styles_intro__1etkw{}

.styles_buttons__1if7S {}

.styles_explanation__1vWXp {
  margin-top: 1rem;
}

.styles_explanation__1vWXp  {
    color: #767676;
    background: #fff;
}

.styles_explanation__1vWXp ol {
    margin-bottom: 0;
    counter-reset: myCounter;
}

.styles_explanation__1vWXp ol li {
    list-style: none;
    padding-left: .5rem;
    margin-bottom: 1rem;
  }

.styles_explanation__1vWXp ol li:before {
    position: absolute;
    left: 0px;
    margin-top: -10px;
    counter-increment: myCounter;
    content: counter(myCounter);
    display: inline-block;
    text-align: center;
    margin: 5px 10px;
    line-height: 40px;
    transition: all .2s;
    color: #ffffff;
    background: #149dcc;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.styles_varidi-button__67-34{
  margin:1rem 0;
}

.custom-button{
    color:orange
}

.styles_large-button--primary__PEp5Z {
  border: 2px solid #00a1df;
  border-radius: 1rem;
  padding: 1.1rem 8.0rem;

  color: #FFF;
  background-color: #00a1df;

  font-size: 1.1rem;
  font-weight: 600;
  line-height: normal
}

.styles_large-button--primary__PEp5Z:hover {
    color: #00a1df;
    background-color: #FFF;
  }

.styles_large-button--primary__PEp5Z:focus {
    outline: none;
  }

.styles_large-button--secondary__178em {
  border: 1px solid #73C8AF;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;

  color: #FFF;
  background-color: #73C8AF;

  font-weight: 600;
  line-height: normal
}

.styles_large-button--secondary__178em:hover {
    color: #73C8AF;
    background-color: #FFF;
  }

.styles_large-button--secondary__178em:focus {
    outline: none;
  }

.styles_large-button--secondary__178em:disabled{
    background-color:#FFF;
    color: lightgrey;
    border: 1px solid lightgrey;
  }

.styles_jumbotron__3B8ZB {
    padding: 3rem 1rem 1rem;
    margin-bottom: 0;
    background-color: #00a1df;
    color: #fff;
    text-align: center;
    border-radius: 0;
    position: relative;
}

.styles_dark__2MA2M{
    background-color: #00a1df;
    color: #fff;
}

.styles_flex-container__2lve2{
  display: flex;
}

.styles_flex-container__2lve2 div{
  flex-grow:1;
  margin: auto;
}

.styles_flex-center__12DQi{
  justify-content:center;
  text-align:center;
}

.styles_flex-cell3__3Uezg{
  width: calc(100% / 3);
}

.styles_flex-cell4__2Hjam{
  width: calc(100% / 4);
}

.styles_flex-cell8__MfMJE{
  width: calc(100% / 8);
}

.styles_field-instructions__KcxOg {
  margin-bottom: 1.5rem;
  text-align: justify;
  font-weight: 300
}

.styles_field-instructions__KcxOg:last-child {
    margin-bottom: 2.5rem;
  }

.styles_light-gray__2P3K1{
  color:#979797;
}

.styles_spacer__1_bJc {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}

.styles_spacer--after-form-group__3e4bo {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.styles_spacer--members-list__3-swq {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.styles_hidden-field__nw5hP {
  display: none;
}

.styles_hidden-field__nw5hP + .styles_align-with-input__NG_4R {
  padding-top: 0;
}

.styles_white__szAbr{
  color:#fafafa
}

.styles_white__szAbr:hover {
    color: #cacaca;
  }

.styles_white-background__3sh_D{
  background:#fff;
}

.styles_float-right__31DfK {
  float: right;
}

.styles_float-left__odDp6 {
  float: left;
}

.styles_modal-section-title__22KDH {
  margin: 0 0 1rem 0;
  color: #00a1df;
  font-size: 1.5rem;
}

.modal-body {
  padding-bottom: 1.5rem;
}

.styles_dhqblue__2sh66{
  color:#00a1df;
}

.styles_acceptsSMScheckbox__3geJn input{
  opacity:.4;
  height: 0.8rem;
}

.styles_acceptsSMS__Sntpm{
  font-size:.8rem;
  color: #999;
}
/*
Contact Us Form Modal Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */
.custom-button{
    color:orange
}
.styles_large-button--primary__1mYVR {
  border: 2px solid #00a1df;
  border-radius: 1rem;
  padding: 1.1rem 8.0rem;

  color: #FFF;
  background-color: #00a1df;

  font-size: 1.1rem;
  font-weight: 600;
  line-height: normal
}
.styles_large-button--primary__1mYVR:hover {
    color: #00a1df;
    background-color: #FFF;
  }
.styles_large-button--primary__1mYVR:focus {
    outline: none;
  }
.styles_large-button--secondary__1I4ad {
  border: 1px solid #73C8AF;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;

  color: #FFF;
  background-color: #73C8AF;

  font-weight: 600;
  line-height: normal
}
.styles_large-button--secondary__1I4ad:hover {
    color: #73C8AF;
    background-color: #FFF;
  }
.styles_large-button--secondary__1I4ad:focus {
    outline: none;
  }
.styles_large-button--secondary__1I4ad:disabled{
    background-color:#FFF;
    color: lightgrey;
    border: 1px solid lightgrey;
  }
.styles_jumbotron__27jGG {
    padding: 3rem 1rem 1rem;
    margin-bottom: 0;
    background-color: #00a1df;
    color: #fff;
    text-align: center;
    border-radius: 0;
    position: relative;
}
.styles_dark__2sw-v{
    background-color: #00a1df;
    color: #fff;
}
.styles_flex-container__1vCRC{
  display: flex;
}
.styles_flex-container__1vCRC div{
  flex-grow:1;
  margin: auto;
}
.styles_flex-center__1cuQo{
  justify-content:center;
  text-align:center;
}
.styles_flex-cell3__35hg2{
  width: calc(100% / 3);
}
.styles_flex-cell4__1ZOi4{
  width: calc(100% / 4);
}
.styles_flex-cell8__sqgxP{
  width: calc(100% / 8);
}
.styles_field-instructions__2NXzn {
  margin-bottom: 1.5rem;
  text-align: justify;
  font-weight: 300
}
.styles_field-instructions__2NXzn:last-child {
    margin-bottom: 2.5rem;
  }
.styles_light-gray__3YwF3{
  color:#979797;
}
.styles_spacer__2KVif {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
.styles_spacer--after-form-group__1c1sG {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.styles_spacer--members-list__3ob4Z {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.styles_hidden-field__Ff3UD {
  display: none;
}
.styles_hidden-field__Ff3UD + .styles_align-with-input__2lkeR {
  padding-top: 0;
}
.styles_white__kHwuP{
  color:#fafafa
}
.styles_white__kHwuP:hover {
    color: #cacaca;
  }
.styles_white-background__2Py8x{
  background:#fff;
}
.styles_float-right__2aiyP {
  float: right;
}
.styles_float-left__2cpZK {
  float: left;
}
.styles_modal-section-title__7EsuL {
  margin: 0 0 1rem 0;
  color: #00a1df;
  font-size: 1.5rem;
}
.modal-body {
  padding-bottom: 1.5rem;
}
.styles_dhqblue__1UgpR{
  color:#00a1df;
}
.styles_acceptsSMScheckbox__10Tql input{
  opacity:.4;
  height: 0.8rem;
}
.styles_acceptsSMS__26lhw{
  font-size:.8rem;
  color: #999;
}

/*
Page Wide
================================================================================
*/

.styles_email__2MrGY{
  color:red;
  font-weight:bold;
  font-size:1.2rem;
}

.styles_hidden_stuff__1avAD .control-label{
    display:none;
  }
/*
Invite Form Modal Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */
.custom-button{
    color:orange
}
.styles_large-button--primary__2xNdT {
  border: 2px solid #00a1df;
  border-radius: 1rem;
  padding: 1.1rem 8.0rem;

  color: #FFF;
  background-color: #00a1df;

  font-size: 1.1rem;
  font-weight: 600;
  line-height: normal
}
.styles_large-button--primary__2xNdT:hover {
    color: #00a1df;
    background-color: #FFF;
  }
.styles_large-button--primary__2xNdT:focus {
    outline: none;
  }
.styles_large-button--secondary__3GWzS {
  border: 1px solid #73C8AF;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;

  color: #FFF;
  background-color: #73C8AF;

  font-weight: 600;
  line-height: normal
}
.styles_large-button--secondary__3GWzS:hover {
    color: #73C8AF;
    background-color: #FFF;
  }
.styles_large-button--secondary__3GWzS:focus {
    outline: none;
  }
.styles_large-button--secondary__3GWzS:disabled{
    background-color:#FFF;
    color: lightgrey;
    border: 1px solid lightgrey;
  }
.styles_jumbotron__3BjnR {
    padding: 3rem 1rem 1rem;
    margin-bottom: 0;
    background-color: #00a1df;
    color: #fff;
    text-align: center;
    border-radius: 0;
    position: relative;
}
.styles_dark__2g0Ah{
    background-color: #00a1df;
    color: #fff;
}
.styles_flex-container__3-2Kd{
  display: flex;
}
.styles_flex-container__3-2Kd div{
  flex-grow:1;
  margin: auto;
}
.styles_flex-center__1kS76{
  justify-content:center;
  text-align:center;
}
.styles_flex-cell3__nKVA1{
  width: calc(100% / 3);
}
.styles_flex-cell4__2uMbM{
  width: calc(100% / 4);
}
.styles_flex-cell8__1VMd8{
  width: calc(100% / 8);
}
.styles_field-instructions__1K1X1 {
  margin-bottom: 1.5rem;
  text-align: justify;
  font-weight: 300
}
.styles_field-instructions__1K1X1:last-child {
    margin-bottom: 2.5rem;
  }
.styles_light-gray__2awZg{
  color:#979797;
}
.styles_spacer__2Scoi {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
.styles_spacer--after-form-group__k4SBK {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.styles_spacer--members-list__1daYe {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.styles_hidden-field__3Aiue {
  display: none;
}
.styles_hidden-field__3Aiue + .styles_align-with-input__3STp3 {
  padding-top: 0;
}
.styles_white__2g7VB{
  color:#fafafa
}
.styles_white__2g7VB:hover {
    color: #cacaca;
  }
.styles_white-background__2Uyg3{
  background:#fff;
}
.styles_float-right__3dIzH {
  float: right;
}
.styles_float-left__q6GXh {
  float: left;
}
.styles_modal-section-title__2XFZD {
  margin: 0 0 1rem 0;
  color: #00a1df;
  font-size: 1.5rem;
}
.modal-body {
  padding-bottom: 1.5rem;
}
.styles_dhqblue__17HCF{
  color:#00a1df;
}
.styles_acceptsSMScheckbox__2m7UV input{
  opacity:.4;
  height: 0.8rem;
}
.styles_acceptsSMS__1D9b3{
  font-size:.8rem;
  color: #999;
}

/*
Page Wide
================================================================================
*/


/*
Invite Form Modal
================================================================================
*/

/* TODO */

.styles_spanishcheckbox__2_326{
  padding: 15px;
  width:40%;
  float: left;
}

.styles_languagepad__1B1O8{
  padding-top:11px
}


.styles_spanishbox__8u9k8{
  padding: 0 15px 0;
}


.styles_spanishbox__8u9k8 .styles_language-radio__33bkY {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -4px
  }


.styles_spanishbox__8u9k8 .styles_language-radio__33bkY>div {
      padding-left: 0;
      font-size: 0.9rem;
    }


.styles_spanishbox__8u9k8 .styles_language-radio__33bkY input {
      width: 12px;
      margin-right: 5px;
    }

/*
Invite Form Modal Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */


/*
Page Wide
================================================================================
*/



.styles_smaller__2Nn5o{
  font-size:.8rem;
}
/*
Invite Form Modal
================================================================================
*/

/* TODO */

.custom-button{
    color:orange
}

.styles_large-button--primary__v6k4Z {
  border: 2px solid #00a1df;
  border-radius: 1rem;
  padding: 1.1rem 8.0rem;

  color: #FFF;
  background-color: #00a1df;

  font-size: 1.1rem;
  font-weight: 600;
  line-height: normal
}

.styles_large-button--primary__v6k4Z:hover {
    color: #00a1df;
    background-color: #FFF;
  }

.styles_large-button--primary__v6k4Z:focus {
    outline: none;
  }

.styles_large-button--secondary__Ykuot {
  border: 1px solid #73C8AF;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;

  color: #FFF;
  background-color: #73C8AF;

  font-weight: 600;
  line-height: normal
}

.styles_large-button--secondary__Ykuot:hover {
    color: #73C8AF;
    background-color: #FFF;
  }

.styles_large-button--secondary__Ykuot:focus {
    outline: none;
  }

.styles_large-button--secondary__Ykuot:disabled{
    background-color:#FFF;
    color: lightgrey;
    border: 1px solid lightgrey;
  }

.styles_jumbotron__3HC6l {
    padding: 3rem 1rem 1rem;
    margin-bottom: 0;
    background-color: #00a1df;
    color: #fff;
    text-align: center;
    border-radius: 0;
    position: relative;
}

.styles_dark__WwHtz{
    background-color: #00a1df;
    color: #fff;
}

.styles_flex-container__7RMVu{
  display: flex;
}

.styles_flex-container__7RMVu div{
  flex-grow:1;
  margin: auto;
}

.styles_flex-center__sLHoK{
  justify-content:center;
  text-align:center;
}

.styles_flex-cell3__1Heey{
  width: calc(100% / 3);
}

.styles_flex-cell4__2HOmv{
  width: calc(100% / 4);
}

.styles_flex-cell8__3YpPO{
  width: calc(100% / 8);
}

.styles_field-instructions__2CH45 {
  margin-bottom: 1.5rem;
  text-align: justify;
  font-weight: 300
}

.styles_field-instructions__2CH45:last-child {
    margin-bottom: 2.5rem;
  }

.styles_light-gray__1r6Vd{
  color:#979797;
}

.styles_spacer__1Mmsz {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}

.styles_spacer--after-form-group__33bkp {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.styles_spacer--members-list__12O7V {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.styles_hidden-field__2oFYk {
  display: none;
}

.styles_hidden-field__2oFYk + .styles_align-with-input__3H6Ke {
  padding-top: 0;
}

.styles_white__3a5mN{
  color:#fafafa
}

.styles_white__3a5mN:hover {
    color: #cacaca;
  }

.styles_white-background__1M6Yt{
  background:#fff;
}

.styles_float-right__1el5n {
  float: right;
}

.styles_float-left__3YQCH {
  float: left;
}

.styles_modal-section-title__22RqN {
  margin: 0 0 1rem 0;
  color: #00a1df;
  font-size: 1.5rem;
}

.modal-body {
  padding-bottom: 1.5rem;
}

.styles_dhqblue__eHZcr{
  color:#00a1df;
}

.styles_acceptsSMScheckbox__112fJ input{
  opacity:.4;
  height: 0.8rem;
}

.styles_acceptsSMS__3qSul{
  font-size:.8rem;
  color: #999;
}
/*
Invite Form Modal Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */
.custom-button{
    color:orange
}
.styles_large-button--primary__3S3Z8 {
  border: 2px solid #00a1df;
  border-radius: 1rem;
  padding: 1.1rem 8.0rem;

  color: #FFF;
  background-color: #00a1df;

  font-size: 1.1rem;
  font-weight: 600;
  line-height: normal
}
.styles_large-button--primary__3S3Z8:hover {
    color: #00a1df;
    background-color: #FFF;
  }
.styles_large-button--primary__3S3Z8:focus {
    outline: none;
  }
.styles_large-button--secondary__2G623 {
  border: 1px solid #73C8AF;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;

  color: #FFF;
  background-color: #73C8AF;

  font-weight: 600;
  line-height: normal
}
.styles_large-button--secondary__2G623:hover {
    color: #73C8AF;
    background-color: #FFF;
  }
.styles_large-button--secondary__2G623:focus {
    outline: none;
  }
.styles_large-button--secondary__2G623:disabled{
    background-color:#FFF;
    color: lightgrey;
    border: 1px solid lightgrey;
  }
.styles_jumbotron__Z2XBg {
    padding: 3rem 1rem 1rem;
    margin-bottom: 0;
    background-color: #00a1df;
    color: #fff;
    text-align: center;
    border-radius: 0;
    position: relative;
}
.styles_dark__EJASy{
    background-color: #00a1df;
    color: #fff;
}
.styles_flex-container__16JYT{
  display: flex;
}
.styles_flex-container__16JYT div{
  flex-grow:1;
  margin: auto;
}
.styles_flex-center__2XSg7{
  justify-content:center;
  text-align:center;
}
.styles_flex-cell3__prU1a{
  width: calc(100% / 3);
}
.styles_flex-cell4__dIWgE{
  width: calc(100% / 4);
}
.styles_flex-cell8__2q6Fe{
  width: calc(100% / 8);
}
.styles_field-instructions__AdyLr {
  margin-bottom: 1.5rem;
  text-align: justify;
  font-weight: 300
}
.styles_field-instructions__AdyLr:last-child {
    margin-bottom: 2.5rem;
  }
.styles_light-gray__3cu2A{
  color:#979797;
}
.styles_spacer__1S8aY {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
.styles_spacer--after-form-group__2ECOi {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.styles_spacer--members-list__2aW72 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.styles_hidden-field__JQHdL {
  display: none;
}
.styles_hidden-field__JQHdL + .styles_align-with-input__36Oft {
  padding-top: 0;
}
.styles_white__1aCq-{
  color:#fafafa
}
.styles_white__1aCq-:hover {
    color: #cacaca;
  }
.styles_white-background__28ObZ{
  background:#fff;
}
.styles_float-right__Hmp5S {
  float: right;
}
.styles_float-left__2aJ7F {
  float: left;
}
.styles_modal-section-title__1ihD2 {
  margin: 0 0 1rem 0;
  color: #00a1df;
  font-size: 1.5rem;
}
.modal-body {
  padding-bottom: 1.5rem;
}
.styles_dhqblue__31OLM{
  color:#00a1df;
}
.styles_acceptsSMScheckbox__mvVCW input{
  opacity:.4;
  height: 0.8rem;
}
.styles_acceptsSMS__1iyq9{
  font-size:.8rem;
  color: #999;
}

/*
Page Wide
================================================================================
*/


.styles_smaller__GY-Xt{
  font-size:.8rem;
}

.styles_error__39LeZ{
  color:red;
}


.styles_popover__container__2sjQR {
  position: relative;
  display: inline;
}

.styles_popover__controls__YhyWv {
  text-align: center;
}

.styles_popover__control__3SlNH {
  margin: 0 1.5rem;
}

.styles_popover__control--close__os0CX {
  color: #646569;

  cursor: pointer;
  font-size: 27px; /* Same as the .button--short's height + padding + outline. */
  line-height: 1
}

.styles_popover__control--close__os0CX:hover {
    color: #BDBBBB;
  }
/*
Invite Form Modal
================================================================================
*/

/* TODO */
.styles_popover__container__2sjQR {
  position: relative;
  display: inline;
}

.styles_popover__controls__YhyWv {
  text-align: center;
}

.styles_popover__control__3SlNH {
  margin: 0 1.5rem;
}

.styles_popover__control--close__os0CX {
  color: #646569;

  cursor: pointer;
  font-size: 27px; /* Same as the .button--short's height + padding + outline. */
  line-height: 1
}

.styles_popover__control--close__os0CX:hover {
    color: #BDBBBB;
  }
.styles_button--short__-k_Bq {
  border: 1px solid #00a1df;
  border-radius: 0.25rem;
  padding: 0.25rem 1.5rem;

  color: #FFF;
  background-color: #00a1df;

  font-size: 0.875rem;
  font-weight: 600;
  line-height: normal
}
.styles_button--short__-k_Bq:hover {
    color: #00a1df;
    background-color: #FFF;
  }
.styles_button--short__-k_Bq:focus {
    outline: none;
  }
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/




/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/

/*
Colors
------------------------------------------------------------
*/   /* rgb(  0, 161, 263) */ /* rgb(115, 200, 175) */   /* darker VHQblue */    /* rgb(100, 101, 105) */  /* rgb(189, 187, 187) */    /* rgb(100, 101, 105) */


/*
Fonts
------------------------------------------------------------
*/

/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */

/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */

.dental-sidenav .nav > li > a.active {
  background-color: #FFF !important;
  color: #141414;
}

li.styles_nav-item__1nMBt {
  margin-bottom: 5px
}

li.styles_nav-item__1nMBt > a {
    color: #FFF
  }

li.styles_nav-item__1nMBt > a:hover {
      color: #141414;
    }

.styles_dental-sidenav__97drr {
  padding: 1em 1.5em
}

.styles_dental-sidenav__97drr.styles_client__871V9 {
    background-color: #15BF71;
  }

.styles_dental-sidenav__97drr.styles_client__871V9 li.styles_nav-item__1nMBt > a {
      background-color: #0E7847;
      white-space: nowrap
    }

.styles_dental-sidenav__97drr.styles_client__871V9 li.styles_nav-item__1nMBt > a:hover {
        background-color: #FFF;
      }

.styles_dental-sidenav__97drr.styles_dentist__3JpRA {
    background-color: #00C0EF;
  }

.styles_dental-sidenav__97drr.styles_dentist__3JpRA li.styles_nav-item__1nMBt > a {
      background-color: #007B9B
    }

.styles_dental-sidenav__97drr.styles_dentist__3JpRA li.styles_nav-item__1nMBt > a:hover {
        background-color: #FFF;
      }

/*
NavBar
================================================================================
*/
.styles_navbar__1Mu1F {
  box-shadow: -5px 1px 10px #888;
  z-index: 900 !important;
}
/*
  Layout
  ------------------------------------------------------------
  */
.styles_navbar__1Mu1F .styles_navbar__row__247qw {
    height: 100%;
  }
.styles_navbar__1Mu1F .styles_navbar__col__2EaMz {
    height: 100%
  }
.styles_navbar__1Mu1F .styles_navbar__col__2EaMz:first-child {
      text-align: left
    }
@media (max-width: 767px) {

    .styles_navbar__1Mu1F .styles_navbar__col__2EaMz:first-child {
        display: none
    }
      }
.styles_navbar__1Mu1F .styles_navbar__col__2EaMz:not(:first-child):not(:last-child) {
      text-align: center;
    }
.styles_navbar__1Mu1F .styles_navbar__col__2EaMz:last-child {
      text-align: right;
    }
/*
  Brand
  ------------------------------------------------------------
  */
.styles_navbar__1Mu1F .styles_navbar__brand__TyOb5 {
    display: block;
    height: 100%;

    text-align: center;
  }
.styles_navbar__1Mu1F .styles_navbar__brand__link__fc_0x {
    display: inline-block;
    height: 100%;
  }
/*
  Text & Links
  ------------------------------------------------------------
  */
.styles_navbar__1Mu1F .styles_navbar__text__ZohJ3 {
    display: inline;
    position: relative;
    top: calc(50% - 1rem);

    color: #646569;

    font-weight: 600;
    text-transform: uppercase;
  }
.styles_navbar__1Mu1F a.styles_navbar__text__ZohJ3:hover,
    .styles_navbar__1Mu1F a.styles_navbar__text__ZohJ3:active,
    .styles_navbar__1Mu1F a.styles_navbar__text__ZohJ3:focus {
      color: #BDBBBB;
      text-decoration: none;
    }

.styles_header-link__1Vgj0 {
  padding: 0 1rem;
}

.styles_header-link__1Vgj0 a:focus,
  .styles_header-link__1Vgj0 a:hover {
    font-weight: bold;
    text-decoration: none;
  }

.styles_header-link--current__Txhww {
  font-weight: bold;
}

.styles_rightbar__35q4G{
  padding-top:35px;
}

.styles_null_navbar__3_8oq{
  height:0;
  min-height:0;
}

.styles_navbar-dropdown__2IaQG {
  left: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.45s ease;
  z-index: 1030;
  background: #282828; }
  .styles_navbar-dropdown__2IaQG .styles_navbar-logo__OPTeo {
    margin-right: 0.8rem;
    transition: margin 0.3s ease-in-out;
    vertical-align: middle; }
    .styles_navbar-dropdown__2IaQG .styles_navbar-logo__OPTeo img {
      height: 3.125rem;
      transition: all 0.3s ease-in-out; }
    .styles_navbar-dropdown__2IaQG .styles_navbar-logo__OPTeo.styles_mbr-iconfont__8cK9_ {
      font-size: 3.125rem;
      line-height: 3.125rem; }
  .styles_navbar-dropdown__2IaQG .styles_navbar-caption__2wu2o {
    font-weight: 700;
    white-space: normal;
    vertical-align: -4px;
    line-height: 3.125rem !important; }
    .styles_navbar-dropdown__2IaQG .styles_navbar-caption__2wu2o, .styles_navbar-dropdown__2IaQG .styles_navbar-caption__2wu2o:hover {
      color: inherit;
      text-decoration: none; }
  .styles_navbar-dropdown__2IaQG .styles_mbr-iconfont__8cK9_ + .styles_navbar-caption__2wu2o {
    vertical-align: -1px; }
  .styles_navbar-dropdown__2IaQG.styles_navbar-fixed-top__npZ64 {
    position: fixed; }
  .styles_navbar-dropdown__2IaQG .styles_navbar-brand__1_CyG span {
    vertical-align: -4px; }
  .styles_navbar-dropdown__2IaQG.styles_bg-color__1gcAw.styles_transparent__19N9x {
    background: none; }
  .styles_navbar-dropdown__2IaQG.styles_navbar-short__APAqh .styles_navbar-brand__1_CyG {
    padding: 0.625rem 0; }
    .styles_navbar-dropdown__2IaQG.styles_navbar-short__APAqh .styles_navbar-brand__1_CyG span {
      vertical-align: -1px; }
  .styles_navbar-dropdown__2IaQG.styles_navbar-short__APAqh .styles_navbar-caption__2wu2o {
    line-height: 2.375rem !important;
    vertical-align: -2px; }
  .styles_navbar-dropdown__2IaQG.styles_navbar-short__APAqh .styles_navbar-logo__OPTeo {
    margin-right: 0.5rem; }
    .styles_navbar-dropdown__2IaQG.styles_navbar-short__APAqh .styles_navbar-logo__OPTeo img {
      height: 2.375rem; }
    .styles_navbar-dropdown__2IaQG.styles_navbar-short__APAqh .styles_navbar-logo__OPTeo.styles_mbr-iconfont__8cK9_ {
      font-size: 2.375rem;
      line-height: 2.375rem; }
  .styles_navbar-dropdown__2IaQG.styles_navbar-short__APAqh .styles_mbr-table-cell__2wMr8 {
    height: 3.625rem; }
  .styles_navbar-dropdown__2IaQG .styles_navbar-close__11eKY {
    left: 0.6875rem;
    position: fixed;
    top: 0.75rem;
    z-index: 1000; }
  .styles_navbar-dropdown__2IaQG .styles_hamburger-icon__uyjEi {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828; }

.styles_dropdown-menu__1B5Gk .styles_dropdown-toggle__1YILf[data-toggle="dropdown-submenu"]::after {
  border-bottom: 0.35em solid transparent;
  border-left: 0.35em solid;
  border-right: 0;
  border-top: 0.35em solid transparent;
  margin-left: 0.3rem; }

.styles_dropdown-menu__1B5Gk .styles_dropdown-item__3_I9Q:focus {
  outline: 0; }

.styles_nav-dropdown__2WfU2 {
  font-size: 0.75rem;
  font-weight: 500;
  height: auto !important; }
  .styles_nav-dropdown__2WfU2 .styles_nav-btn__1ZoeF {
    padding-left: 1rem; }
  .styles_nav-dropdown__2WfU2 .styles_link__1zW-h {
    margin: .667em 1.667em;
    font-weight: 500;
    padding: 0;
    transition: color .2s ease-in-out; }
    .styles_nav-dropdown__2WfU2 .styles_link__1zW-h.styles_dropdown-toggle__1YILf {
      margin-right: 2.583em; }
      .styles_nav-dropdown__2WfU2 .styles_link__1zW-h.styles_dropdown-toggle__1YILf::after {
        margin-left: .25rem;
        border-top: 0.35em solid;
        border-right: 0.35em solid transparent;
        border-left: 0.35em solid transparent;
        border-bottom: 0; }
      .styles_nav-dropdown__2WfU2 .styles_link__1zW-h.styles_dropdown-toggle__1YILf[aria-expanded="true"] {
        margin: 0;
        padding: 0.667em 3.263em  0.667em 1.667em; }
  .styles_nav-dropdown__2WfU2 .styles_link__1zW-h::after,
  .styles_nav-dropdown__2WfU2 .styles_dropdown-item__3_I9Q::after {
    color: inherit; }
  .styles_nav-dropdown__2WfU2 .styles_btn__2tDrs {
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: 0;
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .styles_nav-dropdown__2WfU2 .styles_dropdown-menu__1B5Gk {
    border-radius: 0;
    border: 0;
    left: 0;
    margin: 0;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    position: relative; }
  .styles_nav-dropdown__2WfU2 .styles_dropdown-submenu__20syY {
    margin-left: 0.125rem;
    top: 0; }
  .styles_nav-dropdown__2WfU2 .styles_dropdown-item__3_I9Q {
    font-weight: 500;
    line-height: 2;
    padding: 0.3846em 4.615em 0.3846em 1.5385em;
    position: relative;
    transition: color .2s ease-in-out, background-color .2s ease-in-out; }
    .styles_nav-dropdown__2WfU2 .styles_dropdown-item__3_I9Q::after {
      margin-top: -0.3077em;
      position: absolute;
      right: 1.1538em;
      top: 50%; }
    .styles_nav-dropdown__2WfU2 .styles_dropdown-item__3_I9Q:focus, .styles_nav-dropdown__2WfU2 .styles_dropdown-item__3_I9Q:hover {
      background: none; }

@media (max-width: 767px) {
  .styles_nav-dropdown__2WfU2.styles_navbar-toggleable-sm__3Iblq {
    bottom: 0;
    display: none;
    left: 0;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    width: 18.75rem;
    z-index: 999; } }
.styles_nav-dropdown__2WfU2.styles_navbar-toggleable-xl__FdRsn {
  bottom: 0;
  display: none;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  width: 18.75rem;
  z-index: 999; }

.styles_nav-dropdown-sm__17moZ {
  display: block !important;
  overflow-x: hidden;
  overflow: auto;
  padding-top: 3.875rem; }
  .styles_nav-dropdown-sm__17moZ::after {
    content: "";
    display: block;
    height: 3rem;
    width: 100%; }
  .styles_nav-dropdown-sm__17moZ.styles_collapse__3VHOt.styles_in__2Gpup ~ .styles_navbar-close__11eKY {
    display: block !important; }
  .styles_nav-dropdown-sm__17moZ.styles_collapsing__1Db24, .styles_nav-dropdown-sm__17moZ.styles_collapse__3VHOt.styles_in__2Gpup {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition: all 0.25s ease-out;
    -webkit-transition: all 0.25s ease-out;
    background: #282828; }
  .styles_nav-dropdown-sm__17moZ.styles_collapsing__1Db24[aria-expanded="false"] {
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%); }
  .styles_nav-dropdown-sm__17moZ .styles_nav-item__1nMBt {
    display: block;
    margin-left: 0 !important;
    padding-left: 0; }
  .styles_nav-dropdown-sm__17moZ .styles_link__1zW-h,
  .styles_nav-dropdown-sm__17moZ .styles_dropdown-item__3_I9Q {
    border-top: 1px dotted rgba(255, 255, 255, 0.1);
    font-size: 0.8125rem;
    line-height: 1.6;
    margin: 0 !important;
    padding: 0.875rem 2.4rem 0.875rem 1.5625rem !important;
    position: relative;
    white-space: normal; }
    .styles_nav-dropdown-sm__17moZ .styles_link__1zW-h:focus, .styles_nav-dropdown-sm__17moZ .styles_link__1zW-h:hover,
    .styles_nav-dropdown-sm__17moZ .styles_dropdown-item__3_I9Q:focus,
    .styles_nav-dropdown-sm__17moZ .styles_dropdown-item__3_I9Q:hover {
      background: rgba(0, 0, 0, 0.2) !important;
      color: #c0a375; }
  .styles_nav-dropdown-sm__17moZ .styles_nav-btn__1ZoeF {
    position: relative;
    padding: 1.5625rem 1.5625rem 0 1.5625rem; }
    .styles_nav-dropdown-sm__17moZ .styles_nav-btn__1ZoeF::before {
      border-top: 1px dotted rgba(255, 255, 255, 0.1);
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .styles_nav-dropdown-sm__17moZ .styles_nav-btn__1ZoeF + .styles_nav-btn__1ZoeF {
      padding-top: 0.625rem; }
      .styles_nav-dropdown-sm__17moZ .styles_nav-btn__1ZoeF + .styles_nav-btn__1ZoeF::before {
        display: none; }
  .styles_nav-dropdown-sm__17moZ .styles_btn__2tDrs {
    padding: 0.625rem 0; }
  .styles_nav-dropdown-sm__17moZ .styles_dropdown-toggle__1YILf[data-toggle="dropdown-submenu"]::after {
    margin-left: .25rem;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0; }
  .styles_nav-dropdown-sm__17moZ .styles_dropdown-toggle__1YILf[data-toggle="dropdown-submenu"][aria-expanded="true"]::after {
    border-top: 0;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0.35em solid; }
  .styles_nav-dropdown-sm__17moZ .styles_dropdown-menu__1B5Gk {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
    float: none;
    border-radius: 0;
    background: none; }
  .styles_nav-dropdown-sm__17moZ .styles_dropdown-submenu__20syY {
    left: 100%;
    margin-left: 0.125rem;
    margin-top: -1.25rem;
    top: 0; }

.styles_navbar-toggleable-sm__3Iblq .styles_nav-dropdown__2WfU2 .styles_dropdown-menu__1B5Gk {
  position: absolute; }

.styles_navbar-toggleable-sm__3Iblq .styles_nav-dropdown__2WfU2 .styles_dropdown-submenu__20syY {
  left: 100%;
  margin-left: 0.125rem;
  margin-top: -1.25rem;
  top: 0; }

.styles_navbar-toggleable-sm__3Iblq.styles_opened__1wJPB .styles_nav-dropdown__2WfU2 .styles_dropdown-menu__1B5Gk {
  position: relative; }

.styles_navbar-toggleable-sm__3Iblq.styles_opened__1wJPB .styles_nav-dropdown__2WfU2 .styles_dropdown-submenu__20syY {
  left: 0;
  margin-left: 00rem;
  margin-top: 0rem;
  top: 0; }

.styles_is-builder__1Qyvw .styles_nav-dropdown__2WfU2.styles_collapsing__1Db24 {
  transition: none !important; }

/*# sourceMappingURL=style.css.map */

.styles_sidebar__3KDSE{
  width: 215px;
  position: fixed;
  top: 60px;
  height: 100%;
}


.styles_sidebar_disabled__23uyx{
  width: 215px;
  position: fixed;
  top: 60px;
  height: 100%;
  background-color:rgba(189, 187, 187,.5);
  z-index:40;
}


.styles_sidebar-selected__J8PHe{
  color:#474747;
  background-color:rgba(189, 187, 187,1);
}

.styles_hidden_copy__b8VyF{
  color:#474747;
  background-color:#474747;
  height:1px;
  z-index:-2;
  position:fixed;
  top:-20px;
}

/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.styles_u-position-absolute__5gqfx {
  position: absolute;
}

.styles_u-position-relative__1uPHy {
  position: relative;
}

.accordion {
  color:white;
  background-color:#474747;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  height:100%;
}

.accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin:0 15px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin:0 15px;
}

.accordion__item--has-icon {
  position: relative;
}

.accordion__title {
  color:white;
  background-color:#474747;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
}

.accordion__title sup {
    font-size: 8px;
    top: -1em;
  }

.accordion a{
  color:white;

}


.accordion a:hover{
  color:rgba(189, 187, 187, 1);
  text-decoration:none;
}

.accordion__title:hover {
  background-color: #373737;
}

.accordion__body {
  display: block;
  -webkit-animation: styles_fadein__2ox88 0.35s ease-in;
          animation: styles_fadein__2ox88 0.35s ease-in;
}

.accordion__body--hidden {
  display: none;
  opacity: 0;
  -webkit-animation: styles_fadein__2ox88 0.35s ease-in;
          animation: styles_fadein__2ox88 0.35s ease-in;
}

.accordion__title > *:last-child
.accordion__body > *:last-child {
  margin-bottom: 0;
}

.accordion__arrow {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 12px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -6px;
}

.accordion__arrow::after
.accordion__arrow::before {
  display: block;
  position: absolute;
  top: 50%;
  width: 10px;
  height: 2px;
  background-color: currentColor;
  content: '';
}

.accordion__arrow::before {
  left: 4px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

[aria-expanded='true'] .accordion__arrow::before [aria-selected='true'] .accordion__arrow::before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.accordion__arrow::after {
  right: 4px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

[aria-expanded='true'] .accordion__arrow::after [aria-selected='true'] .accordion__arrow::after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.accordion__arrow::before
.accordion__arrow::after {
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

.accordion ul{
  list-style-type:none;
}

.accordion li{
  padding:10px 0;
  font-size:.8rem;
  margin-left:-10px;
  cursor:pointer;
}



.accordion li a{
  color:#a7a7a7;
  display: block
}



.accordion li a:hover {
    color: #fff;
  }

.accordion li a[aria-selected='true']{
  font-weight:bold;
  color:white;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@-webkit-keyframes styles_fadein__2ox88 {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@keyframes styles_fadein__2ox88 {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@-webkit-keyframes styles_move-down__sm98z {
  0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  10% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  20% {
      -webkit-transform: translateY(5px);
              transform: translateY(5px);
  }
  30% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}

@keyframes styles_move-down__sm98z {
  0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  10% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  20% {
      -webkit-transform: translateY(5px);
              transform: translateY(5px);
  }
  30% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}

@-webkit-keyframes styles_move-up__xpk5v {
  0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  10% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  20% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
  }
  30% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}

@keyframes styles_move-up__xpk5v {
  0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  10% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  20% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
  }
  30% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}
.accordion__title[aria-selected='true']{
  color:white;
  background-color:rgba(255, 255, 255, 0.2);
}

.accordion__title[aria-selected='true']:hover{
  color:#222;
  outline:0;
}

.accordion__title[aria-selected='true']:focus{
  outline:0;
}

.accordion__title--animated:hover .accordion__arrow {
  -webkit-animation-name: styles_move-down__sm98z;
          animation-name: styles_move-down__sm98z;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
}

.accordion__title--animated[aria-expanded='true']:hover .accordion__arrow {
  -webkit-animation-name: styles_move-up__xpk5v;
          animation-name: styles_move-up__xpk5v;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
}

.styles_on_dashboard__2KynS{
  color:#474747;
  background-color:rgba(189, 187, 187, 1);
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
}

.styles_help__2m561{
  float:left;
  margin-top:-86px;
  color:white;
  padding-left:10px;
  cursor:pointer;
}

.styles_beta__2Be4a{
  margin:0 0 0 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.styles_cc_disabled__Wxkni{
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
  color: #979797;
  cursor: default;
}

.styles_hidden-stripe__1D17U{
  color:#474747;
}

.styles_sidebar__3KDSE.styles_withbanner__2D3oG {
  top: 110px;
}

/*
NavBar Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */
/*
NavBar
================================================================================
*/

.styles_navbar__3mjKq {
  box-shadow: -5px 1px 10px #888;
  z-index: 900 !important;
  height: 60px;
}

/*
  Layout
  ------------------------------------------------------------
  */

.styles_navbar__3mjKq .styles_navbar__row__1zGVm {
    height: 100%;
  }

.styles_navbar__3mjKq .styles_navbar__col__aDhjQ {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center
  }

.styles_navbar__3mjKq .styles_navbar__col__aDhjQ:first-child {
      text-align: center
    }

@media (max-width: 767px) {

    .styles_navbar__3mjKq .styles_navbar__col__aDhjQ:first-child {
        display: none
    }
      }

.styles_navbar__3mjKq .styles_navbar__col__aDhjQ:not(:first-child):not(:last-child) {
      text-align: center;
    }

.styles_navbar__3mjKq .styles_navbar__col__aDhjQ:last-child {
      text-align: right;
    }

/*
  Brand
  ------------------------------------------------------------
  */

.styles_navbar__3mjKq .styles_navbar__brand__10yYa {
    display: block;
    height: 100%;

    text-align: center;
  }

.styles_navbar__3mjKq .styles_navbar__brand__link__1Honk {
    height: 100%;
    text-align: right;
  }

.styles_navbar__3mjKq .styles_navbar__brand__img__2tKc7 {
    position: relative;
    /* width: 120px; */
    max-width: 120px;
    max-height: 46px;
    /* padding-top: 18px; */
  }

/* :global(.navbar-right li) {
    float: right;
  } */

.styles_navbar__3mjKq .styles_location-logo__2c2oD {
    display: inline-block;
    float:right;
    padding-left: 0.5rem;
  }

/* .location-dropdown {
    display: inline-block;
    padding-top: 13px;

    select {
      font-size: 17px;
      font-weight: 700;
    }
  } */

.styles_navbar__3mjKq .styles_location-dropdown__1qYeG {
  float: right;
  margin-top: 0.5rem;
}

.styles_navbar__3mjKq .styles_location__30BXd {
    height: 100%;
  }

.styles_navbar__3mjKq .styles_mini_logo__LlaNV{
    height:40px;
    padding-right:10px;
    margin-top: 10px;
  }

.styles_navbar__3mjKq .dropdown-menu>li>a {
    padding: 3px 0
  }

.styles_navbar__3mjKq .dropdown-menu>li>a:hover {
      background-color: inherit;
    }

.styles_navbar__3mjKq .styles_location-logo-bigger__5PxHt {
  width: 100px;
  height: 100px;
  display: inline-block;
}

.styles_navbar__3mjKq .styles_location-logo-bigger__5PxHt img {
    width: 80px;
  }

.styles_navbar__3mjKq .styles_location-selector__293ak {
  text-align: right;
  padding-right: 1em;
}

.styles_navbar__3mjKq .styles_location-welcome__3E47T {
  font-size: 13px;
  /* margin-right: 14px; */
  margin-bottom: -3px;
}

.styles_navbar__3mjKq .styles_topdropdown__WAv38 ul {
  width: 300px;
  border-top: none;
  border-radius: 0;
  margin-top: 15px;
  padding-top: 0;
  text-align: center;
}

/*
  Text & Links
  ------------------------------------------------------------
  */

.styles_navbar__3mjKq .styles_navbar__text__3fqZN {
    display: inline;
    position: relative;
    top: calc(50% - 1rem);

    color: #646569;

    font-weight: 600;
    text-transform: uppercase;
  }

.styles_navbar__3mjKq a.styles_navbar__text__3fqZN:hover,
    .styles_navbar__3mjKq a.styles_navbar__text__3fqZN:active,
    .styles_navbar__3mjKq a.styles_navbar__text__3fqZN:focus {
      color: #BDBBBB;
      text-decoration: none;
    }

.styles_header-link__1vZp4 {
  padding: 0 1rem;
}

.styles_header-link__1vZp4 a {
    display: block;
  }

.styles_header-link__1vZp4 a:focus,
  .styles_header-link__1vZp4 a:hover {
    font-weight: normal;
    text-decoration: none;
    background-color: transparent;
  }

.styles_header-link__1vZp4.styles_locations__2r9DG {
    border-top: 1px solid #ddd;
    padding: 0 0 1em;
    text-align: left;
  }

.styles_header-link__1vZp4.styles_top-link__3G8A0 {
      display: inline-block
  }

.styles_header-link__1vZp4.styles_top-link__3G8A0:hover {
      background-color: #f6f6f6;
    }

.styles_header-link__1vZp4.styles_top-link__3G8A0 svg {
      margin-right: 0.3em;
    }


.styles_header-link--current__1bu0m {
  font-weight: bold;
}

.styles_rightbar__3hTWD{
  padding-top:35px;
}

.styles_null_navbar__2ChPG{
  height:0;
  min-height:0;
}

.styles_navbar-dropdown__15Aza {
  left: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.45s ease;
  z-index: 1030;
  background: #282828; }
  .styles_navbar-dropdown__15Aza .styles_navbar-logo__3NG7o {
    margin-right: 0.8rem;
    transition: margin 0.3s ease-in-out;
    vertical-align: middle; }
    .styles_navbar-dropdown__15Aza .styles_navbar-logo__3NG7o img {
      height: 3.125rem;
      transition: all 0.3s ease-in-out; }
    .styles_navbar-dropdown__15Aza .styles_navbar-logo__3NG7o.styles_mbr-iconfont__OskID {
      font-size: 3.125rem;
      line-height: 3.125rem; }
  .styles_navbar-dropdown__15Aza .styles_navbar-caption__16mAN {
    font-weight: 700;
    white-space: normal;
    vertical-align: -4px;
    line-height: 3.125rem !important; }
    .styles_navbar-dropdown__15Aza .styles_navbar-caption__16mAN, .styles_navbar-dropdown__15Aza .styles_navbar-caption__16mAN:hover {
      color: inherit;
      text-decoration: none; }
  .styles_navbar-dropdown__15Aza .styles_mbr-iconfont__OskID + .styles_navbar-caption__16mAN {
    vertical-align: -1px; }
  .styles_navbar-dropdown__15Aza.styles_navbar-fixed-top__34dSM {
    position: fixed; }
  .styles_navbar-dropdown__15Aza .styles_navbar-brand__2damP span {
    vertical-align: -4px; }
  .styles_navbar-dropdown__15Aza.styles_bg-color__296g0.styles_transparent__3VeCK {
    background: none; }
  .styles_navbar-dropdown__15Aza.styles_navbar-short__1OpCn .styles_navbar-brand__2damP {
    padding: 0.625rem 0; }
    .styles_navbar-dropdown__15Aza.styles_navbar-short__1OpCn .styles_navbar-brand__2damP span {
      vertical-align: -1px; }
  .styles_navbar-dropdown__15Aza.styles_navbar-short__1OpCn .styles_navbar-caption__16mAN {
    line-height: 2.375rem !important;
    vertical-align: -2px; }
  .styles_navbar-dropdown__15Aza.styles_navbar-short__1OpCn .styles_navbar-logo__3NG7o {
    margin-right: 0.5rem; }
    .styles_navbar-dropdown__15Aza.styles_navbar-short__1OpCn .styles_navbar-logo__3NG7o img {
      height: 2.375rem; }
    .styles_navbar-dropdown__15Aza.styles_navbar-short__1OpCn .styles_navbar-logo__3NG7o.styles_mbr-iconfont__OskID {
      font-size: 2.375rem;
      line-height: 2.375rem; }
  .styles_navbar-dropdown__15Aza.styles_navbar-short__1OpCn .styles_mbr-table-cell__3DjAU {
    height: 3.625rem; }
  .styles_navbar-dropdown__15Aza .styles_navbar-close__16kvo {
    left: 0.6875rem;
    position: fixed;
    top: 0.75rem;
    z-index: 1000; }
  .styles_navbar-dropdown__15Aza .styles_hamburger-icon__YM-_J {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828; }

.styles_dropdown-menu__1wn1h .styles_dropdown-toggle__1dkkR[data-toggle="dropdown-submenu"]::after {
  border-bottom: 0.35em solid transparent;
  border-left: 0.35em solid;
  border-right: 0;
  border-top: 0.35em solid transparent;
  margin-left: 0.3rem; }

.styles_dropdown-menu__1wn1h .styles_dropdown-item__2vC1P:focus {
  outline: 0; }

.styles_nav-dropdown__2B2xE {
  font-size: 0.75rem;
  font-weight: 500;
  height: auto !important; }
  .styles_nav-dropdown__2B2xE .styles_nav-btn__2kFpS {
    padding-left: 1rem; }
  .styles_nav-dropdown__2B2xE .styles_link__22i96 {
    margin: .667em 1.667em;
    font-weight: 500;
    padding: 0;
    transition: color .2s ease-in-out; }
    .styles_nav-dropdown__2B2xE .styles_link__22i96.styles_dropdown-toggle__1dkkR {
      margin-right: 2.583em; }
      .styles_nav-dropdown__2B2xE .styles_link__22i96.styles_dropdown-toggle__1dkkR::after {
        margin-left: .25rem;
        border-top: 0.35em solid;
        border-right: 0.35em solid transparent;
        border-left: 0.35em solid transparent;
        border-bottom: 0; }
      .styles_nav-dropdown__2B2xE .styles_link__22i96.styles_dropdown-toggle__1dkkR[aria-expanded="true"] {
        margin: 0;
        padding: 0.667em 3.263em  0.667em 1.667em; }
  .styles_nav-dropdown__2B2xE .styles_link__22i96::after,
  .styles_nav-dropdown__2B2xE .styles_dropdown-item__2vC1P::after {
    color: inherit; }
  .styles_nav-dropdown__2B2xE .styles_btn__1wVLD {
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: 0;
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .styles_nav-dropdown__2B2xE .styles_dropdown-menu__1wn1h {
    border-radius: 0;
    border: 0;
    left: 0;
    margin: 0;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    position: relative; }
  .styles_nav-dropdown__2B2xE .styles_dropdown-submenu__3mIPI {
    margin-left: 0.125rem;
    top: 0; }
  .styles_nav-dropdown__2B2xE .styles_dropdown-item__2vC1P {
    font-weight: 500;
    line-height: 2;
    padding: 0.3846em 4.615em 0.3846em 1.5385em;
    position: relative;
    transition: color .2s ease-in-out, background-color .2s ease-in-out; }
    .styles_nav-dropdown__2B2xE .styles_dropdown-item__2vC1P::after {
      margin-top: -0.3077em;
      position: absolute;
      right: 1.1538em;
      top: 50%; }
    .styles_nav-dropdown__2B2xE .styles_dropdown-item__2vC1P:focus, .styles_nav-dropdown__2B2xE .styles_dropdown-item__2vC1P:hover {
      background: none; }

@media (max-width: 767px) {
  .styles_nav-dropdown__2B2xE.styles_navbar-toggleable-sm__3ySqs {
    bottom: 0;
    display: none;
    left: 0;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    width: 18.75rem;
    z-index: 999; } }
.styles_nav-dropdown__2B2xE.styles_navbar-toggleable-xl__1kSDj {
  bottom: 0;
  display: none;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  width: 18.75rem;
  z-index: 999; }

.styles_nav-dropdown-sm__1Myqc {
  display: block !important;
  overflow-x: hidden;
  overflow: auto;
  padding-top: 3.875rem; }
  .styles_nav-dropdown-sm__1Myqc::after {
    content: "";
    display: block;
    height: 3rem;
    width: 100%; }
  .styles_nav-dropdown-sm__1Myqc.styles_collapse__3-UYb.styles_in__2DcLK ~ .styles_navbar-close__16kvo {
    display: block !important; }
  .styles_nav-dropdown-sm__1Myqc.styles_collapsing__1tzIr, .styles_nav-dropdown-sm__1Myqc.styles_collapse__3-UYb.styles_in__2DcLK {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition: all 0.25s ease-out;
    -webkit-transition: all 0.25s ease-out;
    background: #282828; }
  .styles_nav-dropdown-sm__1Myqc.styles_collapsing__1tzIr[aria-expanded="false"] {
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%); }
  .styles_nav-dropdown-sm__1Myqc .styles_nav-item__23DAr {
    display: block;
    margin-left: 0 !important;
    padding-left: 0; }
  .styles_nav-dropdown-sm__1Myqc .styles_link__22i96,
  .styles_nav-dropdown-sm__1Myqc .styles_dropdown-item__2vC1P {
    border-top: 1px dotted rgba(255, 255, 255, 0.1);
    font-size: 0.8125rem;
    line-height: 1.6;
    margin: 0 !important;
    padding: 0.875rem 2.4rem 0.875rem 1.5625rem !important;
    position: relative;
    white-space: normal; }
    .styles_nav-dropdown-sm__1Myqc .styles_link__22i96:focus, .styles_nav-dropdown-sm__1Myqc .styles_link__22i96:hover,
    .styles_nav-dropdown-sm__1Myqc .styles_dropdown-item__2vC1P:focus,
    .styles_nav-dropdown-sm__1Myqc .styles_dropdown-item__2vC1P:hover {
      background: rgba(0, 0, 0, 0.2) !important;
      color: #c0a375; }
  .styles_nav-dropdown-sm__1Myqc .styles_nav-btn__2kFpS {
    position: relative;
    padding: 1.5625rem 1.5625rem 0 1.5625rem; }
    .styles_nav-dropdown-sm__1Myqc .styles_nav-btn__2kFpS::before {
      border-top: 1px dotted rgba(255, 255, 255, 0.1);
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .styles_nav-dropdown-sm__1Myqc .styles_nav-btn__2kFpS + .styles_nav-btn__2kFpS {
      padding-top: 0.625rem; }
      .styles_nav-dropdown-sm__1Myqc .styles_nav-btn__2kFpS + .styles_nav-btn__2kFpS::before {
        display: none; }
  .styles_nav-dropdown-sm__1Myqc .styles_btn__1wVLD {
    padding: 0.625rem 0; }
  .styles_nav-dropdown-sm__1Myqc .styles_dropdown-toggle__1dkkR[data-toggle="dropdown-submenu"]::after {
    margin-left: .25rem;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0; }
  .styles_nav-dropdown-sm__1Myqc .styles_dropdown-toggle__1dkkR[data-toggle="dropdown-submenu"][aria-expanded="true"]::after {
    border-top: 0;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0.35em solid; }
  .styles_nav-dropdown-sm__1Myqc .styles_dropdown-menu__1wn1h {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
    float: none;
    border-radius: 0;
    background: none; }
  .styles_nav-dropdown-sm__1Myqc .styles_dropdown-submenu__3mIPI {
    left: 100%;
    margin-left: 0.125rem;
    margin-top: -1.25rem;
    top: 0; }

.styles_navbar-toggleable-sm__3ySqs .styles_nav-dropdown__2B2xE .styles_dropdown-menu__1wn1h {
  position: absolute; }

.styles_navbar-toggleable-sm__3ySqs .styles_nav-dropdown__2B2xE .styles_dropdown-submenu__3mIPI {
  left: 100%;
  margin-left: 0.125rem;
  margin-top: -1.25rem;
  top: 0; }

.styles_navbar-toggleable-sm__3ySqs.styles_opened__1tNVq .styles_nav-dropdown__2B2xE .styles_dropdown-menu__1wn1h {
  position: relative; }

.styles_navbar-toggleable-sm__3ySqs.styles_opened__1tNVq .styles_nav-dropdown__2B2xE .styles_dropdown-submenu__3mIPI {
  left: 0;
  margin-left: 00rem;
  margin-top: 0rem;
  top: 0; }

.styles_is-builder__3E64P .styles_nav-dropdown__2B2xE.styles_collapsing__1tzIr {
  transition: none !important; }

/*# sourceMappingURL=style.css.map */


.navbar-default .container{
  height:60px;
  max-width: 1800px;
  margin-left: 0;
}

.form-group{
  margin-bottom:none !important;
}

.navbar-nav .form-control{
  background-color:white;
  color: #aaa;
  height: 100%;
  padding-left: 20px;
}

.navbar-nav .form-control::-webkit-input-placeholder,
.navbar-nav .form-control::-moz-placeholder,
.navbar-nav .form-control::-ms-input-placeholder{
  color: #aaa;
}

.navbar-nav .input-group{
  height: 100%;
}

.navbar-nav .input-group .form-control{
  border:none;
}


.navbar-nav .input-group .input-group-btn .btn{
  border:none;
}

.styles_search-nav__3KMAp{
  color:#979797;
  border-left:1px solid #bbb;
  border-right:1px solid #bbb;
  height: 100%;
}

.styles_likeGmail__2ih0M{
  min-height:100px;
  max-height:300px;
  overflow: auto;
}

.styles_likeGmail__2ih0M .styles_pulldown-location__3Y8OT {
    height: 2rem;
    line-height: 2rem;
    border-bottom: 1px solid #eee;
    padding: 0 1rem
  }

.styles_likeGmail__2ih0M .styles_pulldown-location__3Y8OT:hover {
      background-color: #f6f6f6;
    }

.styles_likeGmail__2ih0M .styles_pulldown-location__3Y8OT.styles_master-dashboard__1jS5W {}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background:#777;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #777;
}

.styles_topdropdown__WAv38{
  white-space: pre-wrap;
}
.styles_topdropdownsecond__34ZUt{
  white-space: pre-wrap;
  font-size:13px;
  font-weight:bold;
  top:-4px;
}
.styles_topdropdownsecond__34ZUt a{
  color:white;
}

.navbar-default .navbar-nav>li>a{
  padding-top:8px;
}

.styles_navbar__3mjKq.styles_withbanner__OvIZf {
  top: 50px;
}

.styles_url__1mPL_{
  text-align:left
}

.styles_url__1mPL_:hover {
    background-color: #e8e8e8;
  }

.styles_url-separator__1Yi0c{
  color:#d7d7d7;
}

.styles_pixel__1O6k9 {
  width: 1px;
  height: 1px;
  background: gray;
}


 .Toastify__toast--info svg {
    color: #3498db;
 }
 .Toastify__toast--success svg {
  color: #07bc0c;
 }
 .Toastify__toast--warning svg {
  color: #f1c40f;
 }
 .Toastify__toast--error svg {
  color: #e74c3c;
 }
/*
NavBar Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */


/*
NavBar
================================================================================
*/
.styles_globalbanner__2vy4P {
  height: 50px;
  display: block;
  background: black;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  font-size: 16px;
  line-height: 50px;
  text-align:center;
  color:white;
  font-weight:600;

}
.styles_globalbanner__2vy4P a {
      color: #fff;
      -webkit-font-smoothing: antialiased
    }
.styles_globalbanner__2vy4P a:hover, .styles_globalbanner__2vy4P a:active, .styles_globalbanner__2vy4P a:focus {
        color: #fff;
        text-decoration: none;
      }
.styles_globalbanner__2vy4P .styles_headline__3LKtw {
      text-transform: uppercase;
      font-size: 16px;
    }
.styles_globalbanner__2vy4P .styles_cta__3fYEv {
      color: #f7fa07;
      text-transform: uppercase;
      margin-left: 1rem
    }
.styles_globalbanner__2vy4P .styles_cta__3fYEv:hover {
        color: #f7fa07;
      }


/*
NavBar Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */
/*
NavBar
================================================================================
*/
.styles_navbar__3Plhx {
  box-shadow: -5px 1px 10px #888;
  z-index: 900 !important;
  height: 60px;
}
/*
  Layout
  ------------------------------------------------------------
  */
.styles_navbar__3Plhx .styles_navbar__row__2dFRP {
    height: 100%;
  }
.styles_navbar__3Plhx .styles_navbar__col__-qFXZ {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center
  }
.styles_navbar__3Plhx .styles_navbar__col__-qFXZ:first-child {
      text-align: center
    }
@media (max-width: 767px) {

    .styles_navbar__3Plhx .styles_navbar__col__-qFXZ:first-child {
        display: none
    }
      }
.styles_navbar__3Plhx .styles_navbar__col__-qFXZ:not(:first-child):not(:last-child) {
      text-align: center;
    }
.styles_navbar__3Plhx .styles_navbar__col__-qFXZ:last-child {
      text-align: right;
    }
/*
  Brand
  ------------------------------------------------------------
  */
.styles_navbar__3Plhx .styles_navbar__brand__2bydg {
    display: block;
    height: 100%;

    text-align: center;
  }
.styles_navbar__3Plhx .styles_navbar__brand__link__3X2dE {
    height: 100%;
    text-align: right;
  }
.styles_navbar__3Plhx .styles_navbar__brand__img__IO2tA {
    position: relative;
    /* width: 120px; */
    max-width: 120px;
    max-height: 46px;
    /* padding-top: 18px; */
  }
/* :global(.navbar-right li) {
    float: right;
  } */
.styles_navbar__3Plhx .styles_location-logo__3k2J7 {
    display: inline-block;
    float:right;
    padding-left: 0.5rem;
  }
/* .location-dropdown {
    display: inline-block;
    padding-top: 13px;

    select {
      font-size: 17px;
      font-weight: 700;
    }
  } */
.styles_navbar__3Plhx .styles_location-dropdown__1BA0i {
  float: right;
  margin-top: 0.5rem;
}
.styles_navbar__3Plhx .styles_location__3_P3u {
    height: 100%;
  }
.styles_navbar__3Plhx .styles_mini_logo__mNZ6s{
    height:40px;
    padding-right:10px;
    margin-top: 10px;
  }
.styles_navbar__3Plhx .dropdown-menu>li>a {
    padding: 3px 0
  }
.styles_navbar__3Plhx .dropdown-menu>li>a:hover {
      background-color: inherit;
    }
.styles_navbar__3Plhx .styles_location-logo-bigger__1k7N8 {
  width: 100px;
  height: 100px;
  display: inline-block;
}
.styles_navbar__3Plhx .styles_location-logo-bigger__1k7N8 img {
    width: 80px;
  }
.styles_navbar__3Plhx .styles_location-selector__O835W {
  text-align: right;
  padding-right: 1em;
}
.styles_navbar__3Plhx .styles_location-welcome__21u7N {
  font-size: 13px;
  /* margin-right: 14px; */
  margin-bottom: -3px;
}
.styles_navbar__3Plhx .styles_topdropdown__3ZQjJ ul {
  width: 300px;
  border-top: none;
  border-radius: 0;
  margin-top: 15px;
  padding-top: 0;
  text-align: center;
}
/*
  Text & Links
  ------------------------------------------------------------
  */
.styles_navbar__3Plhx .styles_navbar__text__kS2oG {
    display: inline;
    position: relative;
    top: calc(50% - 1rem);

    color: #646569;

    font-weight: 600;
    text-transform: uppercase;
  }
.styles_navbar__3Plhx a.styles_navbar__text__kS2oG:hover,
    .styles_navbar__3Plhx a.styles_navbar__text__kS2oG:active,
    .styles_navbar__3Plhx a.styles_navbar__text__kS2oG:focus {
      color: #BDBBBB;
      text-decoration: none;
    }

.styles_header-link__Xg1QQ {
  padding: 0 1rem;
}

.styles_header-link__Xg1QQ a {
    display: block;
  }

.styles_header-link__Xg1QQ a:focus,
  .styles_header-link__Xg1QQ a:hover {
    font-weight: normal;
    text-decoration: none;
    background-color: transparent;
  }

.styles_header-link__Xg1QQ.styles_locations__2VHJb {
    border-top: 1px solid #ddd;
    padding: 0 0 1em;
    text-align: left;
  }

.styles_header-link__Xg1QQ.styles_top-link__DZCS8 {
      display: inline-block
  }

.styles_header-link__Xg1QQ.styles_top-link__DZCS8:hover {
      background-color: #f6f6f6;
    }

.styles_header-link__Xg1QQ.styles_top-link__DZCS8 svg {
      margin-right: 0.3em;
    }


.styles_header-link--current__1fyfi {
  font-weight: bold;
}

.styles_rightbar__2yHWZ{
  padding-top:35px;
}

.styles_null_navbar__3AZr1{
  height:0;
  min-height:0;
}

.styles_navbar-dropdown__q80oi {
  left: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.45s ease;
  z-index: 1030;
  background: #282828; }
  .styles_navbar-dropdown__q80oi .styles_navbar-logo__KKR5w {
    margin-right: 0.8rem;
    transition: margin 0.3s ease-in-out;
    vertical-align: middle; }
    .styles_navbar-dropdown__q80oi .styles_navbar-logo__KKR5w img {
      height: 3.125rem;
      transition: all 0.3s ease-in-out; }
    .styles_navbar-dropdown__q80oi .styles_navbar-logo__KKR5w.styles_mbr-iconfont__gaIRm {
      font-size: 3.125rem;
      line-height: 3.125rem; }
  .styles_navbar-dropdown__q80oi .styles_navbar-caption__2eiGK {
    font-weight: 700;
    white-space: normal;
    vertical-align: -4px;
    line-height: 3.125rem !important; }
    .styles_navbar-dropdown__q80oi .styles_navbar-caption__2eiGK, .styles_navbar-dropdown__q80oi .styles_navbar-caption__2eiGK:hover {
      color: inherit;
      text-decoration: none; }
  .styles_navbar-dropdown__q80oi .styles_mbr-iconfont__gaIRm + .styles_navbar-caption__2eiGK {
    vertical-align: -1px; }
  .styles_navbar-dropdown__q80oi.styles_navbar-fixed-top__1aWdR {
    position: fixed; }
  .styles_navbar-dropdown__q80oi .styles_navbar-brand__3JCll span {
    vertical-align: -4px; }
  .styles_navbar-dropdown__q80oi.styles_bg-color__2CUR6.styles_transparent__3tmOB {
    background: none; }
  .styles_navbar-dropdown__q80oi.styles_navbar-short__L7X9A .styles_navbar-brand__3JCll {
    padding: 0.625rem 0; }
    .styles_navbar-dropdown__q80oi.styles_navbar-short__L7X9A .styles_navbar-brand__3JCll span {
      vertical-align: -1px; }
  .styles_navbar-dropdown__q80oi.styles_navbar-short__L7X9A .styles_navbar-caption__2eiGK {
    line-height: 2.375rem !important;
    vertical-align: -2px; }
  .styles_navbar-dropdown__q80oi.styles_navbar-short__L7X9A .styles_navbar-logo__KKR5w {
    margin-right: 0.5rem; }
    .styles_navbar-dropdown__q80oi.styles_navbar-short__L7X9A .styles_navbar-logo__KKR5w img {
      height: 2.375rem; }
    .styles_navbar-dropdown__q80oi.styles_navbar-short__L7X9A .styles_navbar-logo__KKR5w.styles_mbr-iconfont__gaIRm {
      font-size: 2.375rem;
      line-height: 2.375rem; }
  .styles_navbar-dropdown__q80oi.styles_navbar-short__L7X9A .styles_mbr-table-cell__3nQQd {
    height: 3.625rem; }
  .styles_navbar-dropdown__q80oi .styles_navbar-close__3uYDe {
    left: 0.6875rem;
    position: fixed;
    top: 0.75rem;
    z-index: 1000; }
  .styles_navbar-dropdown__q80oi .styles_hamburger-icon__1B1hm {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828; }

.styles_dropdown-menu__bs2tt .styles_dropdown-toggle__g8A5_[data-toggle="dropdown-submenu"]::after {
  border-bottom: 0.35em solid transparent;
  border-left: 0.35em solid;
  border-right: 0;
  border-top: 0.35em solid transparent;
  margin-left: 0.3rem; }

.styles_dropdown-menu__bs2tt .styles_dropdown-item__3TaTL:focus {
  outline: 0; }

.styles_nav-dropdown__1fI4O {
  font-size: 0.75rem;
  font-weight: 500;
  height: auto !important; }
  .styles_nav-dropdown__1fI4O .styles_nav-btn__23HYs {
    padding-left: 1rem; }
  .styles_nav-dropdown__1fI4O .styles_link__3dXTm {
    margin: .667em 1.667em;
    font-weight: 500;
    padding: 0;
    transition: color .2s ease-in-out; }
    .styles_nav-dropdown__1fI4O .styles_link__3dXTm.styles_dropdown-toggle__g8A5_ {
      margin-right: 2.583em; }
      .styles_nav-dropdown__1fI4O .styles_link__3dXTm.styles_dropdown-toggle__g8A5_::after {
        margin-left: .25rem;
        border-top: 0.35em solid;
        border-right: 0.35em solid transparent;
        border-left: 0.35em solid transparent;
        border-bottom: 0; }
      .styles_nav-dropdown__1fI4O .styles_link__3dXTm.styles_dropdown-toggle__g8A5_[aria-expanded="true"] {
        margin: 0;
        padding: 0.667em 3.263em  0.667em 1.667em; }
  .styles_nav-dropdown__1fI4O .styles_link__3dXTm::after,
  .styles_nav-dropdown__1fI4O .styles_dropdown-item__3TaTL::after {
    color: inherit; }
  .styles_nav-dropdown__1fI4O .styles_btn__3fZOD {
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: 0;
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .styles_nav-dropdown__1fI4O .styles_dropdown-menu__bs2tt {
    border-radius: 0;
    border: 0;
    left: 0;
    margin: 0;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    position: relative; }
  .styles_nav-dropdown__1fI4O .styles_dropdown-submenu__1Xd7x {
    margin-left: 0.125rem;
    top: 0; }
  .styles_nav-dropdown__1fI4O .styles_dropdown-item__3TaTL {
    font-weight: 500;
    line-height: 2;
    padding: 0.3846em 4.615em 0.3846em 1.5385em;
    position: relative;
    transition: color .2s ease-in-out, background-color .2s ease-in-out; }
    .styles_nav-dropdown__1fI4O .styles_dropdown-item__3TaTL::after {
      margin-top: -0.3077em;
      position: absolute;
      right: 1.1538em;
      top: 50%; }
    .styles_nav-dropdown__1fI4O .styles_dropdown-item__3TaTL:focus, .styles_nav-dropdown__1fI4O .styles_dropdown-item__3TaTL:hover {
      background: none; }

@media (max-width: 767px) {
  .styles_nav-dropdown__1fI4O.styles_navbar-toggleable-sm__G8Cmr {
    bottom: 0;
    display: none;
    left: 0;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    width: 18.75rem;
    z-index: 999; } }
.styles_nav-dropdown__1fI4O.styles_navbar-toggleable-xl__gjHvZ {
  bottom: 0;
  display: none;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  width: 18.75rem;
  z-index: 999; }

.styles_nav-dropdown-sm__dZl6m {
  display: block !important;
  overflow-x: hidden;
  overflow: auto;
  padding-top: 3.875rem; }
  .styles_nav-dropdown-sm__dZl6m::after {
    content: "";
    display: block;
    height: 3rem;
    width: 100%; }
  .styles_nav-dropdown-sm__dZl6m.styles_collapse__-TPtv.styles_in__1Jshw ~ .styles_navbar-close__3uYDe {
    display: block !important; }
  .styles_nav-dropdown-sm__dZl6m.styles_collapsing__1QfG7, .styles_nav-dropdown-sm__dZl6m.styles_collapse__-TPtv.styles_in__1Jshw {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition: all 0.25s ease-out;
    -webkit-transition: all 0.25s ease-out;
    background: #282828; }
  .styles_nav-dropdown-sm__dZl6m.styles_collapsing__1QfG7[aria-expanded="false"] {
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%); }
  .styles_nav-dropdown-sm__dZl6m .styles_nav-item__bL9uP {
    display: block;
    margin-left: 0 !important;
    padding-left: 0; }
  .styles_nav-dropdown-sm__dZl6m .styles_link__3dXTm,
  .styles_nav-dropdown-sm__dZl6m .styles_dropdown-item__3TaTL {
    border-top: 1px dotted rgba(255, 255, 255, 0.1);
    font-size: 0.8125rem;
    line-height: 1.6;
    margin: 0 !important;
    padding: 0.875rem 2.4rem 0.875rem 1.5625rem !important;
    position: relative;
    white-space: normal; }
    .styles_nav-dropdown-sm__dZl6m .styles_link__3dXTm:focus, .styles_nav-dropdown-sm__dZl6m .styles_link__3dXTm:hover,
    .styles_nav-dropdown-sm__dZl6m .styles_dropdown-item__3TaTL:focus,
    .styles_nav-dropdown-sm__dZl6m .styles_dropdown-item__3TaTL:hover {
      background: rgba(0, 0, 0, 0.2) !important;
      color: #c0a375; }
  .styles_nav-dropdown-sm__dZl6m .styles_nav-btn__23HYs {
    position: relative;
    padding: 1.5625rem 1.5625rem 0 1.5625rem; }
    .styles_nav-dropdown-sm__dZl6m .styles_nav-btn__23HYs::before {
      border-top: 1px dotted rgba(255, 255, 255, 0.1);
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .styles_nav-dropdown-sm__dZl6m .styles_nav-btn__23HYs + .styles_nav-btn__23HYs {
      padding-top: 0.625rem; }
      .styles_nav-dropdown-sm__dZl6m .styles_nav-btn__23HYs + .styles_nav-btn__23HYs::before {
        display: none; }
  .styles_nav-dropdown-sm__dZl6m .styles_btn__3fZOD {
    padding: 0.625rem 0; }
  .styles_nav-dropdown-sm__dZl6m .styles_dropdown-toggle__g8A5_[data-toggle="dropdown-submenu"]::after {
    margin-left: .25rem;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0; }
  .styles_nav-dropdown-sm__dZl6m .styles_dropdown-toggle__g8A5_[data-toggle="dropdown-submenu"][aria-expanded="true"]::after {
    border-top: 0;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0.35em solid; }
  .styles_nav-dropdown-sm__dZl6m .styles_dropdown-menu__bs2tt {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
    float: none;
    border-radius: 0;
    background: none; }
  .styles_nav-dropdown-sm__dZl6m .styles_dropdown-submenu__1Xd7x {
    left: 100%;
    margin-left: 0.125rem;
    margin-top: -1.25rem;
    top: 0; }

.styles_navbar-toggleable-sm__G8Cmr .styles_nav-dropdown__1fI4O .styles_dropdown-menu__bs2tt {
  position: absolute; }

.styles_navbar-toggleable-sm__G8Cmr .styles_nav-dropdown__1fI4O .styles_dropdown-submenu__1Xd7x {
  left: 100%;
  margin-left: 0.125rem;
  margin-top: -1.25rem;
  top: 0; }

.styles_navbar-toggleable-sm__G8Cmr.styles_opened__10Kwm .styles_nav-dropdown__1fI4O .styles_dropdown-menu__bs2tt {
  position: relative; }

.styles_navbar-toggleable-sm__G8Cmr.styles_opened__10Kwm .styles_nav-dropdown__1fI4O .styles_dropdown-submenu__1Xd7x {
  left: 0;
  margin-left: 00rem;
  margin-top: 0rem;
  top: 0; }

.styles_is-builder__3ne58 .styles_nav-dropdown__1fI4O.styles_collapsing__1QfG7 {
  transition: none !important; }

/*# sourceMappingURL=style.css.map */


.navbar-default .container{
  height:60px;
  max-width: 1800px;
  margin-left: 0;
}

.form-group{
  margin-bottom:none !important;
}

.navbar-nav .form-control{
  background-color:white;
  color: #aaa;
  height: 100%;
  padding-left: 20px;
}

.navbar-nav .form-control::-webkit-input-placeholder,
.navbar-nav .form-control::-moz-placeholder,
.navbar-nav .form-control::-ms-input-placeholder{
  color: #aaa;
}

.navbar-nav .input-group{
  height: 100%;
}

.navbar-nav .input-group .form-control{
  border:none;
}


.navbar-nav .input-group .input-group-btn .btn{
  border:none;
}

.styles_search-nav__jsZGb{
  color:#979797;
  border-left:1px solid #bbb;
  border-right:1px solid #bbb;
  height: 100%;
}

.styles_likeGmail__3VbJY{
  min-height:100px;
  max-height:300px;
  overflow: auto;
}

.styles_likeGmail__3VbJY .styles_pulldown-location__2seYX {
    height: 2rem;
    line-height: 2rem;
    border-bottom: 1px solid #eee;
    padding: 0 1rem
  }

.styles_likeGmail__3VbJY .styles_pulldown-location__2seYX:hover {
      background-color: #f6f6f6;
    }

.styles_likeGmail__3VbJY .styles_pulldown-location__2seYX.styles_master-dashboard__19R9W {}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background:#777;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #777;
}

.styles_topdropdown__3ZQjJ{
  white-space: pre-wrap;
}

.styles_topdropdownsecond__3WdJB{
  white-space: pre-wrap;
  font-size:13px;
  font-weight:bold;
  top:-4px;
}
.styles_topdropdownsecond__3WdJB a{
  color:white;
}


.navbar-default .navbar-nav>li>a{
  padding-top:8px;
}


.styles_url__2kI2d{
  text-align:left
}


.styles_url__2kI2d:hover {
    background-color: #e8e8e8;
  }

.styles_url-separator__2ypTX{
  color:#d7d7d7;
}
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/




/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/

/*
Colors
------------------------------------------------------------
*/   /* rgb(  0, 161, 263) */ /* rgb(115, 200, 175) */   /* darker VHQblue */    /* rgb(100, 101, 105) */  /* rgb(189, 187, 187) */    /* rgb(100, 101, 105) */


/*
Fonts
------------------------------------------------------------
*/

/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */

/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */

.dental-sidenav .nav > li > a.active {
  background-color: #FFF !important;
  color: #141414;
}

li.styles_nav-item__D3_jW {
  margin-bottom: 5px
}

li.styles_nav-item__D3_jW > a {
    color: #FFF
  }

li.styles_nav-item__D3_jW > a:hover {
      color: #141414;
    }

.styles_dental-sidenav__3tIvz {
  padding: 1em 1.5em
}

.styles_dental-sidenav__3tIvz.styles_client__rM_pz {
    background-color: #15BF71;
  }

.styles_dental-sidenav__3tIvz.styles_client__rM_pz li.styles_nav-item__D3_jW > a {
      background-color: #0E7847;
      white-space: nowrap
    }

.styles_dental-sidenav__3tIvz.styles_client__rM_pz li.styles_nav-item__D3_jW > a:hover {
        background-color: #FFF;
      }

.styles_dental-sidenav__3tIvz.styles_dentist__2CuXU {
    background-color: #00C0EF;
  }

.styles_dental-sidenav__3tIvz.styles_dentist__2CuXU li.styles_nav-item__D3_jW > a {
      background-color: #007B9B
    }

.styles_dental-sidenav__3tIvz.styles_dentist__2CuXU li.styles_nav-item__D3_jW > a:hover {
        background-color: #FFF;
      }

/*
NavBar
================================================================================
*/
.styles_navbar__-rn0H {
  box-shadow: -5px 1px 10px #888;
  z-index: 900 !important;
}
/*
  Layout
  ------------------------------------------------------------
  */
.styles_navbar__-rn0H .styles_navbar__row__3ww9x {
    height: 100%;
  }
.styles_navbar__-rn0H .styles_navbar__col__1Fblw {
    height: 100%
  }
.styles_navbar__-rn0H .styles_navbar__col__1Fblw:first-child {
      text-align: left
    }
@media (max-width: 767px) {

    .styles_navbar__-rn0H .styles_navbar__col__1Fblw:first-child {
        display: none
    }
      }
.styles_navbar__-rn0H .styles_navbar__col__1Fblw:not(:first-child):not(:last-child) {
      text-align: center;
    }
.styles_navbar__-rn0H .styles_navbar__col__1Fblw:last-child {
      text-align: right;
    }
/*
  Brand
  ------------------------------------------------------------
  */
.styles_navbar__-rn0H .styles_navbar__brand__2gEhS {
    display: block;
    height: 100%;

    text-align: center;
  }
.styles_navbar__-rn0H .styles_navbar__brand__link__2Iy7T {
    display: inline-block;
    height: 100%;
  }
/*
  Text & Links
  ------------------------------------------------------------
  */
.styles_navbar__-rn0H .styles_navbar__text__3E12P {
    display: inline;
    position: relative;
    top: calc(50% - 1rem);

    color: #646569;

    font-weight: 600;
    text-transform: uppercase;
  }
.styles_navbar__-rn0H a.styles_navbar__text__3E12P:hover,
    .styles_navbar__-rn0H a.styles_navbar__text__3E12P:active,
    .styles_navbar__-rn0H a.styles_navbar__text__3E12P:focus {
      color: #BDBBBB;
      text-decoration: none;
    }

.styles_header-link__GSMGS {
  padding: 0 1rem;
}

.styles_header-link__GSMGS a:focus,
  .styles_header-link__GSMGS a:hover {
    font-weight: bold;
    text-decoration: none;
  }

.styles_header-link--current__NSVvC {
  font-weight: bold;
}

.styles_rightbar__Ewe_U{
  padding-top:35px;
}

.styles_null_navbar__oXne1{
  height:0;
  min-height:0;
}

.styles_navbar-dropdown__3i4xz {
  left: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.45s ease;
  z-index: 1030;
  background: #282828; }
  .styles_navbar-dropdown__3i4xz .styles_navbar-logo__1aN9b {
    margin-right: 0.8rem;
    transition: margin 0.3s ease-in-out;
    vertical-align: middle; }
    .styles_navbar-dropdown__3i4xz .styles_navbar-logo__1aN9b img {
      height: 3.125rem;
      transition: all 0.3s ease-in-out; }
    .styles_navbar-dropdown__3i4xz .styles_navbar-logo__1aN9b.styles_mbr-iconfont__3DVSB {
      font-size: 3.125rem;
      line-height: 3.125rem; }
  .styles_navbar-dropdown__3i4xz .styles_navbar-caption__1-7yB {
    font-weight: 700;
    white-space: normal;
    vertical-align: -4px;
    line-height: 3.125rem !important; }
    .styles_navbar-dropdown__3i4xz .styles_navbar-caption__1-7yB, .styles_navbar-dropdown__3i4xz .styles_navbar-caption__1-7yB:hover {
      color: inherit;
      text-decoration: none; }
  .styles_navbar-dropdown__3i4xz .styles_mbr-iconfont__3DVSB + .styles_navbar-caption__1-7yB {
    vertical-align: -1px; }
  .styles_navbar-dropdown__3i4xz.styles_navbar-fixed-top__1oaaq {
    position: fixed; }
  .styles_navbar-dropdown__3i4xz .styles_navbar-brand__2YDhz span {
    vertical-align: -4px; }
  .styles_navbar-dropdown__3i4xz.styles_bg-color__3mM1W.styles_transparent__3C271 {
    background: none; }
  .styles_navbar-dropdown__3i4xz.styles_navbar-short__3FDse .styles_navbar-brand__2YDhz {
    padding: 0.625rem 0; }
    .styles_navbar-dropdown__3i4xz.styles_navbar-short__3FDse .styles_navbar-brand__2YDhz span {
      vertical-align: -1px; }
  .styles_navbar-dropdown__3i4xz.styles_navbar-short__3FDse .styles_navbar-caption__1-7yB {
    line-height: 2.375rem !important;
    vertical-align: -2px; }
  .styles_navbar-dropdown__3i4xz.styles_navbar-short__3FDse .styles_navbar-logo__1aN9b {
    margin-right: 0.5rem; }
    .styles_navbar-dropdown__3i4xz.styles_navbar-short__3FDse .styles_navbar-logo__1aN9b img {
      height: 2.375rem; }
    .styles_navbar-dropdown__3i4xz.styles_navbar-short__3FDse .styles_navbar-logo__1aN9b.styles_mbr-iconfont__3DVSB {
      font-size: 2.375rem;
      line-height: 2.375rem; }
  .styles_navbar-dropdown__3i4xz.styles_navbar-short__3FDse .styles_mbr-table-cell__3QaJV {
    height: 3.625rem; }
  .styles_navbar-dropdown__3i4xz .styles_navbar-close__1bhoO {
    left: 0.6875rem;
    position: fixed;
    top: 0.75rem;
    z-index: 1000; }
  .styles_navbar-dropdown__3i4xz .styles_hamburger-icon__1TGdQ {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828; }

.styles_dropdown-menu___q5hx .styles_dropdown-toggle__1VwbW[data-toggle="dropdown-submenu"]::after {
  border-bottom: 0.35em solid transparent;
  border-left: 0.35em solid;
  border-right: 0;
  border-top: 0.35em solid transparent;
  margin-left: 0.3rem; }

.styles_dropdown-menu___q5hx .styles_dropdown-item__2FVBQ:focus {
  outline: 0; }

.styles_nav-dropdown__3BdVE {
  font-size: 0.75rem;
  font-weight: 500;
  height: auto !important; }
  .styles_nav-dropdown__3BdVE .styles_nav-btn__3Vzyn {
    padding-left: 1rem; }
  .styles_nav-dropdown__3BdVE .styles_link__BoX8M {
    margin: .667em 1.667em;
    font-weight: 500;
    padding: 0;
    transition: color .2s ease-in-out; }
    .styles_nav-dropdown__3BdVE .styles_link__BoX8M.styles_dropdown-toggle__1VwbW {
      margin-right: 2.583em; }
      .styles_nav-dropdown__3BdVE .styles_link__BoX8M.styles_dropdown-toggle__1VwbW::after {
        margin-left: .25rem;
        border-top: 0.35em solid;
        border-right: 0.35em solid transparent;
        border-left: 0.35em solid transparent;
        border-bottom: 0; }
      .styles_nav-dropdown__3BdVE .styles_link__BoX8M.styles_dropdown-toggle__1VwbW[aria-expanded="true"] {
        margin: 0;
        padding: 0.667em 3.263em  0.667em 1.667em; }
  .styles_nav-dropdown__3BdVE .styles_link__BoX8M::after,
  .styles_nav-dropdown__3BdVE .styles_dropdown-item__2FVBQ::after {
    color: inherit; }
  .styles_nav-dropdown__3BdVE .styles_btn__3S-FE {
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: 0;
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .styles_nav-dropdown__3BdVE .styles_dropdown-menu___q5hx {
    border-radius: 0;
    border: 0;
    left: 0;
    margin: 0;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    position: relative; }
  .styles_nav-dropdown__3BdVE .styles_dropdown-submenu__1DiGF {
    margin-left: 0.125rem;
    top: 0; }
  .styles_nav-dropdown__3BdVE .styles_dropdown-item__2FVBQ {
    font-weight: 500;
    line-height: 2;
    padding: 0.3846em 4.615em 0.3846em 1.5385em;
    position: relative;
    transition: color .2s ease-in-out, background-color .2s ease-in-out; }
    .styles_nav-dropdown__3BdVE .styles_dropdown-item__2FVBQ::after {
      margin-top: -0.3077em;
      position: absolute;
      right: 1.1538em;
      top: 50%; }
    .styles_nav-dropdown__3BdVE .styles_dropdown-item__2FVBQ:focus, .styles_nav-dropdown__3BdVE .styles_dropdown-item__2FVBQ:hover {
      background: none; }

@media (max-width: 767px) {
  .styles_nav-dropdown__3BdVE.styles_navbar-toggleable-sm__19I_L {
    bottom: 0;
    display: none;
    left: 0;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    width: 18.75rem;
    z-index: 999; } }
.styles_nav-dropdown__3BdVE.styles_navbar-toggleable-xl__zDpgs {
  bottom: 0;
  display: none;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  width: 18.75rem;
  z-index: 999; }

.styles_nav-dropdown-sm__1ndGW {
  display: block !important;
  overflow-x: hidden;
  overflow: auto;
  padding-top: 3.875rem; }
  .styles_nav-dropdown-sm__1ndGW::after {
    content: "";
    display: block;
    height: 3rem;
    width: 100%; }
  .styles_nav-dropdown-sm__1ndGW.styles_collapse__29vqJ.styles_in__3Y3th ~ .styles_navbar-close__1bhoO {
    display: block !important; }
  .styles_nav-dropdown-sm__1ndGW.styles_collapsing__3ppnj, .styles_nav-dropdown-sm__1ndGW.styles_collapse__29vqJ.styles_in__3Y3th {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition: all 0.25s ease-out;
    -webkit-transition: all 0.25s ease-out;
    background: #282828; }
  .styles_nav-dropdown-sm__1ndGW.styles_collapsing__3ppnj[aria-expanded="false"] {
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%); }
  .styles_nav-dropdown-sm__1ndGW .styles_nav-item__D3_jW {
    display: block;
    margin-left: 0 !important;
    padding-left: 0; }
  .styles_nav-dropdown-sm__1ndGW .styles_link__BoX8M,
  .styles_nav-dropdown-sm__1ndGW .styles_dropdown-item__2FVBQ {
    border-top: 1px dotted rgba(255, 255, 255, 0.1);
    font-size: 0.8125rem;
    line-height: 1.6;
    margin: 0 !important;
    padding: 0.875rem 2.4rem 0.875rem 1.5625rem !important;
    position: relative;
    white-space: normal; }
    .styles_nav-dropdown-sm__1ndGW .styles_link__BoX8M:focus, .styles_nav-dropdown-sm__1ndGW .styles_link__BoX8M:hover,
    .styles_nav-dropdown-sm__1ndGW .styles_dropdown-item__2FVBQ:focus,
    .styles_nav-dropdown-sm__1ndGW .styles_dropdown-item__2FVBQ:hover {
      background: rgba(0, 0, 0, 0.2) !important;
      color: #c0a375; }
  .styles_nav-dropdown-sm__1ndGW .styles_nav-btn__3Vzyn {
    position: relative;
    padding: 1.5625rem 1.5625rem 0 1.5625rem; }
    .styles_nav-dropdown-sm__1ndGW .styles_nav-btn__3Vzyn::before {
      border-top: 1px dotted rgba(255, 255, 255, 0.1);
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .styles_nav-dropdown-sm__1ndGW .styles_nav-btn__3Vzyn + .styles_nav-btn__3Vzyn {
      padding-top: 0.625rem; }
      .styles_nav-dropdown-sm__1ndGW .styles_nav-btn__3Vzyn + .styles_nav-btn__3Vzyn::before {
        display: none; }
  .styles_nav-dropdown-sm__1ndGW .styles_btn__3S-FE {
    padding: 0.625rem 0; }
  .styles_nav-dropdown-sm__1ndGW .styles_dropdown-toggle__1VwbW[data-toggle="dropdown-submenu"]::after {
    margin-left: .25rem;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0; }
  .styles_nav-dropdown-sm__1ndGW .styles_dropdown-toggle__1VwbW[data-toggle="dropdown-submenu"][aria-expanded="true"]::after {
    border-top: 0;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0.35em solid; }
  .styles_nav-dropdown-sm__1ndGW .styles_dropdown-menu___q5hx {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
    float: none;
    border-radius: 0;
    background: none; }
  .styles_nav-dropdown-sm__1ndGW .styles_dropdown-submenu__1DiGF {
    left: 100%;
    margin-left: 0.125rem;
    margin-top: -1.25rem;
    top: 0; }

.styles_navbar-toggleable-sm__19I_L .styles_nav-dropdown__3BdVE .styles_dropdown-menu___q5hx {
  position: absolute; }

.styles_navbar-toggleable-sm__19I_L .styles_nav-dropdown__3BdVE .styles_dropdown-submenu__1DiGF {
  left: 100%;
  margin-left: 0.125rem;
  margin-top: -1.25rem;
  top: 0; }

.styles_navbar-toggleable-sm__19I_L.styles_opened__1GTRL .styles_nav-dropdown__3BdVE .styles_dropdown-menu___q5hx {
  position: relative; }

.styles_navbar-toggleable-sm__19I_L.styles_opened__1GTRL .styles_nav-dropdown__3BdVE .styles_dropdown-submenu__1DiGF {
  left: 0;
  margin-left: 00rem;
  margin-top: 0rem;
  top: 0; }

.styles_is-builder__3xsbt .styles_nav-dropdown__3BdVE.styles_collapsing__3ppnj {
  transition: none !important; }

/*# sourceMappingURL=style.css.map */

.styles_sidebar__xIsdV{
  width: 215px;
  position: fixed;
  top: 60px;
  height: 100%;
}


.styles_sidebar-selected__3ZSco{
  color:#474747;
  background-color:rgba(189, 187, 187,1);
}

.styles_hidden_copy__1MZod{
  color:#474747;
  background-color:#474747;
  height:1px;
  z-index:-2;
  position:fixed;
  top:-20px;
}

/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.styles_u-position-absolute__17dDH {
  position: absolute;
}

.styles_u-position-relative__1pE-T {
  position: relative;
}

.accordion {
  color:white;
  background-color:#474747;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  height:100%;
}

.accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin:0 15px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin:0 15px;
}

.accordion__item--has-icon {
  position: relative;
}

.accordion__title {
  color:white;
  background-color:#474747;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
}
.accordion a{
  color:white;
}
.accordion a:hover{
  color:rgba(189, 187, 187, 1);
  text-decoration:none;
}

.accordion__title:hover {
  background-color: #373737;
}

.accordion__body {
  display: block;
  -webkit-animation: styles_fadein__1KqRM 0.35s ease-in;
          animation: styles_fadein__1KqRM 0.35s ease-in;
}

.accordion__body--hidden {
  display: none;
  opacity: 0;
  -webkit-animation: styles_fadein__1KqRM 0.35s ease-in;
          animation: styles_fadein__1KqRM 0.35s ease-in;
}

.accordion__title > *:last-child
.accordion__body > *:last-child {
  margin-bottom: 0;
}

.accordion__arrow {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 12px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -6px;
}

.accordion__arrow::after
.accordion__arrow::before {
  display: block;
  position: absolute;
  top: 50%;
  width: 10px;
  height: 2px;
  background-color: currentColor;
  content: '';
}

.accordion__arrow::before {
  left: 4px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

[aria-expanded='true'] .accordion__arrow::before [aria-selected='true'] .accordion__arrow::before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.accordion__arrow::after {
  right: 4px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

[aria-expanded='true'] .accordion__arrow::after [aria-selected='true'] .accordion__arrow::after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.accordion__arrow::before
.accordion__arrow::after {
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

.accordion ul{
  list-style-type:none;
}

.accordion li{
  padding:10px 0;
  font-size:.8rem;
  margin-left:-10px;
  cursor:pointer;
}



.accordion li a{
  color:#a7a7a7;
  display: block
}



.accordion li a:hover {
    color: #fff;
  }

.accordion li a[aria-selected='true']{
  font-weight:bold;
  color:white;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@-webkit-keyframes styles_fadein__1KqRM {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@keyframes styles_fadein__1KqRM {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@-webkit-keyframes styles_move-down__1B3VP {
  0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  10% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  20% {
      -webkit-transform: translateY(5px);
              transform: translateY(5px);
  }
  30% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}

@keyframes styles_move-down__1B3VP {
  0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  10% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  20% {
      -webkit-transform: translateY(5px);
              transform: translateY(5px);
  }
  30% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}

@-webkit-keyframes styles_move-up__2Lw0u {
  0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  10% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  20% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
  }
  30% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}

@keyframes styles_move-up__2Lw0u {
  0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  10% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  20% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
  }
  30% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}
.accordion__title[aria-selected='true']{
  color:white;
  background-color:rgba(255, 255, 255, 0.2);
}

.accordion__title[aria-selected='true']:hover{
  color:#222;
  outline:0;
}

.accordion__title[aria-selected='true']:focus{
  outline:0;
}

.accordion__title--animated:hover .accordion__arrow {
  -webkit-animation-name: styles_move-down__1B3VP;
          animation-name: styles_move-down__1B3VP;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
}

.accordion__title--animated[aria-expanded='true']:hover .accordion__arrow {
  -webkit-animation-name: styles_move-up__2Lw0u;
          animation-name: styles_move-up__2Lw0u;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
}

.styles_on_dashboard__Tkdcm{
  color:#474747;
  background-color:rgba(189, 187, 187, 1);
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
}

/*
Invite Form Modal Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */


/*
Page Wide
================================================================================
*/




/*
Invite Form Modal
================================================================================
*/

/* TODO */
.custom-button{
    color:orange
}
.styles_large-button--primary__BA5XC {
  border: 2px solid #00a1df;
  border-radius: 1rem;
  padding: 1.1rem 8.0rem;

  color: #FFF;
  background-color: #00a1df;

  font-size: 1.1rem;
  font-weight: 600;
  line-height: normal
}
.styles_large-button--primary__BA5XC:hover {
    color: #00a1df;
    background-color: #FFF;
  }
.styles_large-button--primary__BA5XC:focus {
    outline: none;
  }
.styles_large-button--secondary__3ufm2 {
  border: 1px solid #73C8AF;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;

  color: #FFF;
  background-color: #73C8AF;

  font-weight: 600;
  line-height: normal
}
.styles_large-button--secondary__3ufm2:hover {
    color: #73C8AF;
    background-color: #FFF;
  }
.styles_large-button--secondary__3ufm2:focus {
    outline: none;
  }
.styles_large-button--secondary__3ufm2:disabled{
    background-color:#FFF;
    color: lightgrey;
    border: 1px solid lightgrey;
  }
.styles_jumbotron__2Y8gK {
    padding: 3rem 1rem 1rem;
    margin-bottom: 0;
    background-color: #00a1df;
    color: #fff;
    text-align: center;
    border-radius: 0;
    position: relative;
}
.styles_dark__3yK3M{
    background-color: #00a1df;
    color: #fff;
}
.styles_flex-container__2u8Rs{
  display: flex;
}
.styles_flex-container__2u8Rs div{
  flex-grow:1;
  margin: auto;
}
.styles_flex-center__2z04R{
  justify-content:center;
  text-align:center;
}
.styles_flex-cell3__31sG6{
  width: calc(100% / 3);
}
.styles_flex-cell4__2V3I2{
  width: calc(100% / 4);
}
.styles_flex-cell8__2SnFf{
  width: calc(100% / 8);
}
.styles_field-instructions__3V-Nh {
  margin-bottom: 1.5rem;
  text-align: justify;
  font-weight: 300
}
.styles_field-instructions__3V-Nh:last-child {
    margin-bottom: 2.5rem;
  }
.styles_light-gray__1FR_0{
  color:#979797;
}
.styles_spacer__3Wgul {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
.styles_spacer--after-form-group__IOXKL {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.styles_spacer--members-list__9RL0A {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.styles_hidden-field__9j7GU {
  display: none;
}
.styles_hidden-field__9j7GU + .styles_align-with-input__pJB6- {
  padding-top: 0;
}
.styles_white__2DhgY{
  color:#fafafa
}
.styles_white__2DhgY:hover {
    color: #cacaca;
  }
.styles_white-background__3_Ile{
  background:#fff;
}
.styles_float-right__373eH {
  float: right;
}
.styles_float-left__3XyuP {
  float: left;
}
.styles_modal-section-title__Hfakl {
  margin: 0 0 1rem 0;
  color: #00a1df;
  font-size: 1.5rem;
}
.modal-body {
  padding-bottom: 1.5rem;
}
.styles_dhqblue__2d9hC{
  color:#00a1df;
}
.styles_acceptsSMScheckbox__jkP-l input{
  opacity:.4;
  height: 0.8rem;
}
.styles_acceptsSMS__1H2cL{
  font-size:.8rem;
  color: #999;
}
/*
Footer Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */


/*
Footer
================================================================================
*/
.styles_footer__cVV_0 {
  padding: 1rem 0 2rem 0;

  color: #BDBBBB;
  background-color: #8d8e94;

  font-size: 0.775rem;
  height: 1.7rem;
  width: 100%;
  bottom: 0;
  z-index: 1300;
  position: fixed;
}

.styles_footer-different__37CkA{
     /*position: absolute; */
    padding: 1rem 0 2rem 0;

    color: #BDBBBB;
    background-color: #8d8e94;

    font-size: 0.775rem;
    height: 1.7rem;
    width: 100%;
    bottom: 0;
    z-index: 5000;
    position: fixed
}

@media (max-width: 992px){

.styles_footer-different__37CkA{
      padding-bottom: 2.5rem;
      font-size: 0.6775rem
}
    }

/*
Footer Nav
------------------------------------------------------------
*/
.styles_powered-by__3ja7x {
  margin: 0;
  text-align: center
}
@media (max-width: 992px){
.styles_powered-by__3ja7x {
    padding-bottom: 0.5rem
}
  }

.styles_powered-by--logo__2Kv7P {
  width: 100px;
  margin-top: -3px;
  margin-left: 2px
}

@media (max-width: 992px){

.styles_powered-by--logo__2Kv7P {
    width: 70px
}
  }

ul.styles_footer__nav__3pFaV {
  display: inline-block;
  margin: 0 0 1rem 0;
  padding: 0;

  list-style-type: none;

  text-align: center;
  text-transform: uppercase
}

ul.styles_footer__nav__3pFaV > li {
    display: inline;
    margin: 0 0.625rem
  }

ul.styles_footer__nav__3pFaV > li > a {
      color: #BDBBBB;

      cursor: pointer;
    }

ul.styles_footer__nav__3pFaV > li > a:hover,
    ul.styles_footer__nav__3pFaV > li > a:active,
    ul.styles_footer__nav__3pFaV > li > a:focus {
      color: #FFF;

      text-decoration: none;
    }
.styles_terms__sfpUK{
  text-align:right
}
@media (max-width: 992px){
.styles_terms__sfpUK{
    text-align:center;
    margin-top: -8px
}
  }

.styles_disclaimer__3ytXb {
  width: 90%;
  margin: 0 auto;

  text-align: justify;
}

ul.styles_social-links__241wP {
  top: 2.75rem;

  display: block;
  margin: 0 0 1rem 0;
  padding: 0;

  list-style-type: none;

  font-size: 1.5rem;
  text-align: center
}

ul.styles_social-links__241wP > li {
    display: inline-block;
    margin: 0 0.625rem
  }

ul.styles_social-links__241wP > li > a {
      color: #BDBBBB;

      cursor: pointer;
    }

ul.styles_social-links__241wP > li > a:hover,
    ul.styles_social-links__241wP > li > a:active,
    ul.styles_social-links__241wP > li > a:focus {
      color: #FFF;

      text-decoration: none;
    }

/*
Page Header Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */


/*
Page Header
================================================================================
*/


.styles_page-header__3SRj- {
  color: #FFF;
  /* background: url(https://dentalhq-files.s3.amazonaws.com/marketing_materials/dentist-and-woman.jpg) center / cover no-repeat; */
  background-color: #00a1df;

  text-align: center;
}


.styles_page-header__3SRj- .styles_page-header__overlay__2MrDD {
    display: block;
    margin: 0;
    padding: 2rem 0;

    background-color: rgba(  0, 161, 263, 0.65);

  }


.styles_page-header__3SRj- .styles_large-title__26NCn {
    margin: 0;

    font-size: 3rem;
    text-transform: capitalize
  }


.styles_page-header__3SRj- .styles_large-title__26NCn:not(:only-child) {
      margin-bottom: 3rem;
    }


.styles_page-header__3SRj- form br {
      display: none;
    }


.styles_page-header__3SRj- form input[type=text] {
      margin-right: 10px;
    }


.styles_page-header__3SRj- form input[type=submit] {
      background-color: #73C8AF;
      border-color: #73C8AF
    }


.styles_page-header__3SRj- form input[type=submit]:hover {
        color: #73C8AF;
        background-color: #FFF;
      }

.styles_border-content__xtd-l {
  min-height: 3.5rem; /* for pages without any border content */
  padding: 1rem 0;

  color: #FFF;
  background-color: #646569;

  font-weight: lighter;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
}

.styles_border-content__xtd-l a {
    color: #FFF
  }

.styles_border-content__xtd-l a:focus {
      color: #FFF;
      text-decoration: none;
    }

.styles_border-content__xtd-l a:hover {
      color: #FFF;
      text-decoration: underline;
    }

.styles_border-content--user__1hdc_ {
  text-transform: capitalize;
}

.styles_user-name__GIy1J {
  font-weight: bold;
}

.styles_header-link__1m54Y {
  padding: 0 1rem;
}

.styles_header-link__1m54Y a:focus,
  .styles_header-link__1m54Y a:hover {
    font-weight: bold;
    text-decoration: none;
  }

.styles_header-link--current__1UDFz {
  font-weight: bold;
}

.styles_small-welcome__3PoL_{
  font-size:.9rem;
}

.styles_small-welcome-grey__3wcty{
  font-size:.9rem;
  color:#a7a7a7;
  text-align:center;
  width:40%;
  font-weight:bold;
  margin:0 auto;
}


.styles_other-offices__M960X{
  background-color: white;
    color: #272727;
    border-radius: 4px;
    font-size:.9rem;
}

/*
NavBar Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */
/*
NavBar
================================================================================
*/
.styles_navbar-admin___7wHR {
  box-shadow: -5px 1px 10px #888;
  z-index: 900 !important;
  height: 60px;
  background-color:#474747 !important;
}
/*
  Layout
  ------------------------------------------------------------
  */
.styles_navbar-admin___7wHR .styles_navbar__row__3Adta {
    height: 100%;
  }
.styles_navbar-admin___7wHR .styles_navbar__col__1oU6t {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center
  }
.styles_navbar-admin___7wHR .styles_navbar__col__1oU6t:first-child {
      text-align: center
    }
@media (max-width: 767px) {

    .styles_navbar-admin___7wHR .styles_navbar__col__1oU6t:first-child {
        display: none
    }
      }
.styles_navbar-admin___7wHR .styles_navbar__col__1oU6t:not(:first-child):not(:last-child) {
      text-align: center;
    }
.styles_navbar-admin___7wHR .styles_navbar__col__1oU6t:last-child {
      text-align: right;
    }
/*
  Brand
  ------------------------------------------------------------
  */
.styles_navbar-admin___7wHR .styles_navbar__brand__3W1rE {
    display: block;
    height: 100%;

    text-align: center;
  }
.styles_navbar-admin___7wHR .styles_navbar__brand__link__2kKtx {
    height: 100%;
    text-align: right;
  }
.styles_navbar-admin___7wHR .styles_navbar__brand__img__1cKjn {
    position: relative;
    /* width: 120px; */
    max-width: 120px;
    max-height: 46px;
    /* padding-top: 18px; */
  }
/* :global(.navbar-right li) {
    float: right;
  } */
.styles_navbar-admin___7wHR .styles_location-logo__3765t {
    display: inline-block;
    float:right;
    padding-left: 0.5rem;
  }
/* .location-dropdown {
    display: inline-block;
    padding-top: 13px;

    select {
      font-size: 17px;
      font-weight: 700;
    }
  } */
.styles_navbar-admin___7wHR .styles_location-dropdown__bBZZF {
  float: right;
  margin-top: 0.5rem;
}
.styles_navbar-admin___7wHR .styles_location__3TXKp {
    height: 100%;
  }
.styles_navbar-admin___7wHR .styles_mini_logo__2y6gm{
    height:40px;
    padding-right:10px;
    margin-top: 10px;
  }
.styles_navbar-admin___7wHR .dropdown-menu>li>a {
    padding: 3px 0
  }
.styles_navbar-admin___7wHR .dropdown-menu>li>a:hover {
      background-color: inherit;
    }
.styles_navbar-admin___7wHR .styles_location-logo-bigger__nKbbz {
  width: 100px;
  height: 100px;
  display: inline-block;
}
.styles_navbar-admin___7wHR .styles_location-logo-bigger__nKbbz img {
    width: 80px;
  }
.styles_navbar-admin___7wHR .styles_location-selector__gbv_L {
  text-align: right;
  padding-right: 1em;
}
.styles_navbar-admin___7wHR .styles_location-welcome__2TrJk {
  font-size: 13px;
  margin-right: 14px;
  margin-bottom: -3px;
  color:white;
}
.styles_navbar-admin___7wHR .styles_topdropdown__375sd ul {
  width: 300px;
  border-top: none;
  border-radius: 0;
  margin-top: 15px;
  padding-top: 0;
  text-align: center;
}
/*
  Text & Links
  ------------------------------------------------------------
  */
.styles_navbar-admin___7wHR .styles_navbar__text__7IxzC {
    display: inline;
    position: relative;
    top: calc(50% - 1rem);

    color: #646569;

    font-weight: 600;
    text-transform: uppercase;
  }
.styles_navbar-admin___7wHR a.styles_navbar__text__7IxzC:hover,
    .styles_navbar-admin___7wHR a.styles_navbar__text__7IxzC:active,
    .styles_navbar-admin___7wHR a.styles_navbar__text__7IxzC:focus {
      color: #BDBBBB;
      text-decoration: none;
    }

.styles_header-link__3rrB1 {
  padding: 0 1rem;
}

.styles_header-link__3rrB1 a {
    display: block;
  }

.styles_header-link__3rrB1 a:focus,
  .styles_header-link__3rrB1 a:hover {
    font-weight: normal;
    text-decoration: none;
    background-color: transparent;
  }

.styles_header-link__3rrB1.styles_locations__3c2Zn {
    border-top: 1px solid #ddd;
    padding: 0 0 1em;
    text-align: left;
  }

.styles_header-link__3rrB1.styles_top-link__2vvJl {
      display: inline-block
  }

.styles_header-link__3rrB1.styles_top-link__2vvJl:hover {
      background-color: #f6f6f6;
    }

.styles_header-link__3rrB1.styles_top-link__2vvJl svg {
      margin-right: 0.3em;
    }


.styles_header-link--current__3sGc7 {
  font-weight: bold;
}

.styles_rightbar__mIfiV{
  padding-top:35px;
}

.styles_null_navbar__3U8b3{
  height:0;
  min-height:0;
}

.styles_navbar-dropdown__4a7kk {
  left: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.45s ease;
  z-index: 1030;
  background: #282828; }
  .styles_navbar-dropdown__4a7kk .styles_navbar-logo__X7CdO {
    margin-right: 0.8rem;
    transition: margin 0.3s ease-in-out;
    vertical-align: middle; }
    .styles_navbar-dropdown__4a7kk .styles_navbar-logo__X7CdO img {
      height: 3.125rem;
      transition: all 0.3s ease-in-out; }
    .styles_navbar-dropdown__4a7kk .styles_navbar-logo__X7CdO.styles_mbr-iconfont__2yTj7 {
      font-size: 3.125rem;
      line-height: 3.125rem; }
  .styles_navbar-dropdown__4a7kk .styles_navbar-caption__QK-d7 {
    font-weight: 700;
    white-space: normal;
    vertical-align: -4px;
    line-height: 3.125rem !important; }
    .styles_navbar-dropdown__4a7kk .styles_navbar-caption__QK-d7, .styles_navbar-dropdown__4a7kk .styles_navbar-caption__QK-d7:hover {
      color: inherit;
      text-decoration: none; }
  .styles_navbar-dropdown__4a7kk .styles_mbr-iconfont__2yTj7 + .styles_navbar-caption__QK-d7 {
    vertical-align: -1px; }
  .styles_navbar-dropdown__4a7kk.styles_navbar-fixed-top__3N6-w {
    position: fixed; }
  .styles_navbar-dropdown__4a7kk .styles_navbar-brand__1ky6H span {
    vertical-align: -4px; }
  .styles_navbar-dropdown__4a7kk.styles_bg-color__32u2l.styles_transparent__2soSu {
    background: none; }
  .styles_navbar-dropdown__4a7kk.styles_navbar-short__21Nl3 .styles_navbar-brand__1ky6H {
    padding: 0.625rem 0; }
    .styles_navbar-dropdown__4a7kk.styles_navbar-short__21Nl3 .styles_navbar-brand__1ky6H span {
      vertical-align: -1px; }
  .styles_navbar-dropdown__4a7kk.styles_navbar-short__21Nl3 .styles_navbar-caption__QK-d7 {
    line-height: 2.375rem !important;
    vertical-align: -2px; }
  .styles_navbar-dropdown__4a7kk.styles_navbar-short__21Nl3 .styles_navbar-logo__X7CdO {
    margin-right: 0.5rem; }
    .styles_navbar-dropdown__4a7kk.styles_navbar-short__21Nl3 .styles_navbar-logo__X7CdO img {
      height: 2.375rem; }
    .styles_navbar-dropdown__4a7kk.styles_navbar-short__21Nl3 .styles_navbar-logo__X7CdO.styles_mbr-iconfont__2yTj7 {
      font-size: 2.375rem;
      line-height: 2.375rem; }
  .styles_navbar-dropdown__4a7kk.styles_navbar-short__21Nl3 .styles_mbr-table-cell__1Hx5U {
    height: 3.625rem; }
  .styles_navbar-dropdown__4a7kk .styles_navbar-close__296gS {
    left: 0.6875rem;
    position: fixed;
    top: 0.75rem;
    z-index: 1000; }
  .styles_navbar-dropdown__4a7kk .styles_hamburger-icon__sMD5j {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828; }

.styles_dropdown-menu__3kXhK .styles_dropdown-toggle__2kacf[data-toggle="dropdown-submenu"]::after {
  border-bottom: 0.35em solid transparent;
  border-left: 0.35em solid;
  border-right: 0;
  border-top: 0.35em solid transparent;
  margin-left: 0.3rem; }

.styles_dropdown-menu__3kXhK .styles_dropdown-item__30jvO:focus {
  outline: 0; }

.styles_nav-dropdown__8J96O {
  font-size: 0.75rem;
  font-weight: 500;
  height: auto !important; }
  .styles_nav-dropdown__8J96O .styles_nav-btn__ABKqD {
    padding-left: 1rem; }
  .styles_nav-dropdown__8J96O .styles_link__3hm0I {
    margin: .667em 1.667em;
    font-weight: 500;
    padding: 0;
    transition: color .2s ease-in-out; }
    .styles_nav-dropdown__8J96O .styles_link__3hm0I.styles_dropdown-toggle__2kacf {
      margin-right: 2.583em; }
      .styles_nav-dropdown__8J96O .styles_link__3hm0I.styles_dropdown-toggle__2kacf::after {
        margin-left: .25rem;
        border-top: 0.35em solid;
        border-right: 0.35em solid transparent;
        border-left: 0.35em solid transparent;
        border-bottom: 0; }
      .styles_nav-dropdown__8J96O .styles_link__3hm0I.styles_dropdown-toggle__2kacf[aria-expanded="true"] {
        margin: 0;
        padding: 0.667em 3.263em  0.667em 1.667em; }
  .styles_nav-dropdown__8J96O .styles_link__3hm0I::after,
  .styles_nav-dropdown__8J96O .styles_dropdown-item__30jvO::after {
    color: inherit; }
  .styles_nav-dropdown__8J96O .styles_btn__l4su5 {
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: 0;
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .styles_nav-dropdown__8J96O .styles_dropdown-menu__3kXhK {
    border-radius: 0;
    border: 0;
    left: 0;
    margin: 0;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    position: relative; }
  .styles_nav-dropdown__8J96O .styles_dropdown-submenu__2LgJm {
    margin-left: 0.125rem;
    top: 0; }
  .styles_nav-dropdown__8J96O .styles_dropdown-item__30jvO {
    font-weight: 500;
    line-height: 2;
    padding: 0.3846em 4.615em 0.3846em 1.5385em;
    position: relative;
    transition: color .2s ease-in-out, background-color .2s ease-in-out; }
    .styles_nav-dropdown__8J96O .styles_dropdown-item__30jvO::after {
      margin-top: -0.3077em;
      position: absolute;
      right: 1.1538em;
      top: 50%; }
    .styles_nav-dropdown__8J96O .styles_dropdown-item__30jvO:focus, .styles_nav-dropdown__8J96O .styles_dropdown-item__30jvO:hover {
      background: none; }

@media (max-width: 767px) {
  .styles_nav-dropdown__8J96O.styles_navbar-toggleable-sm__3wS3- {
    bottom: 0;
    display: none;
    left: 0;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    width: 18.75rem;
    z-index: 999; } }
.styles_nav-dropdown__8J96O.styles_navbar-toggleable-xl__2WLrW {
  bottom: 0;
  display: none;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  width: 18.75rem;
  z-index: 999; }

.styles_nav-dropdown-sm__1JpfO {
  display: block !important;
  overflow-x: hidden;
  overflow: auto;
  padding-top: 3.875rem; }
  .styles_nav-dropdown-sm__1JpfO::after {
    content: "";
    display: block;
    height: 3rem;
    width: 100%; }
  .styles_nav-dropdown-sm__1JpfO.styles_collapse__yourb.styles_in__PO242 ~ .styles_navbar-close__296gS {
    display: block !important; }
  .styles_nav-dropdown-sm__1JpfO.styles_collapsing__3zd0F, .styles_nav-dropdown-sm__1JpfO.styles_collapse__yourb.styles_in__PO242 {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition: all 0.25s ease-out;
    -webkit-transition: all 0.25s ease-out;
    background: #282828; }
  .styles_nav-dropdown-sm__1JpfO.styles_collapsing__3zd0F[aria-expanded="false"] {
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%); }
  .styles_nav-dropdown-sm__1JpfO .styles_nav-item__14bGa {
    display: block;
    margin-left: 0 !important;
    padding-left: 0; }
  .styles_nav-dropdown-sm__1JpfO .styles_link__3hm0I,
  .styles_nav-dropdown-sm__1JpfO .styles_dropdown-item__30jvO {
    border-top: 1px dotted rgba(255, 255, 255, 0.1);
    font-size: 0.8125rem;
    line-height: 1.6;
    margin: 0 !important;
    padding: 0.875rem 2.4rem 0.875rem 1.5625rem !important;
    position: relative;
    white-space: normal; }
    .styles_nav-dropdown-sm__1JpfO .styles_link__3hm0I:focus, .styles_nav-dropdown-sm__1JpfO .styles_link__3hm0I:hover,
    .styles_nav-dropdown-sm__1JpfO .styles_dropdown-item__30jvO:focus,
    .styles_nav-dropdown-sm__1JpfO .styles_dropdown-item__30jvO:hover {
      background: rgba(0, 0, 0, 0.2) !important;
      color: #c0a375; }
  .styles_nav-dropdown-sm__1JpfO .styles_nav-btn__ABKqD {
    position: relative;
    padding: 1.5625rem 1.5625rem 0 1.5625rem; }
    .styles_nav-dropdown-sm__1JpfO .styles_nav-btn__ABKqD::before {
      border-top: 1px dotted rgba(255, 255, 255, 0.1);
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .styles_nav-dropdown-sm__1JpfO .styles_nav-btn__ABKqD + .styles_nav-btn__ABKqD {
      padding-top: 0.625rem; }
      .styles_nav-dropdown-sm__1JpfO .styles_nav-btn__ABKqD + .styles_nav-btn__ABKqD::before {
        display: none; }
  .styles_nav-dropdown-sm__1JpfO .styles_btn__l4su5 {
    padding: 0.625rem 0; }
  .styles_nav-dropdown-sm__1JpfO .styles_dropdown-toggle__2kacf[data-toggle="dropdown-submenu"]::after {
    margin-left: .25rem;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0; }
  .styles_nav-dropdown-sm__1JpfO .styles_dropdown-toggle__2kacf[data-toggle="dropdown-submenu"][aria-expanded="true"]::after {
    border-top: 0;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0.35em solid; }
  .styles_nav-dropdown-sm__1JpfO .styles_dropdown-menu__3kXhK {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
    float: none;
    border-radius: 0;
    background: none; }
  .styles_nav-dropdown-sm__1JpfO .styles_dropdown-submenu__2LgJm {
    left: 100%;
    margin-left: 0.125rem;
    margin-top: -1.25rem;
    top: 0; }

.styles_navbar-toggleable-sm__3wS3- .styles_nav-dropdown__8J96O .styles_dropdown-menu__3kXhK {
  position: absolute; }

.styles_navbar-toggleable-sm__3wS3- .styles_nav-dropdown__8J96O .styles_dropdown-submenu__2LgJm {
  left: 100%;
  margin-left: 0.125rem;
  margin-top: -1.25rem;
  top: 0; }

.styles_navbar-toggleable-sm__3wS3-.styles_opened__jUiBY .styles_nav-dropdown__8J96O .styles_dropdown-menu__3kXhK {
  position: relative; }

.styles_navbar-toggleable-sm__3wS3-.styles_opened__jUiBY .styles_nav-dropdown__8J96O .styles_dropdown-submenu__2LgJm {
  left: 0;
  margin-left: 00rem;
  margin-top: 0rem;
  top: 0; }

.styles_is-builder__34LR0 .styles_nav-dropdown__8J96O.styles_collapsing__3zd0F {
  transition: none !important; }

/*# sourceMappingURL=style.css.map */


.navbar-default .container{
  height:60px;
  max-width: 1800px;
  margin-left: 0;
}

.form-group{
  margin-bottom:none !important;
}

.navbar-nav .form-control{
  background-color:white;
  color: #aaa;
  height: 100%;
  padding-left: 20px;
}

.navbar-nav .form-control::-webkit-input-placeholder,
.navbar-nav .form-control::-moz-placeholder,
.navbar-nav .form-control::-ms-input-placeholder{
  color: #aaa;
}

.navbar-nav .input-group{
  height: 100%;
}

.navbar-nav .input-group .form-control{
  border:none;
}


.navbar-nav .input-group .input-group-btn .btn{
  border:none;
}

.styles_search-nav__vhtui{
  color:#979797;
  border-left:1px solid #bbb;
  border-right:1px solid #bbb;
  height: 100%;
}

.styles_likeGmail___BV0h{
  min-height:100px;
  max-height:300px;
  overflow: auto;
}

.styles_likeGmail___BV0h .styles_pulldown-location__3AE36 {
    height: 2rem;
    line-height: 2rem;
    border-bottom: 1px solid #eee;
    padding: 0 1rem
  }

.styles_likeGmail___BV0h .styles_pulldown-location__3AE36:hover {
      background-color: #f6f6f6;
    }

.styles_likeGmail___BV0h .styles_pulldown-location__3AE36.styles_master-dashboard__2PnhG {}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background:#777;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #777;
}

.styles_topdropdown__375sd{
  white-space: pre-wrap;
}

.styles_topdropdown__375sd a{
  color:#778998
}
.styles_topdropdownsecond__1KNvK{
  white-space: pre-wrap;
  font-size:14px;
  font-weight:bold;
  top:-4px;
}
.styles_topdropdownsecond__1KNvK a{
  color:#79bbf5;
}

.navbar-default .navbar-nav>li>a{
  padding-top:8px;
}


.styles_url__u2JSw{
  text-align:left
}


.styles_url__u2JSw:hover {
    background-color: #e8e8e8;
  }

.styles_url-separator__Ks21U{
  color:#d7d7d7;
}
/*
sidemenu
================================================================================
*/
.content_div_inner .accordion{
    /* background-color: #253746 !important; */
    margin-left: 1px;
    height: 100vh;
}
.content_div_inner .accordion .accordion__item{
    color:#fff;
    font-size:17px;
    font-weight:normal;
    background-color: inherit;
}

.styles_on_dashboard__drw3P {
    color: #fff !important;
    background-color: #00a1e1 !important;
    border-radius: 10px;
    width: 100%;
}
.styles_sidebar_admin__3KO53 .accordion__title{
    color: #fff !important;
    background-color: inherit !important;
    margin:0px 0;
}
.styles_sidebar_admin__3KO53 .accordion{
    padding:0px 0;
}
.styles_sidebar_admin__3KO53 .accordion__item + .accordion__item{
    border-top:1px solid #fff;
}
.styles_sidebar_admin__2o7Bg .accordion__title {
    color: #fff !important;
    background-color: transparent !important;
    cursor: pointer;
    padding: 15px 10px !important;
    width: 100%;
    text-align: left;
    border: none;
  }
  .styles_sidebar_admin__2o7Bg .accordion__item + .accordion__item {
    border-top: 1px solid #fff !important;
    margin: 0 15px;
  }
  .styles_on_dashboard__2b27u {
    color: #fff !important;
    background-color: #00a1e1 !important;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
    border-radius: 10px !important;
    margin: 15px 0 !important;
  }


  /* this form here start sms setup page styles */


/* till here */
/*
Account Security Form Modal Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */
.custom-button{
    color:orange
}
.styles_large-button--primary__2EUmm {
  border: 2px solid #00a1df;
  border-radius: 1rem;
  padding: 1.1rem 8.0rem;

  color: #FFF;
  background-color: #00a1df;

  font-size: 1.1rem;
  font-weight: 600;
  line-height: normal
}
.styles_large-button--primary__2EUmm:hover {
    color: #00a1df;
    background-color: #FFF;
  }
.styles_large-button--primary__2EUmm:focus {
    outline: none;
  }
.styles_large-button--secondary__1skII {
  border: 1px solid #73C8AF;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;

  color: #FFF;
  background-color: #73C8AF;

  font-weight: 600;
  line-height: normal
}
.styles_large-button--secondary__1skII:hover {
    color: #73C8AF;
    background-color: #FFF;
  }
.styles_large-button--secondary__1skII:focus {
    outline: none;
  }
.styles_large-button--secondary__1skII:disabled{
    background-color:#FFF;
    color: lightgrey;
    border: 1px solid lightgrey;
  }
.styles_jumbotron__24wGU {
    padding: 3rem 1rem 1rem;
    margin-bottom: 0;
    background-color: #00a1df;
    color: #fff;
    text-align: center;
    border-radius: 0;
    position: relative;
}
.styles_dark__3GgZc{
    background-color: #00a1df;
    color: #fff;
}
.styles_flex-container__3CX93{
  display: flex;
}
.styles_flex-container__3CX93 div{
  flex-grow:1;
  margin: auto;
}
.styles_flex-center__2BOEo{
  justify-content:center;
  text-align:center;
}
.styles_flex-cell3__2Dvya{
  width: calc(100% / 3);
}
.styles_flex-cell4__3fQ2b{
  width: calc(100% / 4);
}
.styles_flex-cell8__3HJOp{
  width: calc(100% / 8);
}
.styles_field-instructions__3xS8t {
  margin-bottom: 1.5rem;
  text-align: justify;
  font-weight: 300
}
.styles_field-instructions__3xS8t:last-child {
    margin-bottom: 2.5rem;
  }
.styles_light-gray__2upmo{
  color:#979797;
}
.styles_spacer__1tHRM {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
.styles_spacer--after-form-group__dINFx {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.styles_spacer--members-list__3tw6g {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.styles_hidden-field___KxYK {
  display: none;
}
.styles_hidden-field___KxYK + .styles_align-with-input__1ZCet {
  padding-top: 0;
}
.styles_white__2WT4f{
  color:#fafafa
}
.styles_white__2WT4f:hover {
    color: #cacaca;
  }
.styles_white-background__3qs0f{
  background:#fff;
}
.styles_float-right__EXZS4 {
  float: right;
}
.styles_float-left__37Vcp {
  float: left;
}
.styles_modal-section-title__2tkFX {
  margin: 0 0 1rem 0;
  color: #00a1df;
  font-size: 1.5rem;
}
.modal-body {
  padding-bottom: 1.5rem;
}
.styles_dhqblue__8dc4x{
  color:#00a1df;
}
.styles_acceptsSMScheckbox__erd-I input{
  opacity:.4;
  height: 0.8rem;
}
.styles_acceptsSMS__cJ35g{
  font-size:.8rem;
  color: #999;
}

/*
Page Wide
================================================================================
*/



#styles_paymentplans__1aft6 #styles_header__330Vz {
  padding-top: 20px;
}

#styles_paymentplans__1aft6 .styles_button-create__2k32B{
  padding: 20px 0;
}
.styles_intro__rZuUw{}

.styles_buttons__3t8Qu {}

.styles_explanation__1A3a6 {
  margin-top: 1rem;
}

.styles_explanation__1A3a6  {
    color: #767676;
    background: #fff;
}

.styles_explanation__1A3a6 ol {
    margin-bottom: 0;
    counter-reset: myCounter;
}

.styles_explanation__1A3a6 ol li {
    list-style: none;
    padding-left: .5rem;
    margin-bottom: 1rem;
  }

.styles_explanation__1A3a6 ol li:before {
    position: absolute;
    left: 0px;
    margin-top: -10px;
    counter-increment: myCounter;
    content: counter(myCounter);
    display: inline-block;
    text-align: center;
    margin: 5px 10px;
    line-height: 40px;
    transition: all .2s;
    color: #ffffff;
    background: #149dcc;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.styles_stats-button__2tTmv{
  margin:1rem 0;
}
.styles_metrics__2Q2Fv {
  color: #646569;
}

.styles_metrics__value__WOhBL {
  color: #00a1df;

  font-size: 1.125rem;
  font-weight: bold;
}

.styles_metrics__entry__199ig {
  margin-bottom: 0.5rem;
}


/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/




/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/

/*
Colors
------------------------------------------------------------
*/   /* rgb(  0, 161, 263) */ /* rgb(115, 200, 175) */   /* darker VHQblue */    /* rgb(100, 101, 105) */  /* rgb(189, 187, 187) */    /* rgb(100, 101, 105) */


/*
Fonts
------------------------------------------------------------
*/

/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */

/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */

.dental-sidenav .nav > li > a.active {
  background-color: #FFF !important;
  color: #141414;
}

li.styles_nav-item__2XUo4 {
  margin-bottom: 5px
}

li.styles_nav-item__2XUo4 > a {
    color: #FFF
  }

li.styles_nav-item__2XUo4 > a:hover {
      color: #141414;
    }

.styles_dental-sidenav__3fMaQ {
  padding: 1em 1.5em
}

.styles_dental-sidenav__3fMaQ.styles_client__39-dC {
    background-color: #15BF71;
  }

.styles_dental-sidenav__3fMaQ.styles_client__39-dC li.styles_nav-item__2XUo4 > a {
      background-color: #0E7847;
      white-space: nowrap
    }

.styles_dental-sidenav__3fMaQ.styles_client__39-dC li.styles_nav-item__2XUo4 > a:hover {
        background-color: #FFF;
      }

.styles_dental-sidenav__3fMaQ.styles_dentist__2OSn3 {
    background-color: #00C0EF;
  }

.styles_dental-sidenav__3fMaQ.styles_dentist__2OSn3 li.styles_nav-item__2XUo4 > a {
      background-color: #007B9B
    }

.styles_dental-sidenav__3fMaQ.styles_dentist__2OSn3 li.styles_nav-item__2XUo4 > a:hover {
        background-color: #FFF;
      }

/*
NavBar
================================================================================
*/
.styles_navbar__2VbhK {
  box-shadow: -5px 1px 10px #888;
  z-index: 900 !important;
}
/*
  Layout
  ------------------------------------------------------------
  */
.styles_navbar__2VbhK .styles_navbar__row__1wk_c {
    height: 100%;
  }
.styles_navbar__2VbhK .styles_navbar__col__1f4Ma {
    height: 100%
  }
.styles_navbar__2VbhK .styles_navbar__col__1f4Ma:first-child {
      text-align: left
    }
@media (max-width: 767px) {

    .styles_navbar__2VbhK .styles_navbar__col__1f4Ma:first-child {
        display: none
    }
      }
.styles_navbar__2VbhK .styles_navbar__col__1f4Ma:not(:first-child):not(:last-child) {
      text-align: center;
    }
.styles_navbar__2VbhK .styles_navbar__col__1f4Ma:last-child {
      text-align: right;
    }
/*
  Brand
  ------------------------------------------------------------
  */
.styles_navbar__2VbhK .styles_navbar__brand__1ItlE {
    display: block;
    height: 100%;

    text-align: center;
  }
.styles_navbar__2VbhK .styles_navbar__brand__link__3_42U {
    display: inline-block;
    height: 100%;
  }
/*
  Text & Links
  ------------------------------------------------------------
  */
.styles_navbar__2VbhK .styles_navbar__text__3CfCT {
    display: inline;
    position: relative;
    top: calc(50% - 1rem);

    color: #646569;

    font-weight: 600;
    text-transform: uppercase;
  }
.styles_navbar__2VbhK a.styles_navbar__text__3CfCT:hover,
    .styles_navbar__2VbhK a.styles_navbar__text__3CfCT:active,
    .styles_navbar__2VbhK a.styles_navbar__text__3CfCT:focus {
      color: #BDBBBB;
      text-decoration: none;
    }

.styles_header-link__ecC1x {
  padding: 0 1rem;
}

.styles_header-link__ecC1x a:focus,
  .styles_header-link__ecC1x a:hover {
    font-weight: bold;
    text-decoration: none;
  }

.styles_header-link--current__2ordK {
  font-weight: bold;
}

.styles_rightbar__2k8r1{
  padding-top:35px;
}

.styles_null_navbar__30e22{
  height:0;
  min-height:0;
}

.styles_navbar-dropdown__32D3Q {
  left: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.45s ease;
  z-index: 1030;
  background: #282828; }
  .styles_navbar-dropdown__32D3Q .styles_navbar-logo__1udw2 {
    margin-right: 0.8rem;
    transition: margin 0.3s ease-in-out;
    vertical-align: middle; }
    .styles_navbar-dropdown__32D3Q .styles_navbar-logo__1udw2 img {
      height: 3.125rem;
      transition: all 0.3s ease-in-out; }
    .styles_navbar-dropdown__32D3Q .styles_navbar-logo__1udw2.styles_mbr-iconfont__3Mzww {
      font-size: 3.125rem;
      line-height: 3.125rem; }
  .styles_navbar-dropdown__32D3Q .styles_navbar-caption__1hBnw {
    font-weight: 700;
    white-space: normal;
    vertical-align: -4px;
    line-height: 3.125rem !important; }
    .styles_navbar-dropdown__32D3Q .styles_navbar-caption__1hBnw, .styles_navbar-dropdown__32D3Q .styles_navbar-caption__1hBnw:hover {
      color: inherit;
      text-decoration: none; }
  .styles_navbar-dropdown__32D3Q .styles_mbr-iconfont__3Mzww + .styles_navbar-caption__1hBnw {
    vertical-align: -1px; }
  .styles_navbar-dropdown__32D3Q.styles_navbar-fixed-top__3QUSo {
    position: fixed; }
  .styles_navbar-dropdown__32D3Q .styles_navbar-brand__1hgGS span {
    vertical-align: -4px; }
  .styles_navbar-dropdown__32D3Q.styles_bg-color__1lmZ2.styles_transparent__3Ln4F {
    background: none; }
  .styles_navbar-dropdown__32D3Q.styles_navbar-short__2wXCe .styles_navbar-brand__1hgGS {
    padding: 0.625rem 0; }
    .styles_navbar-dropdown__32D3Q.styles_navbar-short__2wXCe .styles_navbar-brand__1hgGS span {
      vertical-align: -1px; }
  .styles_navbar-dropdown__32D3Q.styles_navbar-short__2wXCe .styles_navbar-caption__1hBnw {
    line-height: 2.375rem !important;
    vertical-align: -2px; }
  .styles_navbar-dropdown__32D3Q.styles_navbar-short__2wXCe .styles_navbar-logo__1udw2 {
    margin-right: 0.5rem; }
    .styles_navbar-dropdown__32D3Q.styles_navbar-short__2wXCe .styles_navbar-logo__1udw2 img {
      height: 2.375rem; }
    .styles_navbar-dropdown__32D3Q.styles_navbar-short__2wXCe .styles_navbar-logo__1udw2.styles_mbr-iconfont__3Mzww {
      font-size: 2.375rem;
      line-height: 2.375rem; }
  .styles_navbar-dropdown__32D3Q.styles_navbar-short__2wXCe .styles_mbr-table-cell__fdJG2 {
    height: 3.625rem; }
  .styles_navbar-dropdown__32D3Q .styles_navbar-close__2qu7w {
    left: 0.6875rem;
    position: fixed;
    top: 0.75rem;
    z-index: 1000; }
  .styles_navbar-dropdown__32D3Q .styles_hamburger-icon__2OaWz {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828; }

.styles_dropdown-menu__1VoR6 .styles_dropdown-toggle__3lXSt[data-toggle="dropdown-submenu"]::after {
  border-bottom: 0.35em solid transparent;
  border-left: 0.35em solid;
  border-right: 0;
  border-top: 0.35em solid transparent;
  margin-left: 0.3rem; }

.styles_dropdown-menu__1VoR6 .styles_dropdown-item__1zt3j:focus {
  outline: 0; }

.styles_nav-dropdown__2DsVN {
  font-size: 0.75rem;
  font-weight: 500;
  height: auto !important; }
  .styles_nav-dropdown__2DsVN .styles_nav-btn__2y6_8 {
    padding-left: 1rem; }
  .styles_nav-dropdown__2DsVN .styles_link__2iy6J {
    margin: .667em 1.667em;
    font-weight: 500;
    padding: 0;
    transition: color .2s ease-in-out; }
    .styles_nav-dropdown__2DsVN .styles_link__2iy6J.styles_dropdown-toggle__3lXSt {
      margin-right: 2.583em; }
      .styles_nav-dropdown__2DsVN .styles_link__2iy6J.styles_dropdown-toggle__3lXSt::after {
        margin-left: .25rem;
        border-top: 0.35em solid;
        border-right: 0.35em solid transparent;
        border-left: 0.35em solid transparent;
        border-bottom: 0; }
      .styles_nav-dropdown__2DsVN .styles_link__2iy6J.styles_dropdown-toggle__3lXSt[aria-expanded="true"] {
        margin: 0;
        padding: 0.667em 3.263em  0.667em 1.667em; }
  .styles_nav-dropdown__2DsVN .styles_link__2iy6J::after,
  .styles_nav-dropdown__2DsVN .styles_dropdown-item__1zt3j::after {
    color: inherit; }
  .styles_nav-dropdown__2DsVN .styles_btn__2JuLr {
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: 0;
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .styles_nav-dropdown__2DsVN .styles_dropdown-menu__1VoR6 {
    border-radius: 0;
    border: 0;
    left: 0;
    margin: 0;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    position: relative; }
  .styles_nav-dropdown__2DsVN .styles_dropdown-submenu__3VSJZ {
    margin-left: 0.125rem;
    top: 0; }
  .styles_nav-dropdown__2DsVN .styles_dropdown-item__1zt3j {
    font-weight: 500;
    line-height: 2;
    padding: 0.3846em 4.615em 0.3846em 1.5385em;
    position: relative;
    transition: color .2s ease-in-out, background-color .2s ease-in-out; }
    .styles_nav-dropdown__2DsVN .styles_dropdown-item__1zt3j::after {
      margin-top: -0.3077em;
      position: absolute;
      right: 1.1538em;
      top: 50%; }
    .styles_nav-dropdown__2DsVN .styles_dropdown-item__1zt3j:focus, .styles_nav-dropdown__2DsVN .styles_dropdown-item__1zt3j:hover {
      background: none; }

@media (max-width: 767px) {
  .styles_nav-dropdown__2DsVN.styles_navbar-toggleable-sm__2t3Fw {
    bottom: 0;
    display: none;
    left: 0;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    width: 18.75rem;
    z-index: 999; } }
.styles_nav-dropdown__2DsVN.styles_navbar-toggleable-xl__ZvscZ {
  bottom: 0;
  display: none;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  width: 18.75rem;
  z-index: 999; }

.styles_nav-dropdown-sm__oCd5K {
  display: block !important;
  overflow-x: hidden;
  overflow: auto;
  padding-top: 3.875rem; }
  .styles_nav-dropdown-sm__oCd5K::after {
    content: "";
    display: block;
    height: 3rem;
    width: 100%; }
  .styles_nav-dropdown-sm__oCd5K.styles_collapse__1wAof.styles_in__19Oue ~ .styles_navbar-close__2qu7w {
    display: block !important; }
  .styles_nav-dropdown-sm__oCd5K.styles_collapsing__3wE0M, .styles_nav-dropdown-sm__oCd5K.styles_collapse__1wAof.styles_in__19Oue {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition: all 0.25s ease-out;
    -webkit-transition: all 0.25s ease-out;
    background: #282828; }
  .styles_nav-dropdown-sm__oCd5K.styles_collapsing__3wE0M[aria-expanded="false"] {
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%); }
  .styles_nav-dropdown-sm__oCd5K .styles_nav-item__2XUo4 {
    display: block;
    margin-left: 0 !important;
    padding-left: 0; }
  .styles_nav-dropdown-sm__oCd5K .styles_link__2iy6J,
  .styles_nav-dropdown-sm__oCd5K .styles_dropdown-item__1zt3j {
    border-top: 1px dotted rgba(255, 255, 255, 0.1);
    font-size: 0.8125rem;
    line-height: 1.6;
    margin: 0 !important;
    padding: 0.875rem 2.4rem 0.875rem 1.5625rem !important;
    position: relative;
    white-space: normal; }
    .styles_nav-dropdown-sm__oCd5K .styles_link__2iy6J:focus, .styles_nav-dropdown-sm__oCd5K .styles_link__2iy6J:hover,
    .styles_nav-dropdown-sm__oCd5K .styles_dropdown-item__1zt3j:focus,
    .styles_nav-dropdown-sm__oCd5K .styles_dropdown-item__1zt3j:hover {
      background: rgba(0, 0, 0, 0.2) !important;
      color: #c0a375; }
  .styles_nav-dropdown-sm__oCd5K .styles_nav-btn__2y6_8 {
    position: relative;
    padding: 1.5625rem 1.5625rem 0 1.5625rem; }
    .styles_nav-dropdown-sm__oCd5K .styles_nav-btn__2y6_8::before {
      border-top: 1px dotted rgba(255, 255, 255, 0.1);
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .styles_nav-dropdown-sm__oCd5K .styles_nav-btn__2y6_8 + .styles_nav-btn__2y6_8 {
      padding-top: 0.625rem; }
      .styles_nav-dropdown-sm__oCd5K .styles_nav-btn__2y6_8 + .styles_nav-btn__2y6_8::before {
        display: none; }
  .styles_nav-dropdown-sm__oCd5K .styles_btn__2JuLr {
    padding: 0.625rem 0; }
  .styles_nav-dropdown-sm__oCd5K .styles_dropdown-toggle__3lXSt[data-toggle="dropdown-submenu"]::after {
    margin-left: .25rem;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0; }
  .styles_nav-dropdown-sm__oCd5K .styles_dropdown-toggle__3lXSt[data-toggle="dropdown-submenu"][aria-expanded="true"]::after {
    border-top: 0;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0.35em solid; }
  .styles_nav-dropdown-sm__oCd5K .styles_dropdown-menu__1VoR6 {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
    float: none;
    border-radius: 0;
    background: none; }
  .styles_nav-dropdown-sm__oCd5K .styles_dropdown-submenu__3VSJZ {
    left: 100%;
    margin-left: 0.125rem;
    margin-top: -1.25rem;
    top: 0; }

.styles_navbar-toggleable-sm__2t3Fw .styles_nav-dropdown__2DsVN .styles_dropdown-menu__1VoR6 {
  position: absolute; }

.styles_navbar-toggleable-sm__2t3Fw .styles_nav-dropdown__2DsVN .styles_dropdown-submenu__3VSJZ {
  left: 100%;
  margin-left: 0.125rem;
  margin-top: -1.25rem;
  top: 0; }

.styles_navbar-toggleable-sm__2t3Fw.styles_opened__2FKBK .styles_nav-dropdown__2DsVN .styles_dropdown-menu__1VoR6 {
  position: relative; }

.styles_navbar-toggleable-sm__2t3Fw.styles_opened__2FKBK .styles_nav-dropdown__2DsVN .styles_dropdown-submenu__3VSJZ {
  left: 0;
  margin-left: 00rem;
  margin-top: 0rem;
  top: 0; }

.styles_is-builder__3MUhF .styles_nav-dropdown__2DsVN.styles_collapsing__3wE0M {
  transition: none !important; }

/*# sourceMappingURL=style.css.map */

.styles_sidebar_admin__3II9K{
  width: 215px;
  position: fixed;
  top: 60px;
  height: 100%;
  
}

.styles_sidebar_admin__3II9K .accordion {
    color:#474747;
    background-color:white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    height:100%;
  }

.styles_sidebar_admin__3II9K .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin:0 15px;
  }

.styles_sidebar_admin__3II9K .accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin:0 15px;
  }

.styles_sidebar_admin__3II9K .accordion__item--has-icon {
    position: relative;
  }

.styles_sidebar_admin__3II9K .accordion__title {
    color:#474747;
    background-color:white;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
  }

.styles_sidebar_admin__3II9K .accordion a{
    color:#474747;
  }

.styles_sidebar_admin__3II9K .accordion a:hover{
    color:#373737;
    text-decoration:none;
  }

.styles_sidebar_admin__3II9K .accordion__title:hover {
    background-color: rgba(189, 187, 187, 1);
  }

.styles_sidebar_admin__3II9K .accordion__body {
    display: block;
    -webkit-animation: styles_fadein__2t6WE 0.35s ease-in;
            animation: styles_fadein__2t6WE 0.35s ease-in;
  }

.styles_sidebar_admin__3II9K .accordion__body--hidden {
    display: none;
    opacity: 0;
    -webkit-animation: styles_fadein__2t6WE 0.35s ease-in;
            animation: styles_fadein__2t6WE 0.35s ease-in;
  }

.styles_sidebar_admin__3II9K .accordion__title > *:last-child
  .accordion__body > *:last-child {
    margin-bottom: 0;
  }

.styles_sidebar_admin__3II9K .accordion__arrow {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -6px;
  }

.styles_sidebar_admin__3II9K .accordion__arrow::after
  .accordion__arrow::before {
    display: block;
    position: absolute;
    top: 50%;
    width: 10px;
    height: 2px;
    background-color: currentColor;
    content: '';
  }

.styles_sidebar_admin__3II9K .accordion__arrow::before {
    left: 4px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }

.styles_sidebar_admin__3II9K [aria-expanded='true'] .accordion__arrow::before [aria-selected='true'] .accordion__arrow::before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }

.styles_sidebar_admin__3II9K .accordion__arrow::after {
    right: 4px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }

.styles_sidebar_admin__3II9K [aria-expanded='true'] .accordion__arrow::after [aria-selected='true'] .accordion__arrow::after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }

.styles_sidebar_admin__3II9K .accordion__arrow::before
  .accordion__arrow::after {
    transition: transform 0.25s ease, -webkit-transform 0.25s ease;
  }

.styles_sidebar_admin__3II9K .accordion ul{
    list-style-type:none;
  }

.styles_sidebar_admin__3II9K .accordion li{
    padding:10px 0;
    font-size:.8rem;
    margin-left:-10px;
    cursor:pointer;
  }

.styles_sidebar_admin__3II9K .accordion li a{
    color:#a7a7a7;
    display: block
  }

.styles_sidebar_admin__3II9K .accordion li a:hover {
      color: #fff;
    }

.styles_sidebar_admin__3II9K .accordion li a[aria-selected='true']{
    font-weight:bold;
    color:white;
  }

.styles_sidebar_admin__3II9K .accordion__title[aria-selected='true']{
    color:#474747;
    background-color:rgba(255, 255, 255, 0.2);
  }

.styles_sidebar_admin__3II9K .accordion__title[aria-selected='true']:hover{
    color:#222;
    outline:0;
  }

.styles_sidebar_admin__3II9K .accordion__title[aria-selected='true']:focus{
    outline:0;
  }

.styles_sidebar_admin__3II9K .accordion__title--animated:hover .accordion__arrow {
    -webkit-animation-name: styles_move-down__20nLi;
            animation-name: styles_move-down__20nLi;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
  }

.styles_sidebar_admin__3II9K .accordion__title--animated[aria-expanded='true']:hover .accordion__arrow {
    -webkit-animation-name: styles_move-up__2lBNA;
            animation-name: styles_move-up__2lBNA;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
  }




.styles_sidebar-selected__2mG37{
  color:#474747;
  background-color:rgba(189, 187, 187,1);
}

.styles_hidden_copy__2i-i9{
  color:#474747;
  background-color:#474747;
  height:1px;
  z-index:-2;
  position:fixed;
  top:-20px;
}

/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.styles_u-position-absolute__2_sNE {
  position: absolute;
}

.styles_u-position-relative__2NaW1 {
  position: relative;
}
/* 
:global(.accordion) {
  color:#474747;
  background-color:white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  height:100%;
}

:global(.accordion__item) {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin:0 15px;
}

:global(.accordion__item + .accordion__item) {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin:0 15px;
}

:global(.accordion__item--has-icon) {
  position: relative;
}

:global(.accordion__title) {
  color:#474747;
  background-color:white;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
}
:global(.accordion a){
  color:#474747;
}
:global(.accordion a:hover){
  color:#373737;
  text-decoration:none;
}

:global(.accordion__title:hover) {
  background-color: rgba(189, 187, 187, 1);
}

:global(.accordion__body) {
  display: block;
  animation: fadein 0.35s ease-in;
}

:global(.accordion__body--hidden) {
  display: none;
  opacity: 0;
  animation: fadein 0.35s ease-in;
}

:global(.accordion__title > *:last-child,
.accordion__body > *:last-child) {
  margin-bottom: 0;
}

:global(.accordion__arrow) {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 12px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -6px;
}

:global(.accordion__arrow::after,
.accordion__arrow::before) {
  display: block;
  position: absolute;
  top: 50%;
  width: 10px;
  height: 2px;
  background-color: currentColor;
  content: '';
}

:global(.accordion__arrow::before) {
  left: 4px;
  transform: rotate(45deg);
}

:global([aria-expanded='true'] .accordion__arrow::before, [aria-selected='true'] .accordion__arrow::before) {
  transform: rotate(-45deg);
}

:global(.accordion__arrow::after) {
  right: 4px;
  transform: rotate(-45deg);
}

:global([aria-expanded='true'] .accordion__arrow::after, [aria-selected='true'] .accordion__arrow::after) {
  transform: rotate(45deg);
}

:global(.accordion__arrow::before,
.accordion__arrow::after) {
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

:global(.accordion ul){
  list-style-type:none;
}

:global(.accordion li){
  padding:10px 0;
  font-size:.8rem;
  margin-left:-10px;
  cursor:pointer;
}



:global(.accordion li a){
  color:#a7a7a7;
  display: block;

  &:hover {
    color: #fff;
  }
}

:global(.accordion li a[aria-selected='true']){
  font-weight:bold;
  color:white;
} */

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@-webkit-keyframes styles_fadein__2t6WE {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@keyframes styles_fadein__2t6WE {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@-webkit-keyframes styles_move-down__20nLi {
  0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  10% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  20% {
      -webkit-transform: translateY(5px);
              transform: translateY(5px);
  }
  30% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}

@keyframes styles_move-down__20nLi {
  0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  10% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  20% {
      -webkit-transform: translateY(5px);
              transform: translateY(5px);
  }
  30% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}

@-webkit-keyframes styles_move-up__2lBNA {
  0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  10% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  20% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
  }
  30% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}

@keyframes styles_move-up__2lBNA {
  0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  10% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  20% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
  }
  30% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}

.styles_on_dashboard__2k_UV{
  color:#474747;
  background-color:rgba(189, 187, 187, 1);
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
}

/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/




/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/

/*
Colors
------------------------------------------------------------
*/   /* rgb(  0, 161, 263) */ /* rgb(115, 200, 175) */   /* darker VHQblue */    /* rgb(100, 101, 105) */  /* rgb(189, 187, 187) */    /* rgb(100, 101, 105) */


/*
Fonts
------------------------------------------------------------
*/

/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */

/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */

.dental-sidenav .nav > li > a.active {
  background-color: #FFF !important;
  color: #141414;
}

li.styles_nav-item__3m9JJ {
  margin-bottom: 5px
}

li.styles_nav-item__3m9JJ > a {
    color: #FFF
  }

li.styles_nav-item__3m9JJ > a:hover {
      color: #141414;
    }

.styles_dental-sidenav__37NvE {
  padding: 1em 1.5em
}

.styles_dental-sidenav__37NvE.styles_client__3nIjZ {
    background-color: #15BF71;
  }

.styles_dental-sidenav__37NvE.styles_client__3nIjZ li.styles_nav-item__3m9JJ > a {
      background-color: #0E7847;
      white-space: nowrap
    }

.styles_dental-sidenav__37NvE.styles_client__3nIjZ li.styles_nav-item__3m9JJ > a:hover {
        background-color: #FFF;
      }

.styles_dental-sidenav__37NvE.styles_dentist__2OTB6 {
    background-color: #00C0EF;
  }

.styles_dental-sidenav__37NvE.styles_dentist__2OTB6 li.styles_nav-item__3m9JJ > a {
      background-color: #007B9B
    }

.styles_dental-sidenav__37NvE.styles_dentist__2OTB6 li.styles_nav-item__3m9JJ > a:hover {
        background-color: #FFF;
      }

/*
NavBar
================================================================================
*/
.styles_navbar__3-UiB {
  box-shadow: -5px 1px 10px #888;
  z-index: 900 !important;
}
/*
  Layout
  ------------------------------------------------------------
  */
.styles_navbar__3-UiB .styles_navbar__row__355CJ {
    height: 100%;
  }
.styles_navbar__3-UiB .styles_navbar__col__-jU3L {
    height: 100%
  }
.styles_navbar__3-UiB .styles_navbar__col__-jU3L:first-child {
      text-align: left
    }
@media (max-width: 767px) {

    .styles_navbar__3-UiB .styles_navbar__col__-jU3L:first-child {
        display: none
    }
      }
.styles_navbar__3-UiB .styles_navbar__col__-jU3L:not(:first-child):not(:last-child) {
      text-align: center;
    }
.styles_navbar__3-UiB .styles_navbar__col__-jU3L:last-child {
      text-align: right;
    }
/*
  Brand
  ------------------------------------------------------------
  */
.styles_navbar__3-UiB .styles_navbar__brand__1_vQ- {
    display: block;
    height: 100%;

    text-align: center;
  }
.styles_navbar__3-UiB .styles_navbar__brand__link__wcxhj {
    display: inline-block;
    height: 100%;
  }
/*
  Text & Links
  ------------------------------------------------------------
  */
.styles_navbar__3-UiB .styles_navbar__text__2xDLm {
    display: inline;
    position: relative;
    top: calc(50% - 1rem);

    color: #646569;

    font-weight: 600;
    text-transform: uppercase;
  }
.styles_navbar__3-UiB a.styles_navbar__text__2xDLm:hover,
    .styles_navbar__3-UiB a.styles_navbar__text__2xDLm:active,
    .styles_navbar__3-UiB a.styles_navbar__text__2xDLm:focus {
      color: #BDBBBB;
      text-decoration: none;
    }

.styles_header-link__1ghFF {
  padding: 0 1rem;
}

.styles_header-link__1ghFF a:focus,
  .styles_header-link__1ghFF a:hover {
    font-weight: bold;
    text-decoration: none;
  }

.styles_header-link--current__238mb {
  font-weight: bold;
}

.styles_rightbar__11PvR{
  padding-top:35px;
}

.styles_null_navbar__1TxCa{
  height:0;
  min-height:0;
}

.styles_navbar-dropdown__384L- {
  left: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.45s ease;
  z-index: 1030;
  background: #282828; }
  .styles_navbar-dropdown__384L- .styles_navbar-logo__CA1w6 {
    margin-right: 0.8rem;
    transition: margin 0.3s ease-in-out;
    vertical-align: middle; }
    .styles_navbar-dropdown__384L- .styles_navbar-logo__CA1w6 img {
      height: 3.125rem;
      transition: all 0.3s ease-in-out; }
    .styles_navbar-dropdown__384L- .styles_navbar-logo__CA1w6.styles_mbr-iconfont__1Kv0x {
      font-size: 3.125rem;
      line-height: 3.125rem; }
  .styles_navbar-dropdown__384L- .styles_navbar-caption__113qK {
    font-weight: 700;
    white-space: normal;
    vertical-align: -4px;
    line-height: 3.125rem !important; }
    .styles_navbar-dropdown__384L- .styles_navbar-caption__113qK, .styles_navbar-dropdown__384L- .styles_navbar-caption__113qK:hover {
      color: inherit;
      text-decoration: none; }
  .styles_navbar-dropdown__384L- .styles_mbr-iconfont__1Kv0x + .styles_navbar-caption__113qK {
    vertical-align: -1px; }
  .styles_navbar-dropdown__384L-.styles_navbar-fixed-top__3xns9 {
    position: fixed; }
  .styles_navbar-dropdown__384L- .styles_navbar-brand__2-42k span {
    vertical-align: -4px; }
  .styles_navbar-dropdown__384L-.styles_bg-color__7-FXT.styles_transparent__3XIpf {
    background: none; }
  .styles_navbar-dropdown__384L-.styles_navbar-short__3Aa62 .styles_navbar-brand__2-42k {
    padding: 0.625rem 0; }
    .styles_navbar-dropdown__384L-.styles_navbar-short__3Aa62 .styles_navbar-brand__2-42k span {
      vertical-align: -1px; }
  .styles_navbar-dropdown__384L-.styles_navbar-short__3Aa62 .styles_navbar-caption__113qK {
    line-height: 2.375rem !important;
    vertical-align: -2px; }
  .styles_navbar-dropdown__384L-.styles_navbar-short__3Aa62 .styles_navbar-logo__CA1w6 {
    margin-right: 0.5rem; }
    .styles_navbar-dropdown__384L-.styles_navbar-short__3Aa62 .styles_navbar-logo__CA1w6 img {
      height: 2.375rem; }
    .styles_navbar-dropdown__384L-.styles_navbar-short__3Aa62 .styles_navbar-logo__CA1w6.styles_mbr-iconfont__1Kv0x {
      font-size: 2.375rem;
      line-height: 2.375rem; }
  .styles_navbar-dropdown__384L-.styles_navbar-short__3Aa62 .styles_mbr-table-cell__2io7a {
    height: 3.625rem; }
  .styles_navbar-dropdown__384L- .styles_navbar-close__yZIab {
    left: 0.6875rem;
    position: fixed;
    top: 0.75rem;
    z-index: 1000; }
  .styles_navbar-dropdown__384L- .styles_hamburger-icon__ssbTA {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828; }

.styles_dropdown-menu__2wglY .styles_dropdown-toggle__PGNH_[data-toggle="dropdown-submenu"]::after {
  border-bottom: 0.35em solid transparent;
  border-left: 0.35em solid;
  border-right: 0;
  border-top: 0.35em solid transparent;
  margin-left: 0.3rem; }

.styles_dropdown-menu__2wglY .styles_dropdown-item__kvNlF:focus {
  outline: 0; }

.styles_nav-dropdown__Jp8VV {
  font-size: 0.75rem;
  font-weight: 500;
  height: auto !important; }
  .styles_nav-dropdown__Jp8VV .styles_nav-btn__Nwj8u {
    padding-left: 1rem; }
  .styles_nav-dropdown__Jp8VV .styles_link__2pMDO {
    margin: .667em 1.667em;
    font-weight: 500;
    padding: 0;
    transition: color .2s ease-in-out; }
    .styles_nav-dropdown__Jp8VV .styles_link__2pMDO.styles_dropdown-toggle__PGNH_ {
      margin-right: 2.583em; }
      .styles_nav-dropdown__Jp8VV .styles_link__2pMDO.styles_dropdown-toggle__PGNH_::after {
        margin-left: .25rem;
        border-top: 0.35em solid;
        border-right: 0.35em solid transparent;
        border-left: 0.35em solid transparent;
        border-bottom: 0; }
      .styles_nav-dropdown__Jp8VV .styles_link__2pMDO.styles_dropdown-toggle__PGNH_[aria-expanded="true"] {
        margin: 0;
        padding: 0.667em 3.263em  0.667em 1.667em; }
  .styles_nav-dropdown__Jp8VV .styles_link__2pMDO::after,
  .styles_nav-dropdown__Jp8VV .styles_dropdown-item__kvNlF::after {
    color: inherit; }
  .styles_nav-dropdown__Jp8VV .styles_btn__1AYUa {
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: 0;
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .styles_nav-dropdown__Jp8VV .styles_dropdown-menu__2wglY {
    border-radius: 0;
    border: 0;
    left: 0;
    margin: 0;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    position: relative; }
  .styles_nav-dropdown__Jp8VV .styles_dropdown-submenu__XpbxA {
    margin-left: 0.125rem;
    top: 0; }
  .styles_nav-dropdown__Jp8VV .styles_dropdown-item__kvNlF {
    font-weight: 500;
    line-height: 2;
    padding: 0.3846em 4.615em 0.3846em 1.5385em;
    position: relative;
    transition: color .2s ease-in-out, background-color .2s ease-in-out; }
    .styles_nav-dropdown__Jp8VV .styles_dropdown-item__kvNlF::after {
      margin-top: -0.3077em;
      position: absolute;
      right: 1.1538em;
      top: 50%; }
    .styles_nav-dropdown__Jp8VV .styles_dropdown-item__kvNlF:focus, .styles_nav-dropdown__Jp8VV .styles_dropdown-item__kvNlF:hover {
      background: none; }

@media (max-width: 767px) {
  .styles_nav-dropdown__Jp8VV.styles_navbar-toggleable-sm__1Nweu {
    bottom: 0;
    display: none;
    left: 0;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    width: 18.75rem;
    z-index: 999; } }
.styles_nav-dropdown__Jp8VV.styles_navbar-toggleable-xl__2K1aD {
  bottom: 0;
  display: none;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  width: 18.75rem;
  z-index: 999; }

.styles_nav-dropdown-sm__18Ww9 {
  display: block !important;
  overflow-x: hidden;
  overflow: auto;
  padding-top: 3.875rem; }
  .styles_nav-dropdown-sm__18Ww9::after {
    content: "";
    display: block;
    height: 3rem;
    width: 100%; }
  .styles_nav-dropdown-sm__18Ww9.styles_collapse__2Pf34.styles_in__1NnA5 ~ .styles_navbar-close__yZIab {
    display: block !important; }
  .styles_nav-dropdown-sm__18Ww9.styles_collapsing__5zmgh, .styles_nav-dropdown-sm__18Ww9.styles_collapse__2Pf34.styles_in__1NnA5 {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition: all 0.25s ease-out;
    -webkit-transition: all 0.25s ease-out;
    background: #282828; }
  .styles_nav-dropdown-sm__18Ww9.styles_collapsing__5zmgh[aria-expanded="false"] {
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%); }
  .styles_nav-dropdown-sm__18Ww9 .styles_nav-item__3m9JJ {
    display: block;
    margin-left: 0 !important;
    padding-left: 0; }
  .styles_nav-dropdown-sm__18Ww9 .styles_link__2pMDO,
  .styles_nav-dropdown-sm__18Ww9 .styles_dropdown-item__kvNlF {
    border-top: 1px dotted rgba(255, 255, 255, 0.1);
    font-size: 0.8125rem;
    line-height: 1.6;
    margin: 0 !important;
    padding: 0.875rem 2.4rem 0.875rem 1.5625rem !important;
    position: relative;
    white-space: normal; }
    .styles_nav-dropdown-sm__18Ww9 .styles_link__2pMDO:focus, .styles_nav-dropdown-sm__18Ww9 .styles_link__2pMDO:hover,
    .styles_nav-dropdown-sm__18Ww9 .styles_dropdown-item__kvNlF:focus,
    .styles_nav-dropdown-sm__18Ww9 .styles_dropdown-item__kvNlF:hover {
      background: rgba(0, 0, 0, 0.2) !important;
      color: #c0a375; }
  .styles_nav-dropdown-sm__18Ww9 .styles_nav-btn__Nwj8u {
    position: relative;
    padding: 1.5625rem 1.5625rem 0 1.5625rem; }
    .styles_nav-dropdown-sm__18Ww9 .styles_nav-btn__Nwj8u::before {
      border-top: 1px dotted rgba(255, 255, 255, 0.1);
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .styles_nav-dropdown-sm__18Ww9 .styles_nav-btn__Nwj8u + .styles_nav-btn__Nwj8u {
      padding-top: 0.625rem; }
      .styles_nav-dropdown-sm__18Ww9 .styles_nav-btn__Nwj8u + .styles_nav-btn__Nwj8u::before {
        display: none; }
  .styles_nav-dropdown-sm__18Ww9 .styles_btn__1AYUa {
    padding: 0.625rem 0; }
  .styles_nav-dropdown-sm__18Ww9 .styles_dropdown-toggle__PGNH_[data-toggle="dropdown-submenu"]::after {
    margin-left: .25rem;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0; }
  .styles_nav-dropdown-sm__18Ww9 .styles_dropdown-toggle__PGNH_[data-toggle="dropdown-submenu"][aria-expanded="true"]::after {
    border-top: 0;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0.35em solid; }
  .styles_nav-dropdown-sm__18Ww9 .styles_dropdown-menu__2wglY {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
    float: none;
    border-radius: 0;
    background: none; }
  .styles_nav-dropdown-sm__18Ww9 .styles_dropdown-submenu__XpbxA {
    left: 100%;
    margin-left: 0.125rem;
    margin-top: -1.25rem;
    top: 0; }

.styles_navbar-toggleable-sm__1Nweu .styles_nav-dropdown__Jp8VV .styles_dropdown-menu__2wglY {
  position: absolute; }

.styles_navbar-toggleable-sm__1Nweu .styles_nav-dropdown__Jp8VV .styles_dropdown-submenu__XpbxA {
  left: 100%;
  margin-left: 0.125rem;
  margin-top: -1.25rem;
  top: 0; }

.styles_navbar-toggleable-sm__1Nweu.styles_opened__3Epm2 .styles_nav-dropdown__Jp8VV .styles_dropdown-menu__2wglY {
  position: relative; }

.styles_navbar-toggleable-sm__1Nweu.styles_opened__3Epm2 .styles_nav-dropdown__Jp8VV .styles_dropdown-submenu__XpbxA {
  left: 0;
  margin-left: 00rem;
  margin-top: 0rem;
  top: 0; }

.styles_is-builder__1R0vq .styles_nav-dropdown__Jp8VV.styles_collapsing__5zmgh {
  transition: none !important; }

/*# sourceMappingURL=style.css.map */

.styles_sidebar_admin__1CRXT{
  width: 215px;
  position: fixed;
  top: 60px;
  height: 100%;
  
}

.styles_sidebar_admin__1CRXT .accordion {
    color:#474747;
    background-color:white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    height:100%;
  }

.styles_sidebar_admin__1CRXT .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin:0 15px;
  }

.styles_sidebar_admin__1CRXT .accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin:0 15px;
  }

.styles_sidebar_admin__1CRXT .accordion__item--has-icon {
    position: relative;
  }

.styles_sidebar_admin__1CRXT .accordion__title {
    color:#474747;
    background-color:white;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
  }

.styles_sidebar_admin__1CRXT .accordion a{
    color:#474747;
  }

.styles_sidebar_admin__1CRXT .accordion a:hover{
    color:#373737;
    text-decoration:none;
  }

.styles_sidebar_admin__1CRXT .accordion__title:hover {
    background-color: rgba(189, 187, 187, 1);
  }

.styles_sidebar_admin__1CRXT .accordion__body {
    display: block;
    -webkit-animation: styles_fadein__3th5w 0.35s ease-in;
            animation: styles_fadein__3th5w 0.35s ease-in;
  }

.styles_sidebar_admin__1CRXT .accordion__body--hidden {
    display: none;
    opacity: 0;
    -webkit-animation: styles_fadein__3th5w 0.35s ease-in;
            animation: styles_fadein__3th5w 0.35s ease-in;
  }

.styles_sidebar_admin__1CRXT .accordion__title > *:last-child
  .accordion__body > *:last-child {
    margin-bottom: 0;
  }

.styles_sidebar_admin__1CRXT .accordion__arrow {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -6px;
  }

.styles_sidebar_admin__1CRXT .accordion__arrow::after
  .accordion__arrow::before {
    display: block;
    position: absolute;
    top: 50%;
    width: 10px;
    height: 2px;
    background-color: currentColor;
    content: '';
  }

.styles_sidebar_admin__1CRXT .accordion__arrow::before {
    left: 4px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }

.styles_sidebar_admin__1CRXT [aria-expanded='true'] .accordion__arrow::before [aria-selected='true'] .accordion__arrow::before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }

.styles_sidebar_admin__1CRXT .accordion__arrow::after {
    right: 4px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }

.styles_sidebar_admin__1CRXT [aria-expanded='true'] .accordion__arrow::after [aria-selected='true'] .accordion__arrow::after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }

.styles_sidebar_admin__1CRXT .accordion__arrow::before
  .accordion__arrow::after {
    transition: transform 0.25s ease, -webkit-transform 0.25s ease;
  }

.styles_sidebar_admin__1CRXT .accordion ul{
    list-style-type:none;
  }

.styles_sidebar_admin__1CRXT .accordion li{
    padding:10px 0;
    font-size:.8rem;
    margin-left:-10px;
    cursor:pointer;
  }

.styles_sidebar_admin__1CRXT .accordion li a{
    color:#a7a7a7;
    display: block
  }

.styles_sidebar_admin__1CRXT .accordion li a:hover {
      color: #fff;
    }

.styles_sidebar_admin__1CRXT .accordion li a[aria-selected='true']{
    font-weight:bold;
    color:white;
  }

.styles_sidebar_admin__1CRXT .accordion__title[aria-selected='true']{
    color:#474747;
    background-color:rgba(255, 255, 255, 0.2);
  }

.styles_sidebar_admin__1CRXT .accordion__title[aria-selected='true']:hover{
    color:#222;
    outline:0;
  }

.styles_sidebar_admin__1CRXT .accordion__title[aria-selected='true']:focus{
    outline:0;
  }

.styles_sidebar_admin__1CRXT .accordion__title--animated:hover .accordion__arrow {
    -webkit-animation-name: styles_move-down__3OEmO;
            animation-name: styles_move-down__3OEmO;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
  }

.styles_sidebar_admin__1CRXT .accordion__title--animated[aria-expanded='true']:hover .accordion__arrow {
    -webkit-animation-name: styles_move-up__1NRQH;
            animation-name: styles_move-up__1NRQH;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
  }




.styles_sidebar-selected__2k0sc{
  color:#474747;
  background-color:rgba(189, 187, 187,1);
}

.styles_hidden_copy__2y0il{
  color:#474747;
  background-color:#474747;
  height:1px;
  z-index:-2;
  position:fixed;
  top:-20px;
}

/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.styles_u-position-absolute__3uuLQ {
  position: absolute;
}

.styles_u-position-relative__gjrQY {
  position: relative;
}
/* 
:global(.accordion) {
  color:#474747;
  background-color:white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  height:100%;
}

:global(.accordion__item) {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin:0 15px;
}

:global(.accordion__item + .accordion__item) {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin:0 15px;
}

:global(.accordion__item--has-icon) {
  position: relative;
}

:global(.accordion__title) {
  color:#474747;
  background-color:white;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
}
:global(.accordion a){
  color:#474747;
}
:global(.accordion a:hover){
  color:#373737;
  text-decoration:none;
}

:global(.accordion__title:hover) {
  background-color: rgba(189, 187, 187, 1);
}

:global(.accordion__body) {
  display: block;
  animation: fadein 0.35s ease-in;
}

:global(.accordion__body--hidden) {
  display: none;
  opacity: 0;
  animation: fadein 0.35s ease-in;
}

:global(.accordion__title > *:last-child,
.accordion__body > *:last-child) {
  margin-bottom: 0;
}

:global(.accordion__arrow) {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 12px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -6px;
}

:global(.accordion__arrow::after,
.accordion__arrow::before) {
  display: block;
  position: absolute;
  top: 50%;
  width: 10px;
  height: 2px;
  background-color: currentColor;
  content: '';
}

:global(.accordion__arrow::before) {
  left: 4px;
  transform: rotate(45deg);
}

:global([aria-expanded='true'] .accordion__arrow::before, [aria-selected='true'] .accordion__arrow::before) {
  transform: rotate(-45deg);
}

:global(.accordion__arrow::after) {
  right: 4px;
  transform: rotate(-45deg);
}

:global([aria-expanded='true'] .accordion__arrow::after, [aria-selected='true'] .accordion__arrow::after) {
  transform: rotate(45deg);
}

:global(.accordion__arrow::before,
.accordion__arrow::after) {
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

:global(.accordion ul){
  list-style-type:none;
}

:global(.accordion li){
  padding:10px 0;
  font-size:.8rem;
  margin-left:-10px;
  cursor:pointer;
}



:global(.accordion li a){
  color:#a7a7a7;
  display: block;

  &:hover {
    color: #fff;
  }
}

:global(.accordion li a[aria-selected='true']){
  font-weight:bold;
  color:white;
} */

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@-webkit-keyframes styles_fadein__3th5w {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@keyframes styles_fadein__3th5w {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@-webkit-keyframes styles_move-down__3OEmO {
  0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  10% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  20% {
      -webkit-transform: translateY(5px);
              transform: translateY(5px);
  }
  30% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}

@keyframes styles_move-down__3OEmO {
  0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  10% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  20% {
      -webkit-transform: translateY(5px);
              transform: translateY(5px);
  }
  30% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}

@-webkit-keyframes styles_move-up__1NRQH {
  0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  10% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  20% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
  }
  30% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}

@keyframes styles_move-up__1NRQH {
  0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  10% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  20% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
  }
  30% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}

.styles_on_dashboard__KcyyG{
  color:#474747;
  background-color:rgba(189, 187, 187, 1);
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
}

/*
NavBar Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */
/*
NavBar
================================================================================
*/
.styles_navbar-admin__3ueWS {
  box-shadow: -5px 1px 10px #888;
  z-index: 900 !important;
  height: 60px;
  background-color:#474747 !important;
}
/*
  Layout
  ------------------------------------------------------------
  */
.styles_navbar-admin__3ueWS .styles_navbar__row__-w1dw {
    height: 100%;
  }
.styles_navbar-admin__3ueWS .styles_navbar__col__2BWam {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center
  }
.styles_navbar-admin__3ueWS .styles_navbar__col__2BWam:first-child {
      text-align: center
    }
@media (max-width: 767px) {

    .styles_navbar-admin__3ueWS .styles_navbar__col__2BWam:first-child {
        display: none
    }
      }
.styles_navbar-admin__3ueWS .styles_navbar__col__2BWam:not(:first-child):not(:last-child) {
      text-align: center;
    }
.styles_navbar-admin__3ueWS .styles_navbar__col__2BWam:last-child {
      text-align: right;
    }
/*
  Brand
  ------------------------------------------------------------
  */
.styles_navbar-admin__3ueWS .styles_navbar__brand__2ldDa {
    display: block;
    height: 100%;

    text-align: center;
  }
.styles_navbar-admin__3ueWS .styles_navbar__brand__link__3Jvaq {
    height: 100%;
    text-align: right;
  }
.styles_navbar-admin__3ueWS .styles_navbar__brand__img__23fXA {
    position: relative;
    /* width: 120px; */
    max-width: 120px;
    max-height: 46px;
    /* padding-top: 18px; */
  }
/* :global(.navbar-right li) {
    float: right;
  } */
.styles_navbar-admin__3ueWS .styles_location-logo__27m3p {
    display: inline-block;
    float:right;
    padding-left: 0.5rem;
  }
/* .location-dropdown {
    display: inline-block;
    padding-top: 13px;

    select {
      font-size: 17px;
      font-weight: 700;
    }
  } */
.styles_navbar-admin__3ueWS .styles_location-dropdown__3iozu {
  float: right;
  margin-top: 0.5rem;
}
.styles_navbar-admin__3ueWS .styles_location__1jFvO {
    height: 100%;
  }
.styles_navbar-admin__3ueWS .styles_mini_logo__3NMkt{
    height:40px;
    padding-right:10px;
    margin-top: 10px;
  }
.styles_navbar-admin__3ueWS .dropdown-menu>li>a {
    padding: 3px 0
  }
.styles_navbar-admin__3ueWS .dropdown-menu>li>a:hover {
      background-color: inherit;
    }
.styles_navbar-admin__3ueWS .styles_location-logo-bigger__1YTFA {
  width: 100px;
  height: 100px;
  display: inline-block;
}
.styles_navbar-admin__3ueWS .styles_location-logo-bigger__1YTFA img {
    width: 80px;
  }
.styles_navbar-admin__3ueWS .styles_location-selector__3kklQ {
  text-align: right;
  padding-right: 1em;
}
.styles_navbar-admin__3ueWS .styles_location-welcome__2lYii {
  font-size: 13px;
  /* margin-right: 14px; */
  margin-bottom: -3px;
  color:white;
}
.styles_navbar-admin__3ueWS .styles_topdropdown__3uzrf ul {
  width: 300px;
  border-top: none;
  border-radius: 0;
  margin-top: 15px;
  padding-top: 0;
  text-align: center;
}
/*
  Text & Links
  ------------------------------------------------------------
  */
.styles_navbar-admin__3ueWS .styles_navbar__text__29APM {
    display: inline;
    position: relative;
    top: calc(50% - 1rem);

    color: #646569;

    font-weight: 600;
    text-transform: uppercase;
  }
.styles_navbar-admin__3ueWS a.styles_navbar__text__29APM:hover,
    .styles_navbar-admin__3ueWS a.styles_navbar__text__29APM:active,
    .styles_navbar-admin__3ueWS a.styles_navbar__text__29APM:focus {
      color: #BDBBBB;
      text-decoration: none;
    }

.styles_header-link__1EHOK {
  padding: 0 1rem;
}

.styles_header-link__1EHOK a {
    display: block;
  }

.styles_header-link__1EHOK a:focus,
  .styles_header-link__1EHOK a:hover {
    font-weight: normal;
    text-decoration: none;
    background-color: transparent;
  }

.styles_header-link__1EHOK.styles_locations__1cY44 {
    border-top: 1px solid #ddd;
    padding: 0 0 1em;
    text-align: left;
  }

.styles_header-link__1EHOK.styles_top-link__rU83t {
      display: inline-block
  }

.styles_header-link__1EHOK.styles_top-link__rU83t:hover {
      background-color: #f6f6f6;
    }

.styles_header-link__1EHOK.styles_top-link__rU83t svg {
      margin-right: 0.3em;
    }


.styles_header-link--current__2Zlks {
  font-weight: bold;
}

.styles_rightbar__2BST1{
  padding-top:35px;
}

.styles_null_navbar__18JpE{
  height:0;
  min-height:0;
}

.styles_navbar-dropdown__3vlM8 {
  left: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.45s ease;
  z-index: 1030;
  background: #282828; }
  .styles_navbar-dropdown__3vlM8 .styles_navbar-logo__W-zmG {
    margin-right: 0.8rem;
    transition: margin 0.3s ease-in-out;
    vertical-align: middle; }
    .styles_navbar-dropdown__3vlM8 .styles_navbar-logo__W-zmG img {
      height: 3.125rem;
      transition: all 0.3s ease-in-out; }
    .styles_navbar-dropdown__3vlM8 .styles_navbar-logo__W-zmG.styles_mbr-iconfont__3Zy9U {
      font-size: 3.125rem;
      line-height: 3.125rem; }
  .styles_navbar-dropdown__3vlM8 .styles_navbar-caption__22tl5 {
    font-weight: 700;
    white-space: normal;
    vertical-align: -4px;
    line-height: 3.125rem !important; }
    .styles_navbar-dropdown__3vlM8 .styles_navbar-caption__22tl5, .styles_navbar-dropdown__3vlM8 .styles_navbar-caption__22tl5:hover {
      color: inherit;
      text-decoration: none; }
  .styles_navbar-dropdown__3vlM8 .styles_mbr-iconfont__3Zy9U + .styles_navbar-caption__22tl5 {
    vertical-align: -1px; }
  .styles_navbar-dropdown__3vlM8.styles_navbar-fixed-top__12SDV {
    position: fixed; }
  .styles_navbar-dropdown__3vlM8 .styles_navbar-brand__2uztY span {
    vertical-align: -4px; }
  .styles_navbar-dropdown__3vlM8.styles_bg-color__1WoGP.styles_transparent__2ECH1 {
    background: none; }
  .styles_navbar-dropdown__3vlM8.styles_navbar-short__3UeBm .styles_navbar-brand__2uztY {
    padding: 0.625rem 0; }
    .styles_navbar-dropdown__3vlM8.styles_navbar-short__3UeBm .styles_navbar-brand__2uztY span {
      vertical-align: -1px; }
  .styles_navbar-dropdown__3vlM8.styles_navbar-short__3UeBm .styles_navbar-caption__22tl5 {
    line-height: 2.375rem !important;
    vertical-align: -2px; }
  .styles_navbar-dropdown__3vlM8.styles_navbar-short__3UeBm .styles_navbar-logo__W-zmG {
    margin-right: 0.5rem; }
    .styles_navbar-dropdown__3vlM8.styles_navbar-short__3UeBm .styles_navbar-logo__W-zmG img {
      height: 2.375rem; }
    .styles_navbar-dropdown__3vlM8.styles_navbar-short__3UeBm .styles_navbar-logo__W-zmG.styles_mbr-iconfont__3Zy9U {
      font-size: 2.375rem;
      line-height: 2.375rem; }
  .styles_navbar-dropdown__3vlM8.styles_navbar-short__3UeBm .styles_mbr-table-cell__1E6cV {
    height: 3.625rem; }
  .styles_navbar-dropdown__3vlM8 .styles_navbar-close__Aigwz {
    left: 0.6875rem;
    position: fixed;
    top: 0.75rem;
    z-index: 1000; }
  .styles_navbar-dropdown__3vlM8 .styles_hamburger-icon__im6Rn {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828; }

.styles_dropdown-menu___YnmU .styles_dropdown-toggle__3Hv68[data-toggle="dropdown-submenu"]::after {
  border-bottom: 0.35em solid transparent;
  border-left: 0.35em solid;
  border-right: 0;
  border-top: 0.35em solid transparent;
  margin-left: 0.3rem; }

.styles_dropdown-menu___YnmU .styles_dropdown-item__39N2E:focus {
  outline: 0; }

.styles_nav-dropdown__8dzH0 {
  font-size: 0.75rem;
  font-weight: 500;
  height: auto !important; }
  .styles_nav-dropdown__8dzH0 .styles_nav-btn__3V3Fk {
    padding-left: 1rem; }
  .styles_nav-dropdown__8dzH0 .styles_link__XQVBy {
    margin: .667em 1.667em;
    font-weight: 500;
    padding: 0;
    transition: color .2s ease-in-out; }
    .styles_nav-dropdown__8dzH0 .styles_link__XQVBy.styles_dropdown-toggle__3Hv68 {
      margin-right: 2.583em; }
      .styles_nav-dropdown__8dzH0 .styles_link__XQVBy.styles_dropdown-toggle__3Hv68::after {
        margin-left: .25rem;
        border-top: 0.35em solid;
        border-right: 0.35em solid transparent;
        border-left: 0.35em solid transparent;
        border-bottom: 0; }
      .styles_nav-dropdown__8dzH0 .styles_link__XQVBy.styles_dropdown-toggle__3Hv68[aria-expanded="true"] {
        margin: 0;
        padding: 0.667em 3.263em  0.667em 1.667em; }
  .styles_nav-dropdown__8dzH0 .styles_link__XQVBy::after,
  .styles_nav-dropdown__8dzH0 .styles_dropdown-item__39N2E::after {
    color: inherit; }
  .styles_nav-dropdown__8dzH0 .styles_btn__2W5BG {
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: 0;
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .styles_nav-dropdown__8dzH0 .styles_dropdown-menu___YnmU {
    border-radius: 0;
    border: 0;
    left: 0;
    margin: 0;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    position: relative; }
  .styles_nav-dropdown__8dzH0 .styles_dropdown-submenu__2pJ4E {
    margin-left: 0.125rem;
    top: 0; }
  .styles_nav-dropdown__8dzH0 .styles_dropdown-item__39N2E {
    font-weight: 500;
    line-height: 2;
    padding: 0.3846em 4.615em 0.3846em 1.5385em;
    position: relative;
    transition: color .2s ease-in-out, background-color .2s ease-in-out; }
    .styles_nav-dropdown__8dzH0 .styles_dropdown-item__39N2E::after {
      margin-top: -0.3077em;
      position: absolute;
      right: 1.1538em;
      top: 50%; }
    .styles_nav-dropdown__8dzH0 .styles_dropdown-item__39N2E:focus, .styles_nav-dropdown__8dzH0 .styles_dropdown-item__39N2E:hover {
      background: none; }

@media (max-width: 767px) {
  .styles_nav-dropdown__8dzH0.styles_navbar-toggleable-sm__2Rn3Q {
    bottom: 0;
    display: none;
    left: 0;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    width: 18.75rem;
    z-index: 999; } }
.styles_nav-dropdown__8dzH0.styles_navbar-toggleable-xl__2VKcF {
  bottom: 0;
  display: none;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  width: 18.75rem;
  z-index: 999; }

.styles_nav-dropdown-sm__3rKLd {
  display: block !important;
  overflow-x: hidden;
  overflow: auto;
  padding-top: 3.875rem; }
  .styles_nav-dropdown-sm__3rKLd::after {
    content: "";
    display: block;
    height: 3rem;
    width: 100%; }
  .styles_nav-dropdown-sm__3rKLd.styles_collapse__-0uxc.styles_in__3Q_R8 ~ .styles_navbar-close__Aigwz {
    display: block !important; }
  .styles_nav-dropdown-sm__3rKLd.styles_collapsing__39_iX, .styles_nav-dropdown-sm__3rKLd.styles_collapse__-0uxc.styles_in__3Q_R8 {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition: all 0.25s ease-out;
    -webkit-transition: all 0.25s ease-out;
    background: #282828; }
  .styles_nav-dropdown-sm__3rKLd.styles_collapsing__39_iX[aria-expanded="false"] {
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%); }
  .styles_nav-dropdown-sm__3rKLd .styles_nav-item__1aKTf {
    display: block;
    margin-left: 0 !important;
    padding-left: 0; }
  .styles_nav-dropdown-sm__3rKLd .styles_link__XQVBy,
  .styles_nav-dropdown-sm__3rKLd .styles_dropdown-item__39N2E {
    border-top: 1px dotted rgba(255, 255, 255, 0.1);
    font-size: 0.8125rem;
    line-height: 1.6;
    margin: 0 !important;
    padding: 0.875rem 2.4rem 0.875rem 1.5625rem !important;
    position: relative;
    white-space: normal; }
    .styles_nav-dropdown-sm__3rKLd .styles_link__XQVBy:focus, .styles_nav-dropdown-sm__3rKLd .styles_link__XQVBy:hover,
    .styles_nav-dropdown-sm__3rKLd .styles_dropdown-item__39N2E:focus,
    .styles_nav-dropdown-sm__3rKLd .styles_dropdown-item__39N2E:hover {
      background: rgba(0, 0, 0, 0.2) !important;
      color: #c0a375; }
  .styles_nav-dropdown-sm__3rKLd .styles_nav-btn__3V3Fk {
    position: relative;
    padding: 1.5625rem 1.5625rem 0 1.5625rem; }
    .styles_nav-dropdown-sm__3rKLd .styles_nav-btn__3V3Fk::before {
      border-top: 1px dotted rgba(255, 255, 255, 0.1);
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .styles_nav-dropdown-sm__3rKLd .styles_nav-btn__3V3Fk + .styles_nav-btn__3V3Fk {
      padding-top: 0.625rem; }
      .styles_nav-dropdown-sm__3rKLd .styles_nav-btn__3V3Fk + .styles_nav-btn__3V3Fk::before {
        display: none; }
  .styles_nav-dropdown-sm__3rKLd .styles_btn__2W5BG {
    padding: 0.625rem 0; }
  .styles_nav-dropdown-sm__3rKLd .styles_dropdown-toggle__3Hv68[data-toggle="dropdown-submenu"]::after {
    margin-left: .25rem;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0; }
  .styles_nav-dropdown-sm__3rKLd .styles_dropdown-toggle__3Hv68[data-toggle="dropdown-submenu"][aria-expanded="true"]::after {
    border-top: 0;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0.35em solid; }
  .styles_nav-dropdown-sm__3rKLd .styles_dropdown-menu___YnmU {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
    float: none;
    border-radius: 0;
    background: none; }
  .styles_nav-dropdown-sm__3rKLd .styles_dropdown-submenu__2pJ4E {
    left: 100%;
    margin-left: 0.125rem;
    margin-top: -1.25rem;
    top: 0; }

.styles_navbar-toggleable-sm__2Rn3Q .styles_nav-dropdown__8dzH0 .styles_dropdown-menu___YnmU {
  position: absolute; }

.styles_navbar-toggleable-sm__2Rn3Q .styles_nav-dropdown__8dzH0 .styles_dropdown-submenu__2pJ4E {
  left: 100%;
  margin-left: 0.125rem;
  margin-top: -1.25rem;
  top: 0; }

.styles_navbar-toggleable-sm__2Rn3Q.styles_opened__1xepx .styles_nav-dropdown__8dzH0 .styles_dropdown-menu___YnmU {
  position: relative; }

.styles_navbar-toggleable-sm__2Rn3Q.styles_opened__1xepx .styles_nav-dropdown__8dzH0 .styles_dropdown-submenu__2pJ4E {
  left: 0;
  margin-left: 00rem;
  margin-top: 0rem;
  top: 0; }

.styles_is-builder__qr3Z4 .styles_nav-dropdown__8dzH0.styles_collapsing__39_iX {
  transition: none !important; }

/*# sourceMappingURL=style.css.map */


.navbar-default .container{
  height:60px;
  max-width: 1800px;
  margin-left: 0;
}

.form-group{
  margin-bottom:none !important;
}

.navbar-nav .form-control{
  background-color:white;
  color: #aaa;
  height: 100%;
  padding-left: 20px;
}

.navbar-nav .form-control::-webkit-input-placeholder,
.navbar-nav .form-control::-moz-placeholder,
.navbar-nav .form-control::-ms-input-placeholder{
  color: #aaa;
}

.navbar-nav .input-group{
  height: 100%;
}

.navbar-nav .input-group .form-control{
  border:none;
}


.navbar-nav .input-group .input-group-btn .btn{
  border:none;
}

.styles_search-nav__11zqy{
  color:#979797;
  border-left:1px solid #bbb;
  border-right:1px solid #bbb;
  height: 100%;
}

.styles_likeGmail__18XTR{
  min-height:100px;
  max-height:300px;
  overflow: auto;
}

.styles_likeGmail__18XTR .styles_pulldown-location__18Zse {
    height: 2rem;
    line-height: 2rem;
    border-bottom: 1px solid #eee;
    padding: 0 1rem
  }

.styles_likeGmail__18XTR .styles_pulldown-location__18Zse:hover {
      background-color: #f6f6f6;
    }

.styles_likeGmail__18XTR .styles_pulldown-location__18Zse.styles_master-dashboard__1DxBk {}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background:#777;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #777;
}

.styles_topdropdown__3uzrf{
  white-space: pre-wrap;
}

.styles_topdropdown__3uzrf a{
  color:#778998
}
.styles_topdropdownsecond__2Eue1{
  white-space: pre-wrap;
  font-size:13px;
  font-weight:bold;
  top:-4px;
}
.styles_topdropdownsecond__2Eue1 a{
  color:#79bbf5;
}

.navbar-default .navbar-nav>li>a{
  padding-top:8px;
}


.styles_url__2ewYG{
  text-align:left
}


.styles_url__2ewYG:hover {
    background-color: #e8e8e8;
  }

.styles_url-separator__1lln1{
  color:#d7d7d7;
}
/*
Account Security Form Modal Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */
.custom-button{
    color:orange
}
.styles_large-button--primary__3pr8t {
  border: 2px solid #00a1df;
  border-radius: 1rem;
  padding: 1.1rem 8.0rem;

  color: #FFF;
  background-color: #00a1df;

  font-size: 1.1rem;
  font-weight: 600;
  line-height: normal
}
.styles_large-button--primary__3pr8t:hover {
    color: #00a1df;
    background-color: #FFF;
  }
.styles_large-button--primary__3pr8t:focus {
    outline: none;
  }
.styles_large-button--secondary__3Yrac {
  border: 1px solid #73C8AF;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;

  color: #FFF;
  background-color: #73C8AF;

  font-weight: 600;
  line-height: normal
}
.styles_large-button--secondary__3Yrac:hover {
    color: #73C8AF;
    background-color: #FFF;
  }
.styles_large-button--secondary__3Yrac:focus {
    outline: none;
  }
.styles_large-button--secondary__3Yrac:disabled{
    background-color:#FFF;
    color: lightgrey;
    border: 1px solid lightgrey;
  }
.styles_jumbotron__32i3k {
    padding: 3rem 1rem 1rem;
    margin-bottom: 0;
    background-color: #00a1df;
    color: #fff;
    text-align: center;
    border-radius: 0;
    position: relative;
}
.styles_dark__2QMM6{
    background-color: #00a1df;
    color: #fff;
}
.styles_flex-container__s8gwu{
  display: flex;
}
.styles_flex-container__s8gwu div{
  flex-grow:1;
  margin: auto;
}
.styles_flex-center__3Z_-q{
  justify-content:center;
  text-align:center;
}
.styles_flex-cell3__3FVN1{
  width: calc(100% / 3);
}
.styles_flex-cell4__Md-iZ{
  width: calc(100% / 4);
}
.styles_flex-cell8__2LxeN{
  width: calc(100% / 8);
}
.styles_field-instructions__3Oh1o {
  margin-bottom: 1.5rem;
  text-align: justify;
  font-weight: 300
}
.styles_field-instructions__3Oh1o:last-child {
    margin-bottom: 2.5rem;
  }
.styles_light-gray__1DEaE{
  color:#979797;
}
.styles_spacer__2Ed3p {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
.styles_spacer--after-form-group__1n38N {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.styles_spacer--members-list__36Gs0 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.styles_hidden-field__uVxnr {
  display: none;
}
.styles_hidden-field__uVxnr + .styles_align-with-input__1JhCZ {
  padding-top: 0;
}
.styles_white__3O6Ip{
  color:#fafafa
}
.styles_white__3O6Ip:hover {
    color: #cacaca;
  }
.styles_white-background__2gsCv{
  background:#fff;
}
.styles_float-right__54NLC {
  float: right;
}
.styles_float-left__13r-x {
  float: left;
}
.styles_modal-section-title__1Png6 {
  margin: 0 0 1rem 0;
  color: #00a1df;
  font-size: 1.5rem;
}
.modal-body {
  padding-bottom: 1.5rem;
}
.styles_dhqblue__3kozm{
  color:#00a1df;
}
.styles_acceptsSMScheckbox__2d1Hn input{
  opacity:.4;
  height: 0.8rem;
}
.styles_acceptsSMS__3SkgZ{
  font-size:.8rem;
  color: #999;
}

/*
Page Wide
================================================================================
*/


@media (min-width: 768px) {


.modal-sm {
    width: 400px
}
  }

#styles_paymentplans__2NuvY #styles_header__2HQn0 {
  padding-top: 20px;
}

#styles_paymentplans__2NuvY .styles_button-create__1Y00G{
  padding: 20px 0;
}
.styles_intro__3pUMT{}

.styles_buttons__2JnWK {}

.styles_explanation__25WHp {
  margin-top: 1rem;
}

.styles_explanation__25WHp  {
    color: #767676;
    background: #fff;
}

.styles_explanation__25WHp ol {
    margin-bottom: 0;
    counter-reset: myCounter;
}

.styles_explanation__25WHp ol li {
    list-style: none;
    padding-left: .5rem;
    margin-bottom: 1rem;
  }

.styles_explanation__25WHp ol li:before {
    position: absolute;
    left: 0px;
    margin-top: -10px;
    counter-increment: myCounter;
    content: counter(myCounter);
    display: inline-block;
    text-align: center;
    margin: 5px 10px;
    line-height: 40px;
    transition: all .2s;
    color: #ffffff;
    background: #149dcc;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.styles_stats-button__1Tvrb{
  margin:1rem 0;
}
.styles_metrics__2Eit9 {
  color: #646569;
}

.styles_metrics__value__Xj4rM {
  color: #00a1df;

  font-size: 1.125rem;
  font-weight: bold;
}

.styles_metrics__entry__1Q69I {
  margin-bottom: 0.5rem;
}

.styles_timeleft__37HJX {
  display: inline-block;
}

.styles_timeleft__37HJX div {
    display: inline-block;
  }



/**
 * styles.module.css
 *
 * App container styles
 */
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */

:root {
  --nav-height: 60px;
}

.styles_wrapper__3vyeE {
  height: 100%;
  margin-top: 60px;
}

.styles_wrapper-dentist-new__2fS64{
  overflow:hidden;
  height: 100%;
  max-width: 1800px;
}

.styles_wrapper--no-navbar__28VH1 {
  height: 100%;
}

.styles_container-wrapper__3JW9p {
  padding:0;
  margin-top: 60px;
  position: relative;
}

.styles_content-wrapper__2gLvb {
  margin-top: 2rem;
  margin-bottom: 4rem;

}

.styles_container-wrapper__3JW9p.styles_dentist-new__3yKPV{
  /* min-height: 92vh; */
  /* background-color:rgba(189, 187, 187, .2); */
  margin-left: 215px;
  position: relative;
  padding-bottom: 3.4rem;
}

.styles_container-wrapper__3JW9p.styles_dentist-new__3yKPV.styles_globalbanneron__nDYSg{
  /* min-height: 92vh; */
  /* background-color:rgba(189, 187, 187, .2); */
  margin-top:110px;
}

.styles_container-wrapper__3JW9p.styles_bclanding__2y8-n{
  /* min-height: 92vh; */
  /* background-color:rgba(189, 187, 187, .2); */
  margin-top: 0;
}

.styles_container__1gq13{
  padding:15px;
}

.styles_memberlist-wrapper__3-THO {
  padding: 20px;
}

.styles_globalbanner__2T-fz {
  height: 30px;
  display: block;
  background: #00a1df;
  position: fixed;
    left: 0;
    right: 0;
    z-index: 9999;
}

/* Roboto font definition */
@font-face {
  font-family: Roboto;
  src: url(/static/media/Roboto-Bold-webfont.5f2071a3.eot);
  src:
    url(/static/media/Roboto-Bold-webfont.5f2071a3.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Roboto-Bold-webfont.2e3d794b.woff) format('woff'),
    url(/static/media/Roboto-Bold-webfont.a452a6e8.ttf) format('truetype'),
    url(/static/media/Roboto-Bold-webfont.aff20cb4.svg#robotobold) format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url(/static/media/Roboto-Light-webfont.82fabc8a.eot);
  src:
    url(/static/media/Roboto-Light-webfont.82fabc8a.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Roboto-Light-webfont.6e0ff940.woff) format('woff'),
    url(/static/media/Roboto-Light-webfont.2575b27e.ttf) format('truetype'),
    url(/static/media/Roboto-Light-webfont.7248a796.svg#robotolight) format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url(/static/media/Roboto-Medium-webfont.ad11054c.eot);
  src:
    url(/static/media/Roboto-Medium-webfont.ad11054c.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Roboto-Medium-webfont.8b88b4ee.woff) format('woff'),
    url(/static/media/Roboto-Medium-webfont.9f855913.ttf) format('truetype'),
    url(/static/media/Roboto-Medium-webfont.e4814937.svg#robotomedium) format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url(/static/media/Roboto-Regular-webfont.86c03c03.eot);
  src:
    url(/static/media/Roboto-Regular-webfont.86c03c03.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Roboto-Regular-webfont.90564028.woff) format('woff'),
    url(/static/media/Roboto-Regular-webfont.447f07f0.ttf) format('truetype'),
    url(/static/media/Roboto-Regular-webfont.2e416dfb.svg#robotoregular) format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url(/static/media/Roboto-Thin-webfont.ddc05a8d.eot);
  src:
    url(/static/media/Roboto-Thin-webfont.ddc05a8d.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Roboto-Thin-webfont.391f6caa.woff) format('woff'),
    url(/static/media/Roboto-Thin-webfont.32e4d956.ttf) format('truetype'),
    url(/static/media/Roboto-Thin-webfont.5e760f31.svg#robotothin) format('svg');
  font-weight: 100;
  font-style: normal;
}

/* Open Sans font definition */
@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.3d5923f9.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-LightItalic.4f712f17.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.17e98b9e.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Italic.95855802.ttf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.043771c9.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-BoldItalic.1c9d53ac.ttf);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8dafb2ac.ttf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBoldItalic.834e159e.ttf);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Epilogue';
  src: url(/static/media/Epilogue-Regular.1cb1776e.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Epilogue';
  src: url(/static/media/Epilogue-Italic.c12afbbb.ttf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Epilogue';
  src: url(/static/media/Epilogue-Bold.ab9f427b.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Epilogue';
  src: url(/static/media/Epilogue-BoldItalic.2d0fe118.ttf);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Termina';
  src: url(/static/media/Termina-Bold.c3e926ae.otf);
  font-weight: 600;
  font-style: normal;
}
.clearfix::before,
.clearfix::after {
  content: ' ';
  display: table;
}
.clearfix::after {
  clear: both;
}
.clearfix {
  *zoom: 1;
}
/* elevio tab overrides */
#_elev_io ._bzf50 {
  padding: 10px 25px !important;
}
#_elev_io ._19znh ._bhq21 {
  transition: 0.3s ease
}
#_elev_io ._19znh ._bhq21:hover {
    background-color: #0181b2 !important;
  }
/*
Old Variables
================================================================================
WARNING: DO NOT USE GOING FORWARD

TODO: Refactor them out.
*/
/*
Style Variables
================================================================================
USE THESE: They are the new ones.

NOTE: The value of `rem` units will be calculated based on the $font-size-base
      variable below.
*/
/*
Colors
------------------------------------------------------------
*/
/* rgb(  0, 161, 263) */
/* rgb(115, 200, 175) */
/* darker VHQblue */
/* rgb(100, 101, 105) */
/* rgb(189, 187, 187) */
/* rgb(100, 101, 105) */
/*
Fonts
------------------------------------------------------------
*/
/*
Sizing
------------------------------------------------------------
*/
/* Taken by inspecting the size of the header (navbar) / footer. */
/* A magic-ishh number that was determined by trial and error.
 * Affected by the Footer height:
 *   - Smaller footer => bottom whitespace.
 *   - Larger  footer => scroll bar (even without content filling the page).
 */
html, body {
    font-family: 'Barlow Semi Condensed', Helvetica, Arial, sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    width: 100%;
    color: #444 !important;
  }
/* max-width: 1800px; */
html #app, html .modal-dialog, body #app, body .modal-dialog {
      height: 100%;
    }
html #app h1, html #app .h1, html #app h2, html #app .h2, html .modal-dialog h1, html .modal-dialog .h1, html .modal-dialog h2, html .modal-dialog .h2, body #app h1, body #app .h1, body #app h2, body #app .h2, body .modal-dialog h1, body .modal-dialog .h1, body .modal-dialog h2, body .modal-dialog .h2
      {
        font-family: 'Rubik', 'Open Sans', Helvetica, Arial, sans-serif;
      }
html {
  }
body {
    height: 100%;
    background-color:rgba(189, 187, 187, .2);
  }
/** Bootstrap overrides **/
a {
  color: #337AB7;
}
.link {
  color: #337AB7;
}
.popover--large {
  max-width: 360px;
  width: 360px;
}
.popover-title {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}
.popover-content {
  text-align: justify;
}
.navbar-default {
  background-color: #FFF;
  border: none;
  margin-bottom: 0;
}
.navbar-default .container {
  height: 60px;
  width: 100%;
}
.navbar-brand {
  position: relative;
  height: 100%;
}
.navbar-header {
  height: 100%;
}
.navbar-collapse {
  padding-top: 2rem;
}
button {
  height: 2rem;
  font-size: 0.875rem;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
          user-select: none;
  border: none;
  outline: 0 !important;
  cursor: pointer;
  transition: background-color 200ms ease-out, box-shadow 100ms ease-out;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  padding-left: 2rem;
  padding-right: 2rem
}
button:active { padding-bottom: 0.2rem; }
.btn-primary {
  border-radius: 0;
}
.btn-padding {
  padding-left: 2rem;
  padding-right: 2rem;
}
.btn-round {
  border-radius: 1rem;
}
.btn-green {
}
.btn-darkest-green {
}
.btn-cyan {
}
.btn-outline {
  border: 0.1rem solid #FFF;
}
.btn-shadow {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  border: none;
}
/** forms **/
.form-horizontal .control-label {
  font-size: 1rem;
  font-weight: bold;
  padding: 0 0 0.5rem 0;
}
.form-group {
  /* margin-bottom: 1.5rem; */
}
@media (max-width: 767px) {
.form-group .col-sm-4 {
    margin-bottom: 1rem
}
  }
select {
-webkit-border-radius: 0;
border: 0;
outline: 1px solid rgba(189, 187, 187, 0.3);
outline-offset: -1px;
}
.form-control {
  /* height: 40px; */
  border: 1px solid rgba(189, 187, 187, 0.3); /* $color-secondary-lowlight in rgba form */
  border-radius: 0;

  color: #646569; /* $color-primary-lowlight */
  background-color: #f8f8f8;
  box-shadow: none;

  font-size: 1rem
}
.form-control::-webkit-input-placeholder {
    opacity: 1;
    color: #646569; /* $color-primary-lowlight */

    font-size: 0.75rem;
  }
.form-control:-moz-placeholder { /* Firefox 18- */
    opacity: 1;
    color: #646569; /* $color-primary-lowlight */

    font-size: 0.75rem;
  }
.form-control::-moz-placeholder { /* Firefox 19+ */
    opacity: 1;
    color: #646569; /* $color-primary-lowlight */

    font-size: 0.75rem;
  }
.form-control:-ms-input-placeholder {
    opacity: 1;
    color: #646569; /* $color-primary-lowlight */

    font-size: 0.75rem;
  }
.form-control[type=checkbox]:focus,
  .form-control[type=radio]:focus {
    box-shadow: none;
    outline: none;
  }
@media (max-width: 767px) {
input[type=checkbox] {
    margin-left: 1px
}
  }
input[type=checkbox],
input[type=radio] {
  cursor: pointer
}
input[type=checkbox]:focus,
  input[type=checkbox]:focus,
  input[type=radio]:focus,
  input[type=radio]:focus {
    box-shadow: none;
    outline: none;
  }
.has-error .form-control {
  border: 1px solid #a94442; /* add the border back for errors */

  box-shadow: none;
}
.input-group-addon {
  height: 34px;
  border: 1px solid rgba(189, 187, 187, 0.3); /* $color-secondary-lowlight in rgba form */
  border-radius: 0;

  color: #646569; /* $color-primary-lowlight */
  box-shadow: none;

  font-size: 1rem;
}
.input-group-btn > .btn {
  height: 2.5rem;
  border: 1px solid rgba(189, 187, 187, 0.5); /* $color-secondary-lowlight in rgba form */
  border-radius: 0;

  color: #646569; /* $color-primary-lowlight */
  box-shadow: none;

  font-size: 1rem;
}
.form-control--large {
  /* a 20% increase */
  height: 3rem;
  font-size: 1.2rem
}
.form-control--large::-webkit-input-placeholder {
    font-size: 0.9rem;
  }
.form-control--large:-moz-placeholder { /* Firefox 18- */
    font-size: 0.9rem;
  }
.form-control--large::-moz-placeholder { /* Firefox 19+ */
    font-size: 0.9rem;
  }
.form-control--large:-ms-input-placeholder {
    font-size: 0.9rem;
  }
.form-control--large + .input-group-addon,
.form-control--large + .input-group-btn > .btn {
  /* a 20% increase */
  height: 3rem;
  width: 3.15rem;
  font-size: 1.2rem;
}
.help-block {
  margin-bottom: 0;
  font-size: 0.8rem;
}
/*
Custom Redux-Form Components
------------------------------------------------------------
NOTE: Don't use CSS-Modules to wrap any custom redux-form components (passed
      to a redux-form field).  There are issues with redux-form interacting
      poorly with other high-level-components, including cases where React
      will interpret the wrapped component as a new component when the render
      method is called for an unrelated part of the page.  This will cause a lot
      of extra rendering to happen, and the user's current field element will
      be de-selected if it is the wrapped component.

      See https://github.com/erikras/redux-form/issues/1094
*/
.input-checkbox {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.375rem;
    width: 1.375rem;
    height: 1.375rem;
    cursor: pointer;
    margin-left: 1px;
  }
.input-checkbox-label {
    margin-bottom: 0;

    font-weight: 300;
  }
.input-checkbox-label span {
      vertical-align: middle;
      margin-left: 0.25rem;
    }
.input-checkbox-label a {
      color: rgba(  0, 161, 263, 0.65);

      cursor: pointer;
      text-decoration: none
    }
.input-checkbox-label a:hover {
        color: #00a1df;
        text-decoration: none;
      }
.input-time .input-time__time {
      display: inline-block;
      width: calc(100% - 62px);

      vertical-align: top;
    }
.input-time .input-time__am-pm {
      display: inline-block;
      padding-left: 1rem;
    }
.input-time .input-time__am-pm .checkbox {
        height: 22px;
        min-height: 22px;
        padding-top: 0px;
      }
/** REACT-AUTOSUGGEST **/
.react-autosuggest__container {
  position: relative;
  float: left;
}
.react-autosuggest__input {
  width: 240px;
  height: 35px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #AAA;
  border-radius: 0;
}
.react-autosuggest__input:focus {
  outline: none;
}
.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 35px;
  width: 240px;
  border: 1px solid #AAA;
  background-color: #FFF;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 6px 20px;
}
.react-autosuggest__suggestion--focused {
  background-color: #DDD;
}
.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
  border-top: 1px dashed #CCC;
}
.react-autosuggest__section-container:first-child .react-autosuggest__section-title {
  border-top: 0;
}
/** END OF REACT-AUTOSUGGEST **/
/*
Modal
================================================================================
*/
.modal-content {
    border-radius: 5px

  }
@media (max-width: 1200px) {

  .modal-content {
      padding-bottom: 3rem

  }
    }
/*
  Modal Header
  ------------------------------------------------------------
  */
.modal-header {
    padding: 3rem 3rem 2rem 3rem;
    border: none;
  }
/* TODO: Remove `!important` on `button` styles (above), then remove them here. */
.modal-header .close {
      position: absolute;
      top: -1rem;
      right: -1rem;
      width: 3rem;
      height: 3rem;
      margin: 0;
      padding: 0.3rem 1.1rem 0.7rem !important;
      border-radius: 100%;

      opacity: 1;
      background-color: #646569;
      color: #FFF;

      font-size: 2rem;
      font-weight: normal;
      text-shadow: none
    }
.modal-header .close:hover {
        font-weight: bold;
      }
.modal-header .modal-title {
      float: left;

      color: #00a1df;

      font-size: 2.5rem;
      line-height: 1
    }
@media (max-width: 767px) {

    .modal-header .modal-title {
        float: none;
        text-align: center
    }
      }
/*
  Modal Body
  ------------------------------------------------------------
  */
.modal-body {
    padding: 0 3rem 3rem 3rem;
    border: none;
  }
/*
  Modal Footer
  ------------------------------------------------------------
  */
.modal-footer {
    padding: 0 3rem 3rem 3rem;
    border: none;
  }
/*
  Modal Control
  ------------------------------------------------------------
  */
.modal-controls {
    position: relative;
    float: right
  }
@media (max-width: 767px) {
  .modal-controls {
      float: none;
      text-align: center;
      margin-top: 1rem
  }
    }
.modal-control {
    border: 1px solid #00a1df;
    padding: 0.5rem 1rem;

    color: #FFF;
    background-color: #00a1df;

    font-weight: 600;
    line-height: normal;
    text-transform: uppercase
  }
.modal-control:hover {
      color: #00a1df;
      background-color: #FFF;
    }
.modal-control:focus {
      outline: none;
    }
.modal-control[disabled] {
      opacity: 0.5;
    }
.modal-control {

    /* negate the hover effects when the button is disabled */
  }
.modal-control[disabled]:hover {
      color: #FFF;
      background-color: #00a1df;
    }
/* End Modal Styles */
/*
React Dropzone S3 Uploader Overrides
------------------------------------------------------------
NOTE: It applies styles on the element directly, thus the use of `!important`.
*/
.react-dropzone-s3-uploader {
    border: none !important;
    border-radius: 0 !important;
  }
.wrapper{
    margin-top:0 !important;
}
.app-containers-App---wrapper{
  margin-top:0 !important;
}
.container{
    width:100%;
    padding:0;
}
.app_container-wrapper__3rO21 {
    min-height: 100%;
  }
.app_content-wrapper__2EOuy {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
.app_user-name__1Y1Tm {
    font-weight: bold;
  }
/*
Page Header
================================================================================
*/
.app_page-header__337OF {
  color: #FFF;
  background: url(https://dentalhq-files.s3.amazonaws.com/marketing_materials/dentist-and-woman.jpg) center / cover no-repeat;

  text-align: center;
}
.app_page-header__337OF .app_page-header__overlay__1yRSo {
    display: block;
    margin: 0;
    padding: 5rem 0;

    background-color: rgba(  0, 161, 263, 0.65);
  }
.app_page-header__337OF .app_large-title__1Pc9L {
    margin: 0;

    font-size: 3rem;
    text-transform: capitalize
  }
.app_page-header__337OF .app_large-title__1Pc9L:not(:only-child) {
      margin-bottom: 3rem;
    }
.app_page-header__337OF form br {
      display: none;
    }
.app_page-header__337OF form input[type=text] {
      margin-right: 10px;
    }
.app_page-header__337OF form input[type=submit] {
      background-color: #73C8AF;
      border-color: #73C8AF
    }
.app_page-header__337OF form input[type=submit]:hover {
        color: #73C8AF;
        background-color: #FFF;
      }
.app_border-content__3uW99 {
  min-height: 3.5rem; /* for pages without any border content */
  padding: 1rem 0;

  color: #FFF;
  background-color: #646569;

  font-weight: lighter;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
}
.app_border-content__3uW99 a {
    color: #FFF
  }
.app_border-content__3uW99 a:focus {
      color: #FFF;
      text-decoration: none;
    }
.app_border-content__3uW99 a:hover {
      color: #FFF;
      text-decoration: underline;
    }
.app_border-content--user__1UmPb {
  text-transform: capitalize;
}
.app_user-name__1Y1Tm {
  font-weight: bold;
}
.app_header-link__2lrt2 {
  padding: 0 1rem;
}
.app_header-link__2lrt2 a:focus,
  .app_header-link__2lrt2 a:hover {
    font-weight: bold;
    text-decoration: none;
  }
.app_header-link--current__3DPI1 {
  font-weight: bold;
}
.app_small-welcome__Olh9O{
  font-size:.9rem;
}
.app_small-welcome-grey__1b00r{
  font-size:.9rem;
  color:#a7a7a7;
  text-align:center;
  width:40%;
  font-weight:bold;
  margin:0 auto;
}
.app_other-offices__1dqRe{
  background-color: white;
    color: #272727;
    border-radius: 4px;
    font-size:.9rem;
    float:right;
}
/*
NavBar
================================================================================
*/
.app_navbar__10_wu {
  box-shadow: -5px 1px 10px #888;
  z-index: 900 !important;
}
/*
  Layout
  ------------------------------------------------------------
  */
.app_navbar__10_wu .app_navbar__row__2eO8x {
    height: 100%;
  }
.app_navbar__10_wu .app_navbar__col__tNVzA {
    height: 100%
  }
.app_navbar__10_wu .app_navbar__col__tNVzA:first-child {
      text-align: left
    }
@media (max-width: 767px) {

    .app_navbar__10_wu .app_navbar__col__tNVzA:first-child {
        display: none
    }
      }
.app_navbar__10_wu .app_navbar__col__tNVzA:not(:first-child):not(:last-child) {
      text-align: center;
    }
.app_navbar__10_wu .app_navbar__col__tNVzA:last-child {
      text-align: right;
    }
/*
  Brand
  ------------------------------------------------------------
  */
.app_navbar__10_wu .app_navbar__brand__3YB1V {
    display: block;
    height: 100%;

    text-align: center;
  }
/*
  Text & Links
  ------------------------------------------------------------
  */
.app_navbar__10_wu .app_navbar__text__1xWBj {
    display: inline;
    position: relative;
    top: calc(50% - 1rem);

    color: #646569;

    font-weight: 600;
    text-transform: uppercase;
  }
.app_navbar__10_wu a.app_navbar__text__1xWBj:hover,
    .app_navbar__10_wu a.app_navbar__text__1xWBj:active,
    .app_navbar__10_wu a.app_navbar__text__1xWBj:focus {
      color: #BDBBBB;
      text-decoration: none;
    }
.app_header-link__2lrt2 {
  padding: 0 1rem;
}
.app_header-link__2lrt2 a:focus,
  .app_header-link__2lrt2 a:hover {
    font-weight: bold;
    text-decoration: none;
  }
.app_header-link--current__3DPI1 {
  font-weight: bold;
}
.app_rightbar__R9LD2{
  padding-top:35px;
}
.app_null_navbar__3GZSB{
  height:0;
  min-height:0;
}
.app_navbar-fixed-top__3qWTW {
  position: fixed;
  background: #eee;
  max-width: 1800px;
}
.app_dentist-dashboard-header__2DoyB {
  font-family: 'Barlow Semi Condensed',sans-serif;
  padding:20px;
  min-height:300px;
}
.app_dash-box__1ALh1 {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
.wrapper{
    margin-top:0 !important;
}
.app-containers-App---wrapper{
  margin-top:0 !important;
}
.container{
    width:100%;
    padding:0;
}
.app_container-wrapper__3rO21 {
    min-height: 100%;
  }
.app_content-wrapper__2EOuy {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
.app_user-name__1Y1Tm {
    font-weight: bold;
  }
/*
Page Header
================================================================================
*/
.app_page-header__337OF {
  color: #FFF;
  background: url(https://dentalhq-files.s3.amazonaws.com/marketing_materials/dentist-and-woman.jpg) center / cover no-repeat;

  text-align: center;
}
.app_page-header__337OF .app_page-header__overlay__1yRSo {
    display: block;
    margin: 0;
    padding: 5rem 0;

    background-color: rgba(  0, 161, 263, 0.65);
  }
.app_page-header__337OF .app_large-title__1Pc9L {
    margin: 0;

    font-size: 3rem;
    text-transform: capitalize
  }
.app_page-header__337OF .app_large-title__1Pc9L:not(:only-child) {
      margin-bottom: 3rem;
    }
.app_page-header__337OF form br {
      display: none;
    }
.app_page-header__337OF form input[type=text] {
      margin-right: 10px;
    }
.app_page-header__337OF form input[type=submit] {
      background-color: #73C8AF;
      border-color: #73C8AF
    }
.app_page-header__337OF form input[type=submit]:hover {
        color: #73C8AF;
        background-color: #FFF;
      }
.app_border-content__3uW99 {
  min-height: 3.5rem; /* for pages without any border content */
  padding: 1rem 0;

  color: #FFF;
  background-color: #646569;

  font-weight: lighter;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
}
.app_border-content__3uW99 a {
    color: #FFF
  }
.app_border-content__3uW99 a:focus {
      color: #FFF;
      text-decoration: none;
    }
.app_border-content__3uW99 a:hover {
      color: #FFF;
      text-decoration: underline;
    }
.app_border-content--user__1UmPb {
  text-transform: capitalize;
}
.app_user-name__1Y1Tm {
  font-weight: bold;
}
.app_header-link__2lrt2 {
  padding: 0 1rem;
}
.app_header-link__2lrt2 a:focus,
  .app_header-link__2lrt2 a:hover {
    font-weight: bold;
    text-decoration: none;
  }
.app_header-link--current__3DPI1 {
  font-weight: bold;
}
.app_small-welcome__Olh9O{
  font-size:.9rem;
}
.app_small-welcome-grey__1b00r{
  font-size:.9rem;
  color:#a7a7a7;
  text-align:center;
  width:40%;
  font-weight:bold;
  margin:0 auto;
}
.app_other-offices__1dqRe{
  background-color: white;
    color: #272727;
    border-radius: 4px;
    font-size:.9rem;
    float:right;
}
/*
NavBar
================================================================================
*/
.app_navbar__10_wu {
  box-shadow: -5px 1px 10px #888;
  z-index: 900 !important;
}
/*
  Layout
  ------------------------------------------------------------
  */
.app_navbar__10_wu .app_navbar__row__2eO8x {
    height: 100%;
  }
.app_navbar__10_wu .app_navbar__col__tNVzA {
    height: 100%
  }
.app_navbar__10_wu .app_navbar__col__tNVzA:first-child {
      text-align: left
    }
@media (max-width: 767px) {

    .app_navbar__10_wu .app_navbar__col__tNVzA:first-child {
        display: none
    }
      }
.app_navbar__10_wu .app_navbar__col__tNVzA:not(:first-child):not(:last-child) {
      text-align: center;
    }
.app_navbar__10_wu .app_navbar__col__tNVzA:last-child {
      text-align: right;
    }
/*
  Brand
  ------------------------------------------------------------
  */
.app_navbar__10_wu .app_navbar__brand__3YB1V {
    display: block;
    height: 100%;

    text-align: center;
  }
/*
  Text & Links
  ------------------------------------------------------------
  */
.app_navbar__10_wu .app_navbar__text__1xWBj {
    display: inline;
    position: relative;
    top: calc(50% - 1rem);

    color: #646569;

    font-weight: 600;
    text-transform: uppercase;
  }
.app_navbar__10_wu a.app_navbar__text__1xWBj:hover,
    .app_navbar__10_wu a.app_navbar__text__1xWBj:active,
    .app_navbar__10_wu a.app_navbar__text__1xWBj:focus {
      color: #BDBBBB;
      text-decoration: none;
    }
.app_header-link__2lrt2 {
  padding: 0 1rem;
}
.app_header-link__2lrt2 a:focus,
  .app_header-link__2lrt2 a:hover {
    font-weight: bold;
    text-decoration: none;
  }
.app_header-link--current__3DPI1 {
  font-weight: bold;
}
.app_rightbar__R9LD2{
  padding-top:35px;
}
.app_null_navbar__3GZSB{
  height:0;
  min-height:0;
}
.app_navbar-fixed-top__3qWTW {
  position: fixed;
  background: #eee;
  max-width: 1800px;
}
.app_dentist-dashboard-header__2DoyB {
  font-family: 'Barlow Semi Condensed',sans-serif;
  padding:20px;
  min-height:300px;
}
.app_dash-box__1ALh1 {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
.custom-button{
    color:orange
}
.app_large-button--primary__3Ze9U {
  border: 2px solid #00a1df;
  border-radius: 1rem;
  padding: 1.1rem 8.0rem;

  color: #FFF;
  background-color: #00a1df;

  font-size: 1.1rem;
  font-weight: 600;
  line-height: normal
}
.app_large-button--primary__3Ze9U:hover {
    color: #00a1df;
    background-color: #FFF;
  }
.app_large-button--primary__3Ze9U:focus {
    outline: none;
  }
.app_large-button--secondary__1YJ53 {
  border: 1px solid #73C8AF;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;

  color: #FFF;
  background-color: #73C8AF;

  font-weight: 600;
  line-height: normal
}
.app_large-button--secondary__1YJ53:hover {
    color: #73C8AF;
    background-color: #FFF;
  }
.app_large-button--secondary__1YJ53:focus {
    outline: none;
  }
.app_large-button--secondary__1YJ53:disabled{
    background-color:#FFF;
    color: lightgrey;
    border: 1px solid lightgrey;
  }
.app_jumbotron__3WT7u {
    padding: 3rem 1rem 1rem;
    margin-bottom: 0;
    background-color: #00a1df;
    color: #fff;
    text-align: center;
    border-radius: 0;
    position: relative;
}
.app_dark__3yKtF{
    background-color: #00a1df;
    color: #fff;
}
.app_flex-container__2HS_b{
  display: flex;
}
.app_flex-container__2HS_b div{
  flex-grow:1;
  margin: auto;
}
.app_flex-center__LuiN2{
  justify-content:center;
  text-align:center;
}
.app_flex-cell3__2SLic{
  width: calc(100% / 3);
}
.app_flex-cell4__URUxE{
  width: calc(100% / 4);
}
.app_flex-cell8__fY0Vs{
  width: calc(100% / 8);
}
.app_field-instructions__NkQwu {
  margin-bottom: 1.5rem;
  text-align: justify;
  font-weight: 300
}
.app_field-instructions__NkQwu:last-child {
    margin-bottom: 2.5rem;
  }
.app_light-gray__32C__{
  color:#979797;
}
.app_spacer__3eFi1 {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
.app_spacer--after-form-group__3fXH8 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.app_spacer--members-list__2L_O3 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.app_hidden-field__2rF1A {
  display: none;
}
.app_hidden-field__2rF1A + .app_align-with-input__36X1H {
  padding-top: 0;
}
.app_white__3GQbx{
  color:#fafafa
}
.app_white__3GQbx:hover {
    color: #cacaca;
  }
.app_white-background__2wdwG{
  background:#fff;
}
.app_float-right__1Or3d {
  float: right;
}
.app_float-left__2NLLO {
  float: left;
}
.app_modal-section-title__vOLCH {
  margin: 0 0 1rem 0;
  color: #00a1df;
  font-size: 1.5rem;
}
.modal-body {
  padding-bottom: 1.5rem;
}
.app_dhqblue__rVTaW{
  color:#00a1df;
}
.app_acceptsSMScheckbox__3fiuN input{
  opacity:.4;
  height: 0.8rem;
}
.app_acceptsSMS__18lIM{
  font-size:.8rem;
  color: #999;
}
.shepherd-element{background:#fff;border-radius:5px;box-shadow:0 1px 4px rgba(0,0,0,.2);max-width:400px;opacity:0;outline:none;transition:opacity .3s,visibility .3s;visibility:hidden;width:100%;z-index:9999}
.shepherd-enabled.shepherd-element{opacity:1;visibility:visible}
.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered){opacity:0;pointer-events:none;visibility:hidden}
.shepherd-element,.shepherd-element *,.shepherd-element :after,.shepherd-element :before{box-sizing:border-box}
.shepherd-arrow,.shepherd-arrow:before{position:absolute;width:16px;height:16px;z-index:-1}
.shepherd-arrow:before{content:"";-webkit-transform:rotate(45deg);transform:rotate(45deg);background:#fff}
.shepherd-element[data-popper-placement^=top]>.shepherd-arrow{bottom:-8px}
.shepherd-element[data-popper-placement^=bottom]>.shepherd-arrow{top:-8px}
.shepherd-element[data-popper-placement^=left]>.shepherd-arrow{right:-8px}
.shepherd-element[data-popper-placement^=right]>.shepherd-arrow{left:-8px}
.shepherd-element.shepherd-centered>.shepherd-arrow{opacity:0}
.shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before{background-color:#e6e6e6}
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,.shepherd-target-click-disabled.shepherd-enabled.shepherd-target *{pointer-events:none}
.shepherd-modal-overlay-container{-ms-filter:progid:dximagetransform.microsoft.gradient.alpha(Opacity=50);filter:alpha(opacity=50);height:0;left:0;opacity:0;overflow:hidden;pointer-events:none;position:fixed;top:0;transition:all .3s ease-out,height 0ms .3s,opacity .3s 0ms;width:100vw;z-index:9997}
.shepherd-modal-overlay-container.shepherd-modal-is-visible{height:100vh;opacity:.5;transition:all .3s ease-out,height 0s 0s,opacity .3s 0s}
.shepherd-modal-overlay-container.shepherd-modal-is-visible path{pointer-events:all}
.shepherd-content{border-radius:5px;outline:none;padding:0}
.shepherd-footer{border-bottom-left-radius:5px;border-bottom-right-radius:5px;display:flex;justify-content:flex-end;padding:0 .75rem .75rem}
.shepherd-footer .shepherd-button:last-child{margin-right:0}
.shepherd-header{align-items:center;border-top-left-radius:5px;border-top-right-radius:5px;display:flex;justify-content:flex-end;line-height:2em;padding:.75rem .75rem 0}
.shepherd-has-title .shepherd-content .shepherd-header{background:#e6e6e6;padding:1em}
.shepherd-text{color:rgba(0,0,0,.75);font-size:1rem;line-height:1.3em;padding:.75em}
.shepherd-text p{margin-top:0}
.shepherd-text p:last-child{margin-bottom:0}
.shepherd-button{background:#3288e6;border:0;border-radius:3px;color:hsla(0,0%,100%,.75);cursor:pointer;margin-right:.5rem;padding:.5rem 1.5rem;transition:all .5s ease}
.shepherd-button:not(:disabled):hover{background:#196fcc;color:hsla(0,0%,100%,.75)}
.shepherd-button.shepherd-button-secondary{background:#f1f2f3;color:rgba(0,0,0,.75)}
.shepherd-button.shepherd-button-secondary:not(:disabled):hover{background:#d6d9db;color:rgba(0,0,0,.75)}
.shepherd-button:disabled{cursor:not-allowed}
.shepherd-cancel-icon{background:transparent;border:none;color:hsla(0,0%,50.2%,.75);font-size:2em;cursor:pointer;font-weight:400;margin:0;padding:0;transition:color .5s ease}
.shepherd-cancel-icon:hover{color:rgba(0,0,0,.75)}
.shepherd-has-title .shepherd-content .shepherd-cancel-icon{color:hsla(0,0%,50.2%,.75)}
.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover{color:rgba(0,0,0,.75)}
.shepherd-title{color:rgba(0,0,0,.75);display:flex;font-size:1rem;font-weight:400;flex:1 0 auto;margin:0;padding:0}
.shepherd-element{background:#fff;border-radius:5px;box-shadow:0 1px 4px rgba(0,0,0,.2);max-width:400px;opacity:0;outline:none;transition:opacity .3s,visibility .3s;visibility:hidden;width:100%;z-index:9999}
.shepherd-enabled.shepherd-element{opacity:1;visibility:visible}
.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered){opacity:0;pointer-events:none;visibility:hidden}
.shepherd-element,.shepherd-element *,.shepherd-element :after,.shepherd-element :before{box-sizing:border-box}
.shepherd-arrow,.shepherd-arrow:before{position:absolute;width:16px;height:16px;z-index:-1}
.shepherd-arrow:before{content:"";-webkit-transform:rotate(45deg);transform:rotate(45deg);background:#fff}
.shepherd-element[data-popper-placement^=top]>.shepherd-arrow{bottom:-8px}
.shepherd-element[data-popper-placement^=bottom]>.shepherd-arrow{top:-8px}
.shepherd-element[data-popper-placement^=left]>.shepherd-arrow{right:-8px}
.shepherd-element[data-popper-placement^=right]>.shepherd-arrow{left:-8px}
.shepherd-element.shepherd-centered>.shepherd-arrow{opacity:0}
.shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before{background-color:#e6e6e6}
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,.shepherd-target-click-disabled.shepherd-enabled.shepherd-target *{pointer-events:none}
.shepherd-modal-overlay-container{-ms-filter:progid:dximagetransform.microsoft.gradient.alpha(Opacity=50);filter:alpha(opacity=50);height:0;left:0;opacity:0;overflow:hidden;pointer-events:none;position:fixed;top:0;transition:all .3s ease-out,height 0ms .3s,opacity .3s 0ms;width:100vw;z-index:9997}
.shepherd-modal-overlay-container.shepherd-modal-is-visible{height:100vh;opacity:.5;transition:all .3s ease-out,height 0s 0s,opacity .3s 0s}
.shepherd-modal-overlay-container.shepherd-modal-is-visible path{pointer-events:all}
.shepherd-content{border-radius:5px;outline:none;padding:0}
.shepherd-footer{border-bottom-left-radius:5px;border-bottom-right-radius:5px;display:flex;justify-content:flex-end;padding:0 .75rem .75rem}
.shepherd-footer .shepherd-button:last-child{margin-right:0}
.shepherd-header{align-items:center;border-top-left-radius:5px;border-top-right-radius:5px;display:flex;justify-content:flex-end;line-height:2em;padding:.75rem .75rem 0}
.shepherd-has-title .shepherd-content .shepherd-header{background:#e6e6e6;padding:1em}
.shepherd-text{color:rgba(0,0,0,.75);font-size:1rem;line-height:1.3em;padding:.75em}
.shepherd-text p{margin-top:0}
.shepherd-text p:last-child{margin-bottom:0}
.shepherd-button{background:#3288e6;border:0;border-radius:3px;color:hsla(0,0%,100%,.75);cursor:pointer;margin-right:.5rem;padding:.5rem 1.5rem;transition:all .5s ease}
.shepherd-button:not(:disabled):hover{background:#196fcc;color:hsla(0,0%,100%,.75)}
.shepherd-button.shepherd-button-secondary{background:#f1f2f3;color:rgba(0,0,0,.75)}
.shepherd-button.shepherd-button-secondary:not(:disabled):hover{background:#d6d9db;color:rgba(0,0,0,.75)}
.shepherd-button:disabled{cursor:not-allowed}
.shepherd-cancel-icon{background:transparent;border:none;color:hsla(0,0%,50.2%,.75);font-size:2em;cursor:pointer;font-weight:400;margin:0;padding:0;transition:color .5s ease}
.shepherd-cancel-icon:hover{color:rgba(0,0,0,.75)}
.shepherd-has-title .shepherd-content .shepherd-cancel-icon{color:hsla(0,0%,50.2%,.75)}
.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover{color:rgba(0,0,0,.75)}
.shepherd-title{color:rgba(0,0,0,.75);display:flex;font-size:1rem;font-weight:400;flex:1 0 auto;margin:0;padding:0}
/* @import 'custom'; */

